export const NoFlame56 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="267.97 677.53 308.99 825.85 426.74 846.3 553.09 762.25 492.99 628.1 386.94 573.12 267.97 677.53"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m426.63,847.29c-39.68-6.26-79.12-12.88-118.76-19.23-.99-.15-1.49-.47-1.78-1.55-13.35-49.56-26.69-99.11-40.1-148.65-.28-.99-.15-1.59.64-2.3,39.18-34.85,78.35-69.71,117.46-104.64.9-.81,1.54-.85,2.59-.3,35.25,19.48,72.71,36.48,106.9,57.16,20.33,44.31,40.55,88.67,60.99,132.93.6,1.29.58,1.96-.75,2.84-42.47,27.63-84.56,56.81-127.19,83.75Zm-44.02-269.7c-37.26,32.85-74.26,65.99-111.53,98.83-.91.8-1.17,1.53-.84,2.72,12.79,46.4,25.33,92.87,38.01,139.3.52,1.91.54,1.91,2.17.69,24.68-18.42,49.34-36.85,74.04-55.25,17.95-13.37,35.78-26.9,53.88-40.09.54-.39,1.06-.83.67-1.82-19.05-47.89-36.76-97.09-56.4-144.38Zm-68.24,248.14c38.05,5.54,76.72,14.89,114.57,18.01,39.32-26.16,78.67-52.29,118-78.44.42-.28,1.16-.45,1.1-.99-.07-.66-.88-.6-1.39-.77-34.06-10.77-68.19-24.68-102.24-33.86-43.56,31.78-86.6,64.1-130.04,96.05Zm75.94-250.02c20.4,47.96,37.01,98.58,58.51,145.51,33.83,12.15,67.96,23.48,101.59,36.11-18.17-41.63-37.95-82.93-56.5-124.52-1.36-3.01-3.26-4.88-6.14-6.37-32.66-16.89-64.77-33.74-97.47-50.73Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m341.13,756.22c-.24,6.63-2.12,12.68-6.65,17.66-5.06,6.62-15.78,6.83-19.06-1.95-6.16-11.98,5.49-38.38,20.55-28.88,3.84,3.18,5.18,8.35,5.17,13.17Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m350.96,641.59c.38-8.42,3.53-15.49,10.36-20.7,7.18-5.91,16.75-1.17,17.43,7.24,1.84,11.36-5.68,27.08-18.52,26.81-6.06-.55-9.61-7.31-9.27-13.36Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m430.91,774.06c-4.56.17-11.86-1.68-9.54-7.58,5.07-9.3,16.46-13.11,26.42-14.34,12.21-.89,14.73,7.15,4.95,14.11-6.56,4.73-13.92,7.35-21.83,7.81Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m477.37,760.77c13.95,0,8.18,10.61-.09,14.85-7.03,4-15.64,7.29-23.77,5.24-3.79-1.26-4.79-4.12-2.63-7.5,1.64-2.56,3.91-4.5,6.46-6.1,6.13-3.85,12.73-6.29,20.04-6.5Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m383.75,800.03c4.38-.43,12.04,2.06,8.48,7.62-5.75,7.91-16.22,10.98-25.57,11.84-13.03.53-12.72-8.11-2.71-13.65,6.09-3.61,12.67-5.68,19.8-5.81Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m503.45,768.64c3.7-.19,9.37,1.81,6.97,6.43-5.33,8.49-15.99,12.27-25.58,13.3-3.45.43-9.71-.84-8.26-5.57,5.01-9.56,16.75-13.15,26.86-14.16Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m413.29,806.34c3.83-.34,10.39,1.78,7.53,6.68-5.88,8.45-21.8,14.07-31.65,10.89-6.14-2.88.1-9.11,3.71-11.19,6.23-3.94,13.01-6.15,20.4-6.39Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m422.36,829.94c-4.01.07-9.7-1.14-7.42-5.89,5.28-7.83,15.52-10.89,24.51-11.88,7.64-.72,11.66,4.07,4.71,9.53-6.61,5.13-14.16,7.75-21.8,8.24Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m474.25,704.94c-.38,11.36-13.91,1-17.24-3.37-3.85-4.26-6.94-9.03-8.87-14.49-1.72-3.96-1.78-11.6,4.48-9.97,11.08,4.12,19.84,16.25,21.71,27.81h-.07Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m418.24,611.44c.08-8.3,9.2-3,12.49.05,6.54,5.86,12.82,13.24,13.84,22.27.16,4.45-4.51,4.53-7.44,2.74-9.36-5.65-16.64-14.22-18.89-25.06Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m524.88,725.65c-.12,3.39-2.12,4.75-5.27,3.63-8.93-4.12-14.85-13.02-18.25-22.01-1.22-3.08-1.62-9.4,3.37-8.28,10.35,3.86,18.22,16.02,20.15,26.65Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m494.65,658.08c-.15,7.1-8.48,1.7-11.01-1.01-5.86-5.77-12.04-12.86-12.31-21.51.55-4.63,6.24-1.83,8.43-.01,7.1,5.71,13.51,13.29,14.88,22.53Z"/>
        </g>
      </g>
    </svg>
  )
}