import { useEffect } from "react"

export const Dashboard = (props) => {

  useEffect(()=>{
    console.log(props.backend)
  }, [props])

  return (
    <div>
      Dashboard
    </div>
  )
}