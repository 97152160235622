export const BigFlame07 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="301.77 619.73 423.33 582.9 553.94 668.71 497.59 798.9 393.8 868.25 271.74 763.82 301.77 619.73"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m163.86,403.93c-4-11.43-5.44-23.24-6.24-35.51-6.21,10.45-10.66,21.62-12.73,33.68-1.65,9.61-.16,18.43,6,26.38,3.86,4.98,7.12,10.44,10.67,15.66,3.99,5.87,8.01,11.72,11.98,17.52,2.21-.85,2.67-2.55,3.39-3.97,6.7-13.29,3.67-25.49-4.33-37.04-3.62-5.22-6.62-10.7-8.74-16.73Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m513.24,768.04c17.25-20.58,26.93-44.38,29.89-71.04,4.22-9.88,8.5-19.73,12.91-29.48-3.69-2.42-7.7-5.05-11.96-7.83-3.37-42.5-24.05-76.57-46.62-111.32-.71,9.36,3.05,17.55-.53,25.33-3.6-.06-4.99-2.27-6.62-4.11-5.34-6.06-7.12-13.84-9.97-21.08-15.22-38.6-41.27-65.02-82.84-74.08-17.13-3.73-30.6-13.3-42-26.2-2.81-3.19-5.59-6.4-8.76-10.02-.1,1.24-.24,1.75-.15,2.21.19.97.52,1.92.78,2.89,4.68,17.24,14.15,30.93,29.38,40.61,8.61,5.48,16.31,12.07,21.65,21.02,4.28,7.19,6.55,14.92,7.04,23.88-2.57-1.11-4.39-1.88-6.19-2.67-17.46-7.69-35.32-13.65-54.69-14.05-8.97-.18-17.9-1.37-26.74-3.06-39.24-7.54-74.99-23.67-108.95-44.22-1.44-.87-2.68-2.64-4.89-1.86,28.68,38.99,46.58,81.85,48.24,130.68-6.9,4.65-13.68,8.53-20.96,11.39-8.58,3.37-17.07,4.99-23.92-4.04.49,2.91,1.11,5.46,1.95,7.95,4.37,13,12.27,24.01,20.61,34.55,16.59,20.98,17.09,43.69,8.64,67.81-2.69-.41-4.23-3.82-7.98-3.71,6.8,11.12,9.85,23.17,13.25,34.86,9.62,33.08,28.18,58.78,58.43,75.63,15.08,8.4,31.18,13.62,48.47,15.44,13.88,11.88,27.72,23.73,41.38,35.48,1.17,1.01,1.99,1.05,3.26.19,34.13-23.16,68.36-46.17,102.48-69.35,5.22-10.48,10.17-21.14,14.99-31.89.28.09.41.13.45.14,0,0-.01,0-.02,0Zm-90.84-183.82c1.1-.35,1.78.08,2.58.6,41.34,27.12,82.75,54.11,124.05,81.28-34.09,17.73-68.46,34.92-102.56,52.63-1.21.64-2,.49-3.07-.29-44.84-33-89.79-65.79-134.63-98.8,37.89-11.93,75.75-23.5,113.62-35.42Zm-31.45,278.93c-38.86-32.92-77.27-66.15-115.97-99.24-.92-.78-1.06-1.47-.82-2.54,3.08-14.06,6.13-28.12,9.18-42.18,6.92-31.19,13.17-62.46,20.35-93.6,44.87,33.19,90.41,66.91,135.27,100.1,0,0-33.38,91.72-48.01,137.47Zm105.9-65.13s-64.76,43.11-97.8,65.59c14.44-42.42,30.25-84.47,45.42-126.66,1.56-3.18,1.78-7.55,4.6-9.86,34.11-17.93,68.15-35.96,102.32-53.78-17.58,41.03-36.85,83.75-54.54,124.72Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m513.26,768.05s.03,0,0,0h0Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m407.41,742.81c.17-11.34-7.58-24.43-19.71-25.39-18.89,1.88-10.4,30.73,2.2,36.45,8.92,5.67,18.07-1.83,17.51-11.05Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m306.46,738.25c-14.68-2.46-11.25,19.54-4.84,26.49,6.54,9.37,18.72,11.21,20.07-2.49-.26-7.1-2.32-12.5-6-17.28-2.41-3.13-5.33-5.62-9.23-6.72Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m447.28,641.34c-6.04-5.48-13.36-7.8-22.56-8.37-27.47,3.91,7.64,28.3,23.2,19.98,5.44-2.53,2.9-8.62-.63-11.61Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m470.97,732.22c-10.4,4.6-19.23,16.88-21.34,27.74.81,15.29,21.19-8.43,22.86-13.61,2.38-3.8,7.21-16.18-1.51-14.13Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m445.96,800.79c-9.92,3.82-19.38,16.17-21.47,26.32-.38,5.47,4.12,4.32,7.03,2.39,7.75-5.11,13.19-12.16,16.91-20.56,1.21-2.73,2.71-9.59-2.47-8.14Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m504.33,725.33c-.85,2.63-3.17,10.16,2.04,8.93,10.19-4.29,17.12-15.97,20.06-25.96-.47-15.8-21.6,11.77-22.1,17.03Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m492.6,770.9c-7.36,5.35-13.34,14.05-15.9,22.5-.28,5.1,2.78,4.69,5.52,2.89,8.22-5.95,14.98-14.32,17.03-24.38.36-4.5-4.51-2.75-6.65-1.02Z"/>
        </g>
      </g>
    </svg>
  )
}