export const BigFlame58 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="408.95 829.21 545.05 781.98 505.29 624.86 406.3 581.97 271.05 654.76 298.49 824.21 408.95 829.21"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m225.21,286.51c-14.24-4.44-26.78-13.06-35.19-25.52-4.64,51.52,7.02,121.09,22.13,137.92,4.17-22.26,13.13-42.28,25.09-61.1,9.44-16.19,10.03-45.58-12.04-51.3Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m306.36,444.14c-5.12-38.17-30.79-20.66-40.38-52.13-5.09,45.61,24.96,35.04,40.38,52.13Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m536.68,746.71c60.03-103.2,7.06-187.25-110.7-158.73-5.68-2.6-11.32-5.17-16.87-7.7-1.82-1.31-11.5,5.51-14.4,6.56-13.09,6.96-26.18,13.93-39.27,20.9-31.62,1.82-66.21,6.37-90.34-18.63-3.61,37.73-7.13,74.7-13.52,111.61-12.15-1.86-23.23-4.35-30.41-15.55-5.82,23.97,14.1,44.59,26.44,63.16,4.69,6.64,7.7,13.38,7.84,21.99,10.58,98.88,152.04,79.17,212.17,38.73,25.54-8.8,51.09-17.61,76.63-26.41.9-.31,1.69-.42,1.32-1.86-2.93-11.18-5.9-22.57-8.88-34.08Zm-134.18-158.46c2.03-1.07,2.04-1.06,2.53,1.14,12.83,57.71,25.8,115.36,38.57,173.09-46.67,18.35-94.23,36.26-141.18,54.47-1.96.75-1.99.76-2.33-1.27-7.15-43.51-14.33-87.01-21.5-130.51-1.51-9.19-2.99-18.38-4.55-27.56-.18-1.07,0-1.59.97-2.1,13.13-6.89,26.24-13.81,39.34-20.74,29.31-15.64,58.82-30.91,88.16-46.53Zm-101.75,236.39c1.93.08,3.85.17,5.78.25-.17,0-.35,0-.52,0-1.69-.06-3.45.12-5.26-.26Zm111.69,2.36c-2.99,1.35-6.08,1.21-9.24.97-32.49-1.87-65.03-2.8-97.5-5.02,48.15-17.64,95.73-37.49,144.09-53.87,29.55,4.86,59.17,9.08,88.67,14.28-41.95,14.69-84.08,28.96-126.02,43.63Zm128.59-50.4c-29.28-5.05-58.56-10.17-87.85-15.16-15.62-56.74-26.71-117.1-40.57-175.06,29.86,13.51,59.42,26.93,89.26,40.38,1.31.59,1.85,1.42,2.19,2.73,12.62,48.71,25.18,97.45,37.88,146.14.29,1.03.06,1.14-.91.97Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m383.3,662.84c7-4.68,10.03-11.51,10.46-19.68.76-12.07-10.96-21.95-21.81-13.43-15.64,9.53-10.1,44.44,11.34,33.11Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m313.97,775.57c10.15,15.55,27.26-.93,26.01-14.74.03-4.31-1.04-8.3-3.49-11.86-15.39-18.1-32.83,12.85-22.52,26.61Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m470.44,709.68c-1.78-2.36-3.87-4.39-6.61-5.65-2.83-1.31-5.61-.4-7.07,2.35-3.54,10.03,1.19,20.83,7.17,28.92,6.1,8.2,14.9,7.68,14.49-3.91-.41-8.06-3.11-15.29-7.98-21.72Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m458.28,647.06c-.29-7.27-2.91-13.74-7.01-19.65-3.82-6.66-15.16-13.55-15.73-1.04.51,10.9,5.22,21.56,14.44,27.72,5.24,3.23,8.75-2.11,8.29-7.03Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m509.85,717.07c-4.83-2.86-7.45,3.11-7.06,7.16.62,9.6,4.34,20.18,12.55,25.78,2.96,1.7,5.47.74,6.61-2.47,2.38-10.09-3.35-24.83-12.1-30.47Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m493.07,642.09c-2.09-2.37-6.9-5.43-9.02-1.55-6.49,11.66,17.25,46.28,18.73,23.64-.74-7.96-3.78-15.64-9.71-22.09Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m422.78,786.26c-7.83,3.23-7.04,6.67,1.39,6.33,6.21-.07,30.18-3.8,31.45-10.3-5.35-4.93-26.35,1.22-32.84,3.97Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m449.48,795.36c10.04-.73,21.14-2.52,29.63-8.29,4.56-6.81-22.99-.75-25.49.54-5.65,1.32-18.05,7.84-4.14,7.76Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m383.91,809.09c-1.35-2.56-7.81-1.55-10.4-1.47-8.76,1.11-18.24,2.34-25.64,7.44-2.5,3.23,4.23,2.9,6.63,2.98,5.74-.13,27.08-3.06,29.41-8.96Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m472.28,797.93c9.91-.85,20.4-2.5,28.85-8.02,4.49-6.84-24.66.22-27.03,1.75-5.32,1.62-12.45,6.42-1.82,6.28Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m408.6,810.01c-8.1-1.49-16.41.77-24.22,2.84-5.39,1.2-16.6,6.79-3.7,6.77,2.96.77,34.55-5.09,27.91-9.61Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m405.95,815.89c-5.85,1.95-9.52,5.46-.26,5.28,5.41-.27,25.4-3.13,27.71-8.4-4.56-3.83-21.82,1.22-27.45,3.12Z"/>
        </g>
      </g>
    </svg>
  )
}