export const NoFlame40 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="278.32 722.03 308.96 811.94 464.22 859.97 514.43 721.32 524.56 623.76 354.69 580.42 278.32 722.03"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m525.4,624.15c-3.47,32.11-7.03,64.13-10.53,96.22-15.46,46.92-33.74,93.11-50.11,139.8-.46,1.28-1.01,1.22-2.06.88-25.33-8.28-131.17-39.29-155.67-49.3-9.6-28.54-20.55-60.31-30.17-88.84-.48-1.42-.37-2.55.34-3.84,8.14-14.95,58.22-107.35,75.17-138.57q1.14-2.1,3.43-1.51c51.47,13.52,103.01,26.7,154.47,40.24,1.98.92,16.53,3.12,15.13,4.93Zm-168.42-40.71c-25.2,42.58-47.83,88.94-72.19,132.76-.89,1.66-.91,1.66.86,2.19,58.32,17.69,118.63,35.29,176.94,53,19.47-45.41,35.68-97.12,55.66-144.05.46-1.2.4-1.68-.98-2.04-53.44-13.92-106.85-27.95-160.29-41.86Zm102.98,235.27c.33-12.51.13-25.04.33-37.55.01-1.15-.47-1.52-1.44-1.81-42.17-12.84-159.56-49.01-175.98-53.36-.46.42.03.98.18,1.44,1.84,5.68,18.69,62.62,28.09,82.88,48.68,15.74,97.38,30.15,146.03,45.94,2.85.93,2.6-.16,2.6-3.23,0-12.1.13-34.3.19-34.3Zm63.34-186.33c-18.98,47.92-37.58,96.62-55.43,145.06-.54,25.45-1.46,50.9-1.77,76.35,13.1-35.86,44.94-122.01,46.81-129.42.77-3.04,1.27-6.13,1.31-9.32,2.73-27.6,6.38-55.04,9.08-82.67Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m433.32,702.86c31.41,1.53,22.42,40.54-6.83,33.31-22.23-6.2-15.47-33.36,6.83-33.31Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m387.68,723.73c-37.53-3.76-13.77-46.79,14.02-29.68,15.13,11.01,1.84,30.32-14.02,29.68Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m343.23,710.61c-36.15-3.8-12.24-44.81,14.41-29.55,15.87,10.5,1.22,30.76-14.41,29.55Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m461.87,662.81c-2.7.02-7.14-1.16-11.04-4-19.91-16.87,11.32-35.25,26.44-22.07,13.62,11.77.13,27.58-15.4,26.08Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m421.93,621.01c28.71,2.02,20.16,34.09-5.61,29.55-22.57-4.69-15.77-29.87,5.61-29.55Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m378.44,639.46c-23.13-.67-23.2-26.13-1.09-29.14,26.97-2.77,27.61,28.48,1.09,29.14Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m341.19,772.44c-7.19-.45-13.62-3.07-19.59-6.96-5.19-2.75-14.57-13.51-3.52-14.96,10.05-.34,19.97,4.39,27.1,11.32,5.38,5.27,4.16,10.93-3.99,10.6Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m386.51,806.31c-9.49-1.25-18.76-4.74-25.5-12.77-2.87-3.51-.9-7.56,3.64-7.53,10-.4,19.85,3.99,26.91,10.89,5.29,5.62,2.3,9.59-5.05,9.41Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m430.03,837.87c-9.1-.98-17.38-3.9-24.14-10.38-6.37-6.38.41-9.57,6.81-8.34,8.93,1.55,18.89,5.03,23.93,13.05,2.15,4.33-3.25,5.87-6.6,5.66Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m475.43,776.57c.29-6.28,6.66-32.19,11.69-34.72,3.79,1.19-11.21,48.9-11.69,34.72Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m503.06,703.58c1.3-10.44,4.19-20.87,8.61-30.44,1.07-3.28,3.23-3.01,2.44.63-1.18,10.35-4.18,20.55-8.41,30.06-.57.88-.92,2.68-2.07,2.44-1.09-.25-.29-1.73-.56-2.69Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m499.33,731.14c-.35,6.34-6.08,29.54-10.63,32.86-1.01-.26-.64-1.52-.57-2.35,1.21-10.15,4.25-20.09,8.32-29.45.8-1.82,3.09-5.71,2.89-1.06Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m473.8,817.19c.37-5.41,6.37-29.58,10.72-31.54,2.95.96-10.05,43.04-10.72,31.54Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m509.46,720.34c-.29,5.29-5.98,28.07-9.88,30.41-.82-.25-.39-1.37-.33-2.11,1.07-8.88,3.61-17.59,6.79-25.93.75-1.55,3.68-7.81,3.41-2.37Z"/>
        </g>
      </g>
    </svg>
  )
}