export const NoFlame06 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="299 620.59 413.57 587.67 549.78 657.15 501.69 798.86 402.85 865.82 274.58 772.85 299 620.59"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m551.65,657.12c-14.6,42.83-32.31,97.34-49.52,142.87-32.43,22.42-64.87,44.82-97.23,67.33-1.22.85-1.99.74-3.13-.08-42.87-30.64-85.73-61.28-128.66-91.84-1.13-.8-1.48-1.5-1.25-2.94,8.12-50.49,16.35-100.97,24.31-151.48.18-1.13.59-1.78,1.83-2.13,39.03-10.23,77.99-24.06,117.07-32.49,45.55,23.55,91.05,47.15,136.57,70.76Zm-251.93-30.55c-7.08,48.7-18.46,98.9-22.93,147,41.19,29.14,82.22,58.45,123.41,87.6,13.71-49.73,27.12-99.51,40.82-149.24.33-1.19.12-1.8-.95-2.38-46.84-27.59-93.52-55.36-140.36-82.98Zm244.88,29.54c-43.81-21.97-87.01-47.43-131.42-67.24-36.21,10.55-72.57,20.77-108.66,31.66,46.63,27.49,93.49,54.58,140.13,82.05,1.36.8,2.38.78,3.72.1,32.03-15.61,64.16-31.01,96.22-46.57Zm2.94,6.79c-32.14,15.86-64.72,32.43-96.83,48.32,0,0-27.66,100.61-42.69,150.5,31.26-22.55,93.17-64.16,93.17-64.16,14.78-44.24,31.54-90.36,46.35-134.66Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m410.15,731.69c-.64,27.37-30,14.7-31.51-5.43-2.49-10.48,7.46-22.17,18.08-15.9,8.42,4.65,12.39,12.08,13.44,21.34Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m324.38,765.39c-4.52,27.55-32.83-.43-25.92-16.97,1.95-5.58,7.1-7.7,12.54-5.24,8.4,3.97,12.95,13.21,13.38,22.21Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m437.13,648.11c-9.03-.46-16.32-2.24-22.68-6.83-6.99-5.25-4.82-10.07,3.36-11.08,10.06-.6,20.8,1.78,27.8,9.5,4.55,6.39-4.72,7.88-8.48,8.4Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m477.69,724.87c-1,10.87-7.1,20.85-15.58,27.51-7.19,4.92-9.92.5-8.5-6.8,2.64-10.41,8.32-20.61,18.1-25.77,3.79-1.86,6.28,1.45,5.98,5.06Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m455.37,799.15c-1.22,10.56-7.82,20.43-16.43,26.56-9.22,5.61-7.79-6.39-5.02-11.48,3.53-7.72,8.47-15.53,16.35-19.36,3.42-1.5,5.37.91,5.1,4.28Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m525.25,698.55c-.27,4.41-1.86,8.55-3.91,12.52-2,6.05-18.66,25.42-17.44,8.35,2.3-10.12,7.43-20.38,16.82-25.51,3.32-1.44,4.72,1.72,4.53,4.64Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m502.22,768.46c-.99,9.1-6.32,17.45-12.93,23.6-7.53,6.38-9.21.69-6.85-6.43,3.06-8.19,7.68-16.61,15.67-20.86,2.83-1.31,4.39,1,4.1,3.69Z"/>
        </g>
      </g>
    </svg>
  )
}