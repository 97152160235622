export const NoFlame60 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="288.16 812.87 445.24 804.66 531.51 795.2 518.55 628.73 425.14 605.61 278.41 636.02 288.16 812.87"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m291.27,814.85c-4.69.14-4.62.14-4.81-3.46-3.17-58.66-6.25-117.32-9.44-175.98-.08-1.35.29-1.88,1.65-2.15,49.86-9.95,99.7-20,149.54-30.01,21.62,4.23,42.94,11.6,64.45,16.9,8.36,2.27,16.72,4.56,25.09,6.78,1.19.32,1.65.8,1.74,2.09,2.95,38.58,5.93,77.15,8.97,115.72,1.32,16.75,2.52,33.52,3.9,50.27.12,1.38-.22,1.84-1.68,2.01-79.09,11.53-159.83,13.95-239.42,17.84Zm146.46-14.87c-4.2-63.01-8.67-125.91-12.84-188.91-.07-1.14-.49-1.27-1.46-1.08-46.94,9.21-93.86,18.54-140.82,27.64-1.24.24-1.54.71-1.42,1.98,1.51,26.33,2.79,52.68,4.3,79.01,1.53,29.84,3.26,59.68,4.77,89.53.05,1.08.43,1.44,1.53,1.35,3.29-.25,6.58-.4,9.88-.57,30.07-1.55,60.13-3.24,90.2-4.79,14.69-.79,29.38-1.62,44.08-2.38,1.4-.07,2.32-.24,1.79-1.79Zm-4.63-190.34c3.35,50.17,6.99,100.16,10.33,150.31.91,13.16,1.86,26.31,2.73,39.47.08,1.27.47,1.56,1.69,1.42,10.77-1.19,21.54-2.34,32.31-3.49,16.08-1.71,32.16-3.43,48.24-5.12,1.06-.11,1.57-.39,1.47-1.62-1.29-16.01-2.49-32.03-3.76-48.04-2.78-36.47-5.66-72.93-8.45-109.39-.09-1.18-.41-1.68-1.62-1.99-27.71-7.15-55.21-14.45-82.95-21.56Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m405.7,661.19c1.59,16.33-19.16,30.76-29.72,13.81-12.2-17.7,13.02-47.22,27.4-23.66,1.62,3.06,2.38,6.35,2.32,9.84Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m336.17,763.22c-.12,5.7-1.34,10.18-4.35,14.02-16.46,18.02-32.7-11.43-21.66-25.97,10.49-14.66,26.38-2.14,26.01,11.94Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m476.34,755.34c-.27,5.47-.76,11.4-4.7,15.51-5.25,5.03-11.26-1.92-12.75-7-3.23-9.13-4.23-20.19,1.68-28.47,2.42-2.74,5.74-2.96,8.48-.56,5.61,5.17,6.97,13.31,7.29,20.53Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m469.76,664.03c.19,6.34-2.5,19.26-11.06,14.6-8.62-7.03-10.22-19.91-8.06-30.29,2.56-10.51,11.03-9.22,15.17-.46,2.69,5.16,3.7,10.72,3.94,16.15Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m517.02,753.55c.97,17.6-10.67,24.26-15.56,4.99-2.18-5.85-1.68-29.57,9.05-22.98,4.76,3.95,6.43,13.11,6.51,17.99Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m510.69,671.87c.58,8.2-5.06,20.12-12.33,8.93-4.63-8.66-6.41-19.45-1.95-28.48,3.61-5.87,9.61.02,11.15,4.46,2.2,4.79,3.03,9.87,3.12,15.1Z"/>
        </g>
      </g>
    </svg>
  )
}