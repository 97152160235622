export const SmallFlame59 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="400.12 817.59 538.15 790.27 510.63 627.24 418.22 592.91 274.89 645.26 295 820.5 400.12 817.59"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m533.77,755.08c39.6-50.74,35.13-134.81-28.71-163.66-24.33-18.21-41.14-44.2-62.03-66.31-6.2,12.87-15.83,22.27-25.23,32.16-44.49-2.25-82.44,15.21-101.27,56.99-3.35,7.14-7.32,13.22-13.84,17.68-.43.3-.88.6-1.33.93-3.04,1.12-6.09,2.22-9.13,3.32-25.49-11.5-49.57-21.88-70.72-40.29,9.05,39.28,18,79.38,15.24,120.54,3.14,44.12,1.48,84.73,41.82,112.79,66.37,45.2,157.35,17.9,215.11-29.97,14.82-2.96,29.64-5.9,44.46-8.75,1.31-.26,1.51-.71,1.29-1.97-1.91-11.15-3.79-22.3-5.66-33.45Zm-238.29,57.92c-4.86-44.99-9.84-89.96-14.77-134.94-1.1-9.98-2.18-19.97-3.39-29.94-.22-1.79.88-1.95,1.98-2.34,14.24-5.13,28.47-10.26,42.71-15.4,30.38-10.96,60.77-21.91,91.15-32.87,1.99-.72,2.03-.71,2.34,1.43,8.88,60.92,17.73,121.85,26.56,182.78.18,1.08-.72.97-1.31,1.1-47.9,10.48-95.85,20.76-143.75,31.25-1.1.24-1.4.09-1.53-1.08Zm113.2,2.88c2.24-.42,4.48-.85,6.73-1.27l-7.41,1.46c.09-.02.18-.04.27-.06-10.21,1.76-20.65,1.52-31.05,1.78-25.6.71-50.57,1.01-76.29,1.87,47.93-10.69,96.06-20.53,144.13-30.69,3.76-.71,7.63-.13,11.44-.06,24.96.95,49.98.73,74.92,2.31-40.77,8.7-82.14,15.72-122.75,24.66Zm124.89-30.83s0-.05,0-.08c-27.26-1.06-54.51-2.25-81.77-3.38-.84-.03-1.16-.26-1.28-1.13-2.62-17.9-5.32-35.79-7.93-53.69-6.32-42.88-12.57-85.78-18.97-128.64-.25-1.77-.18-1.84,1.51-1.21,28,11.56,58.98,19.97,85.53,33.12,8.5,51.22,17.13,102.43,25.74,153.63.5,2.07-1.63,1.2-2.84,1.38Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m418.14,800.4c8.64-.29,17.16-1.5,25.62-3.26,1.53-.43,7.93-1.37,8.22-2.79-1.26-3.52-54.5,6.12-33.84,6.05Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m443.09,800.72s0-.04,0-.06c6.14.09,12.25-1.11,18.29-2.18,1.82-.63,13.53-2.12,13.21-3.79-1.06-2.95-51.86,5.76-31.5,6.02Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m378.12,809.75c-.07-.68-.81-.49-1.3-.51-8.84-.19-17.61,1.16-26.29,2.73-3.33.39-15.15,3.48-4.27,3.14,10.35-.46,20.69-1.72,30.7-4.5.46-.12,1.21-.25,1.15-.85Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m462.44,800.68c10.73.14,21.39-1.94,31.77-4.47.57-.14,1.55-.43,1.46-1.14-.33-.94-1.79-.4-2.71-.54-10.21.62-20.84,1.86-30.74,4.86-1.67.3-1.41,1.67.22,1.3Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m402.91,809.4c-.13-.75-1.16-.43-1.8-.44-4.97-.1-9.87.61-14.79,1.22-5.6.7-11.13,1.7-16.58,3.17-.58.16-1.09.24-1.38,1,5.47,1.11,11.11-.36,16.6-.8,5.53-.73,11.04-1.58,16.4-3.18.59-.18,1.67-.21,1.54-.97Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m415.66,809.27c-7.03.67-13.97,1.83-20.82,3.53-9.62,4.05,30.67-1.53,31.34-3.58-3.48-1.3-6.97-.05-10.52.05Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m376.14,637.6c-11.45,9.07-10.84,34.29,6.56,35.27,27.4-1.8,20.63-53.71-6.56-35.27Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m309.07,763.53c.11,4.36.76,8.18,2.72,11.72,14.81,21.45,34.78-8.41,23.58-24.16-9.9-15.32-26.86-1.57-26.3,12.45Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m468.61,719.88c-4.8-4.35-9.86-.36-10.81,5.06-1.85,10.85.95,23.21,9.49,30.52,7.48,5.29,11.31-4.84,11-10.84-.26-9.14-2.74-18.59-9.68-24.75Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m464.52,655.24c-.11-6.63-1.98-12.78-5.29-18.5-1.48-2.55-3.29-4.86-5.77-6.54-3.78-2.54-7.29-1.47-8.95,2.77-2.86,9.91.34,20.06,6.06,28.31,7.06,9.64,14.49,4.53,13.95-6.05Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m502.4,736.71c.33,8.36,2.58,17.85,9.44,23.23,4.02,2.88,7.42-1.23,7.95-5.11,1.44-9.74-.91-21.04-8.26-27.95-6.54-5.39-9.71,4.57-9.13,9.82Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m506.9,667.69c-.32-6.09-1.95-12.2-5.27-17.81-4.13-7.61-11.92-9.45-12.65,1.13-.23,9.4,2.41,20.15,10.19,26.07,6.02,3.52,8-4.63,7.74-9.39Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m247.25,367.15c-.54-6.79-4.05-11.5-9.61-15.76-3.07,6.65,2.46,15.64,9.61,15.76Z"/>
        </g>
      </g>
    </svg>
  )
}