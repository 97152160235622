export const SmallFlame05 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="294.33 622.71 404.33 593.6 545.58 648.85 507.18 797.44 412.2 862.16 276.54 783.06 294.33 622.71"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m393.09,699.18c-21.56,1.6-14.6,34.9,2.61,38.69,4.69,1.46,8.92.28,12.35-3.29,10.26-11.94.38-34.15-14.96-35.4Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m316.32,747.55c-3.97-2.76-9.06-2.85-12.33-.23-9.64,9.27-2.27,27.15,8.6,32.21,7.85,4.11,15.26-3.76,14.53-11.62-.39-8.47-3.7-15.43-10.8-20.36Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m469.23,708.53c-8.25,7-13.41,18.07-13.21,28.9.16,2.24.65,4.53,2.97,5.51,10.76,2,19.51-20.02,19.97-29.02-.7-6.71-3.43-9.94-9.73-5.4Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m455.29,787.63c-5.49,2.31-8.97,7.55-11.78,12.56-2.63,4.82-4.49,9.89-4.86,15.67,2,19.31,25.37-11.76,21.94-25.19-.32-2.9-2.59-4.05-5.3-3.04Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m516.23,685.99c-7.69,6.74-11.01,15.66-12.4,25.85.8,6.71,3.24,8.5,8.8,3.83,7.14-6.92,11.18-16.92,11.01-26.8-.36-4.93-3.65-6.17-7.41-2.87Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m498.28,761.59c-7.16,6.52-12.02,16.24-12.12,25.99.2,4.09,2.66,5.32,6.11,3.12,7.7-5.78,12.15-15.78,13.21-25.06.25-5.54-2.7-7.68-7.2-4.05Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m416.75,627.53c-2.51.42-5.04.73-7.52,1.29-1.43.32-2.85,1.02-3.17,2.69-.33,3.84,4.78,5.31,7.46,6.91,8.36,3.43,17.98,5.13,26.72,2.22,2.72-.93,3.02-3.23.93-5.28-6.71-5.74-15.84-7.12-24.43-7.84Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m546.89,648.64c.04-.18.09-.35.13-.53-.12-.05-.25-.09-.37-.14-.19-.54-.36-1.08-.56-1.61-34.31-105.78-75.22-70.84-129.61-129.97-14.73-17.55-36-31.79-58-35.04,14.88,31.79,7.99,56.26-30.57,56.9-32.93.8-65.22,3.42-97.38,11.5,20.3,16.26,46.34,35.33,44.29,64.23-10.41,4.44-21.34,4.25-32.59,4.56,23.03,37.19-2.79,77.83.57,117.84-2.84,74.72,71.78,121.2,140.73,111.14,9.07,5.28,18.14,10.56,27.21,15.84,1.52.89,2.54.93,4.04-.11,21.85-15.05,43.76-30.03,65.65-45.02,9.03-6.19,17.01-11.61,26-17.85,2.75-2.78,3.62-8.31,4.9-11.9.92-3.75,1.86-7.49,2.8-11.24,30.25-34.63,48.42-83.66,32.76-128.59Zm-245.88-26.7c32.63-8.33,65.31-17.07,97.95-25.45,2.59-.35,5.18-.66,7.77-.91,44.7,16.21,88.68,35.02,133.05,51.68-30.25,13.57-62.49,26.34-92.92,39.61-49.42-18.66-96.82-43.64-145.93-64.57.02-.12.05-.24.07-.36Zm68.29-19.66c-6.12,1.61-12.37,3.24-18.52,4.84,6.17-1.7,12.35-3.34,18.52-4.84Zm39.87,255.04c-43.08-24.79-85.99-49.73-129.09-74.49-1.29-.74-1.78-1.42-1.58-3.02,6.17-50.68,11.36-101.54,17.76-152.15,48.25,21.86,97.06,44.54,145.36,66.27l-32.45,163.39Zm99.78-66.74c-1.04,4.19-2.95,6.94-6.55,9.36-28.46,19.04-56.66,38.41-85.06,57.55,10.55-53.64,22.18-107.14,33.03-160.75.26-1.26.69-2.07,2-2.64,30.4-13.32,60.65-26.92,91.08-40.18-11.53,45.71-23.13,90.97-34.49,136.66Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m185.31,543.47c-.35-27.86,11.3-31.3-7.01-56.82-28.3,8.38-.28,41.32,7.01,56.82Z"/>
        </g>
      </g>
    </svg>
  )
}