export const SmallFlame44 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="294.42 764.75 355.07 588.15 530.08 644.33 482.11 823.66 453.18 837.5 301.41 791.47 294.42 764.75"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m359.08,712.51c-10.84.04-19.86,8.99-19.79,19.64-.08,10.8,8.7,19.69,20.3,19.77,11.24.09,20.02-8.85,19.96-20.03-.05-10.58-9.39-19.42-20.47-19.38Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m404.41,726.55c-10.55-.11-19.48,8.6-19.54,19.07-.13,11.23,8.47,19.94,19.82,20.34,10.98.39,20.08-8.47,20.11-19.14.03-11.29-8.88-20.15-20.39-20.27Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m449.28,740.42c-10.18-.02-18.94,8.58-19.1,18.66-.18,11.49,8.6,20.57,20.05,20.82,10.55.23,19.25-7.72,19.57-19.25.07-11.04-9.22-20.21-20.52-20.23Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m387.48,664.43c10.53,0,19.05-8.16,19.04-18.44-.01-10.29-9.06-18.98-19.83-19.1-.99.07-2,.04-2.97.22-1.38.26-2.77.57-4.1,1.04-8.28,2.92-12.68,10.93-12.11,18.54.75,9.96,9.78,17.74,19.97,17.73Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m432.24,678.3c2.44-.04,4.78-.53,7.02-1.46,7.54-3.14,12.14-10.71,11.05-18.7-1.18-8.68-6.27-14.04-14.61-16.56-12.11-3.67-25.51,5.73-23.77,19.71,1.22,9.87,10.22,17.24,20.31,17.01Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m479.44,655.33c-8.94-2.71-18.8,1.76-22.29,10.43-5.07,12.59,4.95,26.52,19.1,26.36.36-.02.73-.05,1.09-.07,11.83-.82,19.21-11.87,16.24-22.7-2-7.28-6.89-11.82-14.14-14.01Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m360.18,792.58c-.89-.54-1.79-1.09-2.74-1.52-9-4.1-18.4-6.9-28.11-8.66-1.25-.23-2.56-.56-3.88-.2-.82.22-1.01.59-.25,1.1.95.64,1.91,1.31,2.94,1.79,5.77,2.73,11.8,4.76,17.95,6.46,4.44,1.23,8.9,2.33,13.61,2.76.46-.21,1.35.31,1.59-.4.23-.68-.59-1.01-1.11-1.33Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m400.62,810.83c-.67-.69-1.56-1.05-2.41-1.44-7.86-3.68-16.14-6.07-24.59-7.89-2.38-.51-4.78-1.1-7.54-.51.72.67,1.24,1.17,1.78,1.65.11.1.28.12.42.18,7.85,3.54,16.01,6.13,24.4,8,2.47.55,4.92,1.43,7.53,1.05.3-.04.69.1.83-.26.13-.34-.2-.56-.42-.78Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m434.93,825.93c-.29-.22-.64-.36-.97-.51-4.71-2.13-9.63-3.68-14.59-5.1-4.46-1.27-8.95-2.4-13.57-2.85-.52.19-1.33-.35-1.51.38-.12.49.66.71,1.06,1.01.2.16.46.26.69.37,9,4.23,18.47,6.89,28.29,8.38.73.11,1.92.42,2.16-.25.29-.84-.97-.98-1.55-1.42Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m553.29,660.29c-11.12-44.02-35.29-85.14-79.38-102.19-48.59-16.03-55.15-61.4-100.04-78.37,17.61,75.37-66.13,57.19-111.64,71.48,15.49,13.95,26.34,29.49,26.63,50.68-17.49,6.04-35.11,5.68-53.56,4.07,20.21,27.89,9.9,61.24,4.91,92.27-23.23,127.65,90.82,182.57,197.8,134.79,5.66,1.72,11.33,3.44,16.99,5.16.45.14.9.26,1.41.41.37-.15.76-.28,1.14-.46,8.9-4.21,17.79-8.43,26.7-12.63.93-.44,1.46-1.03,1.73-2.04,1.34-5.06,2.75-10.1,4.13-15.14.97-3.55,1.93-7.11,2.9-10.66,40.56-32.97,69.3-84.28,60.28-137.38Zm-76.78,164.72c-7.48,3.79-14.97,7.55-22.46,11.31-.51.25-1.04.47-1.49.67-1.67-.48-3.23-.9-4.77-1.37-14.85-4.55-29.69-9.13-44.55-13.65-10.87-3.3-21.75-6.58-32.63-9.85-21.98-6.75-43.82-13.46-65.68-20.17-.36-.34-.56-.8-.7-1.38-1.6-6.66-3.24-13.3-4.86-19.95-.42-1.72-.22-1.91,1.44-1.4,22.88,6.99,45.76,13.99,68.64,20.97,35.6,10.86,71.21,21.72,106.8,32.59.78.24,2.07.24,2.16.95.09.79-1.19.93-1.89,1.29Zm5.54-8.87c-.13.49-.04,1.22-.82,1.27-.51-.14-.96-.26-1.41-.4-33.69-10.3-67.38-20.61-101.07-30.91-25.48-7.79-50.96-15.59-76.46-23.31-1.59-.48-1.72-.98-1.23-2.45,18.55-55.55,37.05-111.11,55.56-166.68q.68-2.04,2.74-1.39c52.7,16.7,105.4,33.4,158.1,50.11,3.14,1,6.26,2.09,9.44,2.95,1.54.42,1.75.98,1.34,2.48-5.71,20.62-11.35,41.27-17,61.91-9.72,35.48-19.44,70.95-29.17,106.42Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m175.03,493.87c-19.5,22.48,1.66,43.83,17.5,60.54.94-2.37-.21-3.79-.76-5.31-7.11-26.6,5.76-27.34-16.74-55.23Z"/>
        </g>
      </g>
    </svg>
  )
}