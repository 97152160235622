export const NoFlame41 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="282.23 732.47 307.3 806.22 463.87 856.02 510.59 714.14 526.36 628.94 351.51 580.65 282.23 732.47"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m466.36,848.98c-.39,2.6-1.37,4.53-2.12,7.02-.28.93-1.15.7-1.58.56-8.54-2.7-112.13-35.98-155.41-49.85-1.13-.36-1.68-.94-2.07-2.03-8.29-23.38-16.62-46.75-24.96-70.12-.37-1.04-.42-1.95.07-2.99,7.05-15.09,64.13-137.45,70.55-151.44.67-1.46,1.29-1.58,2.69-1.19,26.94,7.5,141.32,39.15,171.57,47.46,1.56.43,2.01.9,1.68,2.63-3.62,18.75-14.08,74.5-15.78,83.61-.15.82-.28,1.65-.79,2.35-.23-.4-.09-.81-.01-1.22,3.11-16.65,13.61-73,14.12-75.81-.62.58-.68,1.1-.84,1.57-3.56,10.58-40.14,119.98-49.45,147.88-.36,1.09-.7,2.17-.84,3.34-.77,6.38-6.36,50.25-7.07,55.95-.09.77-.28,1.57.23,2.28Zm1.11-63.78c.8.01.69-.81.87-1.32,3.76-11.05,38.01-112.19,51.38-151.71.81-2.39.81-2.38-1.57-3.04-53.87-14.94-107.74-29.88-161.6-44.86-1.43-.4-2.03-.2-2.68,1.21-15.88,34.54-59.73,129.52-65.74,142.49-.52,1.12-.84,1.82.85,2.33,17.19,5.21,133.37,41,174.3,53.61,1.35.42,2.7.83,4.2,1.28Zm-1.66,8.87c.39-.94-.32-1.26-1.22-1.54-5.65-1.74-162.58-50.33-176.89-54.74-.46-.14-1.03-.62-1.45-.13-.32.38.1.89.24,1.32,3.47,10.47,19.44,56.48,22.58,65.64.28.82.63,1.38,1.59,1.69,17.16,5.46,112.07,35.95,142.39,45.66,1.8.58,3.46,1.14,5.38,1.42,1.12.16,1.27-1.27,1.35-2.09.44-4.32,5.23-49.42,6.03-57.22Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m489.51,748.67c1.02,4.1-8.28,34.89-11.14,37.04,2.33-12.76,6.04-25.11,11.14-37.04Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m504.42,709.02c-.79-4.94,7.42-32.85,10.37-35.27-2.22,12.11-5.5,23.92-10.37,35.27Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m488.45,764.53c2.53-11.96,5.86-23.68,10.9-34.88-2.09,12.09-5.56,23.77-10.07,35.13-.28-.08-.56-.17-.83-.25Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m474.1,818.26c1.94-11.55,5.38-22.63,9.61-33.48.25.07.49.15.74.22-2.12,11.49-5.76,22.52-10.35,33.26Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m507.68,713.67c-2.18,11.32-5.46,22.31-9.84,33.43-.66-3.74,7.25-30.4,9.84-33.43Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m416.97,729.83c.2-5.36,3.1-10.13,8.27-13.56,8.31-5.53,20.12-4.29,27.07,2.66,7.63,7.63,6.28,18.96-1.18,25.25-9.58,8.08-24.8,5.93-31.59-4.49-1.67-2.56-2.59-5.76-2.57-9.86Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m371.99,716.9c.24-5.5,3.15-10.28,8.29-13.73,7.39-4.97,17.44-4.79,24.66.37,9.07,6.48,9.63,18.91,1.28,26.3-8.69,7.69-22.06,6.8-29.43-.59-3.14-3.15-4.78-7.13-4.8-12.35Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m345.67,721.6c-5.1-.39-10.37-2.06-14.24-6.75-5.2-6.31-4.93-14.07.33-20.73,6.75-8.54,20.97-10.09,29.48-3.37,7.74,6.12,8.51,16.75,1.74,23.93-4.5,4.77-10.09,6.92-17.31,6.92Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m466.53,636.99c4.6.35,9.67,1.83,13.72,5.86,6.99,6.97,6.27,17.11-1.62,23.08-8,6.05-20.74,5.01-27.63-2.26-7.2-7.59-5.5-18.68,3.63-23.86,3.41-1.93,7.06-2.85,11.89-2.83Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m423.22,657.35c-5.85-.05-10.66-1.58-14.66-5.15-6.77-6.06-6.81-15.56-.18-21.88,7.44-7.09,20.98-7.1,28.43-.01,7,6.66,6.32,17.11-1.51,22.89-3.74,2.76-7.98,4.06-12.08,4.16Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m379.38,644.94c-5.13.03-9.71-1.56-13.53-4.98-6.3-5.64-6.39-14.72-.3-20.91,7.01-7.13,19.83-7.92,27.67-1.72,8.02,6.35,7.61,17.33-.9,23.56-3.84,2.81-8.21,4.07-12.94,4.05Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m322.52,759.82c7.47.49,14.27,3.06,20.7,6.74,2.46,1.41,4.81,3.01,6.67,5.18,1.21,1.41,2.72,2.89,1.79,4.99-.91,2.06-3.02,2.12-4.87,2.18-5.17.17-10.05-1.26-14.78-3.16-4.83-1.94-9.44-4.3-13.24-7.96-1.14-1.09-2.18-2.25-2.62-3.83-.45-1.62.04-2.7,1.57-3.44,1.53-.73,3.16-.64,4.79-.69Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m368.49,791.22c9.32.88,17.79,3.98,25.19,9.81,1.11.87,2.05,1.93,2.7,3.23.87,1.74.36,3.21-1.46,3.9-2.55.98-5.18.73-7.75.28-7.84-1.38-15.11-4.16-21.46-9.08-1.16-.9-2.2-1.94-2.96-3.22-1.27-2.14-.68-3.71,1.66-4.49,1.33-.44,2.69-.33,4.07-.44Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m410.7,820.19c9.28.92,17.87,3.7,25.22,9.66,1.37,1.11,2.91,2.5,2.23,4.49-.67,1.94-2.73,1.93-4.42,2.04-3.11.21-6.15-.4-9.14-1.18-5.7-1.5-11.12-3.67-15.94-7.16-1.44-1.05-2.81-2.2-3.59-3.87-.68-1.46-.29-2.52,1.15-3.23,1.05-.52,2.19-.69,4.48-.75Z"/>
        </g>
      </g>
    </svg>
  )
}