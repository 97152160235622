export const SmallFlame47 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="289.93 770.42 309.01 791.67 487.52 844.28 543.89 665.92 368.42 602.47 340.44 615.97 289.93 770.42"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m545.05,664.5c-.72-.26-1.44-.52-2.17-.78-.94-6.07-3.07-11.8-5.05-17.59-3.92-11.45-11.65-20.87-16.56-31.81-10.7-23.85-27.51-41.63-51.92-52.01-15.11-6.43-29.06-15.11-40.45-26.98-19.58-20.42-44.11-30.9-70.89-37.33-1.05-.25-2.19-1-3.1.2,1.09,4.41,2.36,8.72,3.2,13.11,3.4,17.84-3.82,29.05-21.43,33.33-8.5,2.07-16.96,2.63-25.59.02-8.43-2.55-16.94-4.85-25.22-7.88-13.71-5.01-27.37-10.16-41.16-15.28-.85,1.19-1.57,2.09-2.16,3.07-1.53,2.57-3.21,5.08-4.47,7.78-18.1,38.83-13.61,76.48,5.56,113.6,8.13,15.74,13.03,31.31,7.87,50.34-15.37,56.77,9.14,110.32,60.21,135.26,31.95,15.6,65.48,17.43,99.81,9.83,9.86-2.18,19.44-5.21,29.52-9.58,15.34,4.54,30.68,9.08,46.02,13.64,1.53.46,2.08.27,2.59-1.33,6.53-20.72,13.08-41.43,19.65-62.13,11.33-13.53,20.57-28.35,26.84-44.88,8.06-21.27,11.74-43.02,8.15-65.46.52-1.62,1.03-3.25,1.55-4.88.31-.97.7-1.72-.8-2.25Zm-237.05,121.9c-1.36-1.28-7.43-7.01-11.87-11.33-1.36-1.72-2.72-3.43-4.07-5.15,17.07-51.78,34.02-103.18,50.96-154.58,7.97-2.72,15.75-5.37,23.42-7.99-2.13,6.53-4.25,13.06-6.38,19.59-8.55,26.2-17.11,52.4-25.66,78.6-6.82,20.88-13.65,41.76-20.47,62.64-1.98,6.07-3.95,12.14-5.93,18.21Zm178.46,50.64c-.86,2.7-.49,2.34-2.92,1.62-46.47-13.8-92.93-27.63-139.39-41.46-9.63-2.87-19.26-5.77-28.91-8.57-1.33-.39-1.42-.86-1.03-2.05,11.6-35.41,23.17-70.84,34.74-106.26,7.68-23.53,15.37-47.07,23.01-70.61.4-1.22.79-1.49,2.06-1.04,32.85,11.85,65.71,23.66,98.58,35.47,21.92,7.88,43.85,15.76,65.77,23.66.59.21,1.62.14,1.24,1.62-.71,2.25-1.51,4.8-2.32,7.34-9.95,31.38-19.91,62.75-29.86,94.13-6.99,22.05-13.97,44.1-20.97,66.15Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m372.24,737.21c-10.96,0-20.05,9.26-19.96,20.58.09,10.57,8.03,18.99,19.23,19.38,11.29.03,20.35-9.1,20.35-20.53,0-10.85-8.66-19.43-19.62-19.44Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m401.41,687.1c11.02,0,19.48-9.08,19.37-20.08-.09-11.03-8.63-19.85-19.98-19.9-10.58-.05-19.13,9.11-19.05,20.26.08,10.32,8.21,19.72,19.66,19.73Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m415.12,751.21c-11.3.97-18.76,10.74-18.42,20.89.53,12.02,10.35,19.26,20.34,18.44,11.1-.91,19.04-10.79,18.11-22.19-.83-10.07-10.06-18-20.02-17.14Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m444.42,701.71c10.35.18,18.97-8.58,19.12-19.43.15-11.18-8.27-19.89-19.33-19.99-9.93-.09-18.53,8.86-18.68,19.43-.16,10.94,8.21,19.79,18.89,19.99Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m458.12,764.88c-11.85.64-20.61,12.76-17.71,24.52,2.27,9.2,9.41,14.19,17.6,14.24,10.75.07,19.28-8.88,19.36-19.81.09-11.15-8.44-19.54-19.26-18.95Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m487.31,677.14c-10.77-.23-19.15,8.49-19.14,20.85-.04,2.5,1.04,5.96,2.95,9.18,3.67,6.22,11.4,9.72,18.39,8.51,11.74-2.03,18.02-14.53,14.91-24.99-2.49-8.38-9.46-13.4-17.11-13.56Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m338.35,638.11c-1.17-.41-4.66,6.57-7.79,15.58-3.13,9.01-4.71,16.65-3.54,17.06,1.17.41,4.66-6.57,7.79-15.58,3.13-9.01,4.71-16.65,3.54-17.06Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m322.27,720.15c-1.19-.37-4.71,7.63-7.86,17.86-3.16,10.23-4.75,18.82-3.57,19.19s4.71-7.63,7.86-17.86c3.16-10.23,4.75-18.82,3.57-19.19Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m327.16,471.73c-.22,6.32,2.54,10.43,7.52,13.56,8.84,5.56,18.68,8.32,30.3,10.85-8.71-6.87-12.52-14.9-14.05-24.22-.49-2.98-2.07-5.59-4.02-7.93-6.1-7.33-13.94-12.23-22.49-16.13-.2-.09-.68.42-1.08.68,2.42,7.65,4.09,15.33,3.82,23.17Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m160.64,469.84c-1.59,7.85,9.04,27.79,17.06,32.52-.05-8.85-2.09-17.17-6.15-24.91-2.19-4.19-5.12-7.76-10.91-7.6Z"/>
        </g>
      </g>
    </svg>
  )
}