export const BigFlame48 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="285.36 762.49 311.32 799.01 485.8 849.81 547.89 675.56 374.81 609.76 338.85 608.75 285.36 762.49"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m146.47,446.25c10.63-35.55-17.59-59.04-34.87-86.65-13.33,35.27,23.17,56.36,34.87,86.65Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m549.13,676.04c.82-2.29,1.05-1.89-1.34-2.79-1.44-.55-3.06-1.16-4.83-1.83,2.03-30.61-5.69-61.69-24.25-86.45-16.25-21.83-26.37-47.01-41.38-69.52,1.61,13.34,5.26,25.97,1.83,38.12-13.81,4.6-26.11-31.38-33-40.66-37.76-67.66-80.41-36.15-112.2-97.62-5.84,60.93,43.88,55.38,47.94,97.23-17.46-5.4-33.72-13.73-52.62-13.93-55.62-5.14-123.66-12.8-155.4-65.52.52,34.12,24.86,65.51,44.48,91.73,22.45,25.04,30.43,56.84,30.97,89.8-7.34.76-13.6-1.07-19.64-5.2,6.21,31.74,14.57,62.53,10.29,95.32-5.42-4.16-8.73-10.02-16.09-12.69,20.17,32.49,15.6,73.83,46.12,99.19,39.99,36.53,92.95,53.29,145.71,38.98,33.65,9.88,63.73,18.71,69.38,20.39,1.35.4,1.9.16,2.33-1.21.66-2.07,18.26-51.44,35.85-100.78,7.2-12.76,12.12-26.31,14.53-40.76,4.19-11.76,8.06-22.63,11.32-31.79Zm-238.71,116.41c-.59.09-22.51-33.04-23.43-33.69-.04-.03-.07-.03-.11-.04,4.44-12.81,17.12-49.4,18.28-52.23,1.22-2.99,26.65-75.81,33.27-94.79,3.79.18,27.5,1.34,32.57,1.66,2.29.15,2.3.07,1.56,2.18-7.03,20.06-59.46,169.91-61.44,175.65-.16.47-.03,1.16-.7,1.26Zm3-111.35l-27.36,78.27c-.05-.08-.1-.16-.15-.24,9.5-26.76,18.51-52.4,27.51-78.03Zm170.92,162.2c-.34,1-.71,1.27-1.79.95-27.2-8.08-54.42-16.12-81.63-24.16-27.46-8.12-54.92-16.25-82.4-24.33-1.35-.4-1.54-.79-1.07-2.11,20.55-58.53,41.05-117.07,61.56-175.61.79-2.25.8-2.24,3.07-1.38,41.91,15.76,83.81,31.51,125.72,47.27,11.47,4.31,22.94,8.62,34.41,12.94.43.16,1.11.11,1.07.83-.22.65-.42,1.25-.63,1.84-17.85,50.1-35.69,100.2-53.54,150.29-1.6,4.49-3.22,8.97-4.77,13.47Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m324.44,723.19c-.63-.26-1.05.27-1.48.64-.72.62-1.27,1.39-1.76,2.2-4.19,6.87-6.65,14.41-8.46,22.19-.72,3.09-1.37,6.22-.97,9.45.09.71.2,1.42.94,1.65.7.22,1.18-.38,1.61-.79.52-.5.93-1.13,1.35-1.73,2.01-2.91,3.41-6.12,4.67-9.4,2.46-6.41,4.47-12.95,4.99-19.85.04-.47.13-.94.12-1.4,0-.47-.13-.93-.15-1.4-.03-.67-.22-1.3-.86-1.56Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m339.34,631.04c-.63-.26-1.05.27-1.48.64-.72.62-1.27,1.39-1.76,2.2-4.19,6.87-6.65,14.41-8.46,22.19-.72,3.09-1.37,6.22-.97,9.45.09.71.2,1.42.94,1.65.7.22,1.18-.38,1.61-.79.52-.5.93-1.13,1.35-1.73,2.01-2.91,3.41-6.12,4.67-9.4,2.46-6.41,4.47-12.95,4.99-19.85.04-.47.13-.94.12-1.4,0-.47-.13-.93-.15-1.4-.03-.67-.21-1.29-.86-1.56Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m407.28,695.36c10.59-.6,18.85-9.51,18.85-20.32.09-10.95-8.11-19.76-19.24-19.67-10.3.08-18.38,7.8-19.4,18.59-1.14,12.07,8.28,22.06,19.79,21.41Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m376.04,745.05c-11.02.1-20.25,9-20.26,20.73,0,10.58,7.86,18.43,18.51,18.59,11.22.16,20.62-9.29,20.62-20.4,0-10.74-8.13-18.89-18.87-18.91Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m452.93,709.5c8.89-2.3,15.05-10.46,14.97-19.86.08-2.17-.33-4.27-1.02-6.32-3.65-10.85-15.92-15.76-25.9-10.3-9.54,5.21-13.18,16.63-8.73,26.51,3.54,7.86,12.36,12.12,20.67,9.97Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m419.08,758.9c-1.79-.02-3.55.24-5.26.75-9.75,2.95-16.04,12.81-14.34,22.91,1.47,8.73,7.82,14.13,16.14,14.87,10.62.94,19.78-7.29,21.22-17.52,1.61-11.4-6.35-20.9-17.75-21.02Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m482.86,687.7c-6.01,3.09-9.49,8.15-10.66,14.83-1.95,11.06,5.82,21.55,16.79,21.69,1.31.08,2.6-.11,3.88-.38,11.16-2.27,17.55-14.51,14.73-24.94-2.94-10.9-14.72-16.37-24.74-11.21Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m460.08,772.28c-10.5.46-18.87,9.5-18.9,20.15-.11,11.82,9.95,19.62,20.93,17.35,10.32-2.14,17.51-13.25,15.38-23.58-1.77-8.58-8.91-14.29-17.4-13.91Z"/>
        </g>
      </g>
    </svg>
  )
}