/**
 * Checks exact match, then checks substring, for profanity based on a predetermined blacklist of strings
 * Console.error out a warning if passed strings with space or special symbols  blacklist doesn't account for variants
 * With all variants included, we would have to start benchmarking performance.
 * @param text
 * @returns
 */
export function isProfane(text: string) {
    if (text.includes(" ") || specialSymbolsPattern.test(text)) {
        console.error(`profanityChecker.ts > isProfane(string) has been passed an unsanitized string containing space(s) or invalid symbols; "${text}", this may cause some profanity to bypass checking.`);
        console.error("Please limit text to alphanumeric.");
    }

    // Normalize text before checking for profanity
    // Eg. 'áss' will be normalized to 'ass', which will correctly be identified as profanity
    let normalizedText = text.normalize("NFKD").replace(/[\u0300\u036f]/g, '');
    // console.log(`Prenormalized text=${text}, Normalized text=${normalizedText}`);
    // check exact match
    if (profanityDefinition.indexOf(normalizedText) > 1) {
        // found exact match
        return true;
    }

    // Check for profanity (PT), no normalize needed
    if (profanityDefinition.indexOf(text) > 1) {
        // found exact match (PT)
        return true;
    }
    
    // no exact match so check substring
    let containsProfaneSubstring = false;
    // TODO  currently too many false positives to strictly catch all substrings using the following code;
    // Eg.  "BassPlayer" is not an issue, but since it has "ass" in it, this would get flagged.
    // for (let index = 0; index < profanityDefinition.length; index++) {
    //     const eachProfanity = profanityDefinition[index];
    //     // skip check if previously found profane substring
    //     if (text.includes(eachProfanity)) {
    //         containsProfaneSubstring = true;
    //         break; // end early since it doesn't matter how many different profanities are found
    //     }
    // }
    return containsProfaneSubstring;
    // }
}

const specialSymbolsPattern = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;

/**
 * Limited to ALPHANUMERICs, no variant checking with spaces, symbols.
 * Eg. Checks for "Ass", "ass", "aSs", but not "A$$" nor " A$S"
 */
const profanityDefinition: string[] = [
    "2girls1cup",
    "anal",
    "anus",
    "areole",
    "arian",
    "arrse",
    "arse",
    "arsehole",
    "aryan",
    "asanchez",
    "ass",
    "assbang",
    "assbanged",
    "asses",
    "assfuck",
    "assfucker",
    "assfukka",
    "asshole",
    "assmunch",
    "asswhole",
    "autoerotic",
    "ballsack",
    "bastard",
    "bdsm",
    "beastial",
    "beastiality",
    "bellend",
    "bestial",
    "bestiality",
    "bimbo",
    "bimbos",
    "bitch",
    "bitches",
    "bitchin",
    "bitching",
    "blowjob",
    "blowjobs",
    "bluewaffle",
    "bondage",
    "boner",
    "boob",
    "boobs",
    "booobs",
    "boooobs",
    "booooobs",
    "booooooobs",
    "bootycall",
    "breasts",
    "brownshower",
    "brownshowers",
    "buceta",
    "bukake",
    "bukkake",
    "bullshit",
    "busty",
    "butthole",
    "carpetmuncher",
    "cawk",
    "chink",
    "cipa",
    "clit",
    "clitoris",
    "clits",
    "cnut",
    "cock",
    "cockface",
    "cockhead",
    "cockmunch",
    "cockmuncher",
    "cocks",
    "cocksuck",
    "cocksucked",
    "cocksucker",
    "cocksucking",
    "cocksucks",
    "cokmuncher",
    "coon",
    "cowgirl",
    "cowgirls",
    "crap",
    "crotch",
    "cum",
    "cuming",
    "cummer",
    "cumming",
    "cums",
    "cumshot",
    "cunilingus",
    "cunillingus",
    "cunnilingus",
    "cunt",
    "cuntlicker",
    "cuntlicking",
    "cunts",
    "damn",
    "deepthroat",
    "dick",
    "dickhead",
    "dildo",
    "dildos",
    "dink",
    "dinks",
    "dlck",
    "dogstyle",
    "dogfucker",
    "doggiestyle",
    "doggin",
    "dogging",
    "doggystyle",
    "dong",
    "donkeyribber",
    "doofus",
    "doosh",
    "dopey",
    "douche",
    "douchebag",
    "douchebags",
    "douchey",
    "drunk",
    "duche",
    "dumass",
    "dumbass",
    "dumbasses",
    "dummy",
    "dyke",
    "dykes",
    "eatadick",
    "eathairpie",
    "ejaculate",
    "ejaculated",
    "ejaculates",
    "ejaculating",
    "ejaculatings",
    "ejaculation",
    "ejakulate",
    "enlargement",
    "erect",
    "erection",
    "erotic",
    "erotism",
    "essohbee",
    "extacy",
    "extasy",
    "facial",
    "fack",
    "fag",
    "fagg",
    "fagged",
    "fagging",
    "faggit",
    "faggitt",
    "faggot",
    "faggs",
    "fagot",
    "fagots",
    "fags",
    "faig",
    "faigt",
    "fanny",
    "fannybandit",
    "fannyflaps",
    "fannyfucker",
    "fanyy",
    "fart",
    "fartknocker",
    "fat",
    "fatass",
    "fcuk",
    "fcuker",
    "fcuking",
    "feck",
    "fecker",
    "felch",
    "felcher",
    "felching",
    "fellate",
    "fellatio",
    "feltch",
    "feltcher",
    "femdom",
    "fingerfuck",
    "fingerfucked",
    "fingerfucker",
    "fingerfuckers",
    "fingerfucking",
    "fingerfucks",
    "fingering",
    "fisted",
    "fistfuck",
    "fistfucked",
    "fistfucker",
    "fistfuckers",
    "fistfucking",
    "fistfuckings",
    "fistfucks",
    "fisting",
    "fisty",
    "flange",
    "flogthelog",
    "floozy",
    "foad",
    "fondle",
    "foobar",
    "fook",
    "fooker",
    "footjob",
    "foreskin",
    "freex",
    "frigg",
    "frigga",
    "fubar",
    "fuck",
    "fucka",
    "fuckass",
    "fuckbitch",
    "fucked",
    "fucker",
    "fuckers",
    "fuckface",
    "fuckhead",
    "fuckheads",
    "fuckhole",
    "fuckin",
    "fucking",
    "fuckings",
    "fuckingshitmotherfucker",
    "fuckme",
    "fuckmeat",
    "fucknugget",
    "fucknut",
    "fuckoff",
    "fuckpuppet",
    "fucks",
    "fucktard",
    "fucktoy",
    "fucktrophy",
    "fuckup",
    "fuckwad",
    "fuckwhit",
    "fuckwit",
    "fuckyomama",
    "fudgepacker",
    "fuk",
    "fuker",
    "fukker",
    "fukkin",
    "fukking",
    "fuks",
    "fukwhit",
    "fukwit",
    "futanari",
    "futanary",
    "fux",
    "fuxor",
    "fxck",
    "gae",
    "gai",
    "gangbang",
    "gangbanged",
    "gangbangs",
    "ganja",
    "gassyass",
    "gay",
    "gaylord",
    "gays",
    "gaysex",
    "gey",
    "gfy",
    "ghay",
    "ghey",
    "gigolo",
    "glans",
    "goatse",
    "god",
    "godamn",
    "godamnit",
    "goddam",
    "goddammit",
    "goddamn",
    "goddamned",
    "gokkun",
    "goldenshower",
    "gonad",
    "gonads",
    "gook",
    "gooks",
    "gringo",
    "gspot",
    "gtfo",
    "guido",
    "hamflap",
    "handjob",
    "hardcoresex",
    "hardon",
    "hebe",
    "heeb",
    "hell",
    "hemp",
    "hentai",
    "heroin",
    "herp",
    "herpes",
    "herpy",
    "heshe",
    "hitler",
    "hiv",
    "hoar",
    "hoare",
    "hobag",
    "hoer",
    "homey",
    "homo",
    "homoerotic",
    "homoey",
    "honky",
    "hooch",
    "hookah",
    "hooker",
    "hoor",
    "hootch",
    "hooter",
    "hooters",
    "hore",
    "horniest",
    "horny",
    "hotsex",
    "howtokill",
    "howtomurdep",
    "hump",
    "humped",
    "humping",
    "hussy",
    "hymen",
    "inbred",
    "incest",
    "injun",
    "jackass",
    "jackhole",
    "jackoff",
    "jap",
    "japs",
    "jerk",
    "jerked",
    "jerkoff",
    "jism",
    "jiz",
    "jizm",
    "jizz",
    "jizzed",
    "junkie",
    "junky",
    "kawk",
    "kike",
    "kikes",
    "kill",
    "kinbaku",
    "kinky",
    "kinkyJesus",
    "kkk",
    "klan",
    "knob",
    "knobead",
    "knobed",
    "knobend",
    "knobhead",
    "knobjocky",
    "knobjokey",
    "kock",
    "kondum",
    "kondums",
    "kooch",
    "kooches",
    "kootch",
    "kraut",
    "kum",
    "kummer",
    "kumming",
    "kums",
    "kunilingus",
    "kwif",
    "kyke",
    "l3itch",
    "labia",
    "lech",
    "len",
    "leper",
    "lesbians",
    "lesbo",
    "lesbos",
    "lez",
    "lezbian",
    "lezbians",
    "lezbo",
    "lezbos",
    "lezzie",
    "lezzies",
    "lezzy",
    "lmao",
    "lmfao",
    "loin",
    "loins",
    "lube",
    "lust",
    "lusting",
    "lusty",
    "mfucking",
    "mafugly",
    "mams",
    "masochist",
    "massa",
    "masterb8",
    "masterbate",
    "masterbating",
    "masterbation",
    "masterbations",
    "masturbate",
    "masturbating",
    "masturbation",
    "maxi",
    "menses",
    "menstruate",
    "menstruation",
    "meth",
    "milf",
    "mofo",
    "molest",
    "moolie",
    "moron",
    "mothafuck",
    "mothafucka",
    "mothafuckas",
    "mothafuckaz",
    "mothafucked",
    "mothafucker",
    "mothafuckers",
    "mothafuckin",
    "mothafucking",
    "mothafuckings",
    "mothafucks",
    "motherfuck",
    "motherfucka",
    "motherfucked",
    "motherfucker",
    "motherfuckers",
    "motherfuckin",
    "motherfucking",
    "motherfuckings",
    "motherfuckka",
    "motherfucks",
    "mtherfucker",
    "mthrfucker",
    "mthrfucking",
    "muff",
    "muffdiver",
    "muffpuff",
    "murder",
    "mutha",
    "muthafecker",
    "muthafuckaz",
    "muthafucker",
    "muthafuckker",
    "muther",
    "mutherfucker",
    "mutherfucking",
    "muthrfucking",
    "nad",
    "nads",
    "naked",
    "napalm",
    "nappy",
    "nazi",
    "nazism",
    "needthedick",
    "negro",
    "nig",
    "nigg",
    "nigga",
    "niggah",
    "niggas",
    "niggaz",
    "nigger",
    "niggers",
    "niggle",
    "niglet",
    "nimrod",
    "ninny",
    "nipple",
    "nipples",
    "nob",
    "nobhead",
    "nobjocky",
    "nobjokey",
    "nooky",
    "nude",
    "nudes",
    "numbnuts",
    "nutbutter",
    "nutsack",
    "nympho",
    "omg",
    "opiate",
    "opium",
    "oral",
    "orally",
    "organ",
    "orgasim",
    "orgasims",
    "orgasm",
    "orgasmic",
    "orgasms",
    "orgies",
    "orgy",
    "ovary",
    "ovum",
    "ovums",
    "paddy",
    "paki",
    "pantie",
    "panties",
    "panty",
    "pastie",
    "pasty",
    "pawn",
    "pcp",
    "pecker",
    "pedo",
    "pedophile",
    "pedophilia",
    "pedophiliac",
    "pee",
    "peepee",
    "penetrate",
    "penetration",
    "penial",
    "penile",
    "penis",
    "penisfucker",
    "perversion",
    "peyote",
    "phalli",
    "phallic",
    "phonesex",
    "phuck",
    "phuk",
    "phuked",
    "phuking",
    "phukked",
    "phukking",
    "phuks",
    "phuq",
    "pigfucker",
    "pillowbiter",
    "pimp",
    "pimpis",
    "pinko",
    "piss",
    "pissed",
    "pisser",
    "pissers",
    "pisses",
    "pissflaps",
    "pissin",
    "pissing",
    "pissoff",
    "playboy",
    "pms",
    "polack",
    "pollock",
    "poon",
    "poontang",
    "poop",
    "porn",
    "porno",
    "pornography",
    "pornos",
    "pot",
    "potty",
    "prick",
    "pricks",
    "prig",
    "pron",
    "prostitute",
    "prude",
    "pube",
    "pubic",
    "pubis",
    "punkass",
    "punky",
    "puss",
    "pusse",
    "pussi",
    "pussies",
    "pussy",
    "pussyfart",
    "pussypalace",
    "pussypounder",
    "pussys",
    "puto",
    "queaf",
    "queef",
    "queer",
    "queero",
    "queers",
    "quicky",
    "quim",
    "racy",
    "rape",
    "raped",
    "raper",
    "raping",
    "rapist",
    "raunch",
    "rectal",
    "rectum",
    "rectus",
    "reefer",
    "reetard",
    "reich",
    "retard",
    "retarded",
    "revue",
    "rimjaw",
    "rimjob",
    "rimming",
    "ritard",
    "rtard",
    "rum",
    "rump",
    "rumprammer",
    "ruski",
    "sadism",
    "sadist",
    "sandbar",
    "sausagequeen",
    "scag",
    "scantily",
    "schizo",
    "schlong",
    "screw",
    "screwed",
    "screwing",
    "scroat",
    "scrog",
    "scrot",
    "scrote",
    "scrotum",
    "scrud",
    "scum",
    "seaman",
    "seamen",
    "seduce",
    "semen",
    "sex",
    "sexual",
    "shag",
    "shagger",
    "shaggin",
    "shagging",
    "shamedame",
    "shemale",
    "shibari",
    "shibary",
    "shit",
    "shitdick",
    "shite",
    "shiteater",
    "shited",
    "shitey",
    "shitface",
    "shitfuck",
    "shitfucker",
    "shitfull",
    "shithead",
    "shithole",
    "shithouse",
    "shiting",
    "shitings",
    "shits",
    "shitt",
    "shitted",
    "shitter",
    "shitters",
    "shitting",
    "shittings",
    "shitty",
    "shiz",
    "shota",
    "sissy",
    "skag",
    "skank",
    "slave",
    "sleaze",
    "sleazy",
    "slope",
    "slut",
    "slutbucket",
    "slutdumper",
    "slutkiss",
    "sluts",
    "smegma",
    "smut",
    "smutty",
    "snatch",
    "sniper",
    "snuff",
    "sob",
    "sodom",
    "sonofabitch",
    "souse",
    "soused",
    "spac",
    "sperm",
    "spic",
    "spick",
    "spik",
    "spiks",
    "spooge",
    "spunk",
    "steamy",
    "stfu",
    "stiffy",
    "stoned",
    "strip",
    "stripclub",
    "stroke",
    "stupid",
    "suck",
    "sucked",
    "sucking",
    "sumofabiatch",
    "tampon",
    "tard",
    "tawdry",
    "teabagging",
    "teat",
    "teets",
    "teez",
    "terd",
    "teste",
    "testee",
    "testes",
    "testical",
    "testicle",
    "testis",
    "threesome",
    "throating",
    "thrust",
    "thug",
    "tinkle",
    "tit",
    "titfuck",
    "titi",
    "tits",
    "titt",
    "tittiefucker",
    "titties",
    "titty",
    "tittyfuck",
    "tittyfucker",
    "tittywank",
    "titwank",
    "toke",
    "toots",
    "tosser",
    "tramp",
    "transsexual",
    "trashy",
    "tubgirl",
    "turd",
    "tush",
    "twat",
    "twathead",
    "twats",
    "twatty",
    "twink",
    "twunt",
    "twunter",
    "ugly",
    "undies",
    "unwed",
    "urinal",
    "urine",
    "uterus",
    "uzi",
    "vag",
    "vagina",
    "valium",
    "viagra",
    "vigra",
    "virgin",
    "vixen",
    "vodka",
    "vomit",
    "voyeur",
    "vulgar",
    "vulva",
    "wad",
    "wang",
    "wank",
    "wanker",
    "wanky",
    "wazoo",
    "wedgie",
    "weed",
    "weenie",
    "weewee",
    "weiner",
    "weirdo",
    "wench",
    "wetback",
    "whitey",
    "whiz",
    "whoar",
    "whoralicious",
    "whore",
    "whorealicious",
    "whored",
    "whoreface",
    "whorehopper",
    "whorehouse",
    "whores",
    "whoring",
    "wigger",
    "willies",
    "willy",
    "womb",
    "woody",
    "woose",
    "wop",
    "wtf",
    "xrated2g1c",
    "xx",
    "xxx",
    "yaoi",
    "yury",
    "aborto",
    "amador",
    "ânus",
    "aranha",
    "ariano",
    "balalao",
    "bastardo",
    "bicha",
    "biscate",
    "bissexual",
    "boceta",
    "boob",
    "bosta",
    "brauliodeborracha",
    "bumbum",
    "burro",
    "cabrao",
    "cacete",
    "cagar",
    "camisinha",
    "caralho",
    "cerveja",
    "chochota",
    "chupar",
    "clitoris",
    "cocaína",
    "coito",
    "colhoes",
    "comer",
    "cona",
    "consolo",
    "corno",
    "cu",
    "darorabo",
    "dumraio",
    "esporra",
    "fecal",
    "filhodaputa",
    "foda",
    "fodase",
    "foder",
    "frangoassado",
    "gozar",
    "grelho",
    "heroína",
    "heterosexual",
    "homemgay",
    "homoerótico",
    "homosexual",
    "inferno",
    "lésbica",
    "lolita",
    "mama",
    "merda",
    "paneleiro",
    "passarumcheque",
    "pau",
    "peidar",
    "pênis",
    "pinto",
    "porra",
    "puta",
    "putaquepariu",
    "putaquetepariu",
    "queca",
    "sacanagem",
    "saco",
    "torneira",
    "transar",
    "vadia",
    "vaitefoder",
    "vaitomarnocu",
    "veado",
    "vibrador",
    "xana",
    "xochota",
    "anal",
    "anus",
    "arregaçada",
    "arrombada",
    "babaovo",
    "babaca",
    "babaovo",
    "bacura",
    "bagos",
    "baitola",
    "bebum",
    "benga",
    "besta",
    "bisca",
    "bixa",
    "boazuda",
    "boco",
    "bocó",
    "boiola",
    "bolagato",
    "bosseta",
    "bostana",
    "braulio",
    "brioco",
    "bronha",
    "buca",
    "buceta",
    "bunda",
    "bunduda",
    "buraco",
    "burra",
    "busseta",
    "caceta",
    "caga",
    "cagado",
    "cagalhao",
    "cagalhão",
    "cagao",
    "cagona",
    "canalha",
    "casseta",
    "cassete",
    "checheca",
    "chereca",
    "chibumba",
    "chibumbo",
    "chifruda",
    "chifrudo",
    "chota",
    "clitóris",
    "cocô",
    "corna",
    "cornuda",
    "cornudo",
    "cretina",
    "cretino",
    "cuzao",
    "cuzuda",
    "cuzudo",
    "cuzão",
    "cú",
    "darumacagada",
    "debil",
    "debiloide",
    "defunto",
    "demonio",
    "demônio",
    "doida",
    "doido",
    "débil",
    "escrota",
    "escroto",
    "estupido",
    "estúpido",
    "favelado",
    "fedida",
    "fedido",
    "fedorenta",
    "fedorento",
    "feia",
    "feio",
    "feiosa",
    "feioso",
    "feioza",
    "feiozo",
    "fodace",
    "fodao",
    "fodasse",
    "fode",
    "fodida",
    "fodido",
    "fodão",
    "gaiata",
    "gaiato",
    "grelo",
    "gruta",
    "idiota",
    "imbecil",
    "iscrota",
    "iscroto",
    "jeba",
    "ladra",
    "ladrao",
    "ladrona",
    "ladrão",
    "lalau",
    "leprosa",
    "leproso",
    "machao",
    "machona",
    "machorra",
    "machão",
    "maconha",
    "meleca",
    "mija",
    "mijada",
    "mijado",
    "mijar",
    "mijo",
    "mocrea",
    "mocreia",
    "mocréa",
    "mocréia",
    "naba",
    "otaria",
    "otario",
    "otária",
    "otário",
    "papaanjo",
    "paspalha",
    "paspalhao",
    "paspalho",
    "paspalhona",
    "paspalhão",
    "peido",
    "peidorreira",
    "peidorreiro",
    "peitinhos",
    "pemba",
    "perereca",
    "pica",
    "pilantra",
    "piranha",
    "piroca",
    "pirocao",
    "pirocão",
    "piru",
    "precheca",
    "prostituta",
    "prostituto",
    "punheta",
    "punhetao",
    "punheteira",
    "punheteiro",
    "punhetão",
    "puto",
    "puxasaco",
    "puxasaco",
    "quenga",
    "rabuda",
    "rabudao",
    "rabudo",
    "rabudona",
    "rabudão",
    "racha",
    "ramela",
    "ramelona",
    "ramelão",
    "rapariga",
    "ridicula",
    "ridiculo",
    "ridícula",
    "ridículo",
    "rola",
    "rolas",
    "rolona",
    "safada",
    "safado",
    "siririca",
    "tarada",
    "tarado",
    "tchuntcha",
    "testuda",
    "testudao",
    "testudo",
    "testudona",
    "testudão",
    "tesuda",
    "tesudo",
    "teta",
    "tezuda",
    "tezudo",
    "trolha",
    "trolxa",
    "trouxa",
    "troxa",
    "vagabunda",
    "vagabundo",
    "vagina",
    "vara",
    "veadao",
    "veadão",
    "viada",
    "viadao",
    "viadinha",
    "viadinho",
    "viado",
    "viadona",
    "viadão",
    "vibradorxana",
    "xaninha",
    "xavasca",
    "xerereca",
    "xibiu",
    "xibumba",
    "xota",
    "xoxota",
];