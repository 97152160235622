export const BigFlame52 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="271.57 721.99 317.86 819.03 463.7 859.17 557.82 715.6 480.4 640.69 351.58 579.47 271.57 721.99"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m72.43,351.97c5.2,5.19,10.8,10.04,14.11,18.35,1.24-21.44-9.49-38.02-14.93-56.25-14.63,9.92-10.35,27.54.82,37.89Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m559.23,715.21c-14.63-13.7-28.92-27.83-43.32-41.81.11-23.61-8.71-45.87-28.63-59.86-21.22-15.46-37.24-35.57-47.61-60.24-4.52,9.31-5.93,18.43-7.65,27.8-16.94-5.95-34.1-5.98-50.57-12.49-8.2-2.36-12.47-7.4-15.59-15.02-17.45-35.89-7.62-60.93-11.52-97.01-2.41-23.63-21.62-35.48-43.57-38.97-29.63-5.48-57.97-10.23-86.65-20.68,18.36,32.63,48.59,54.09,76.52,77.59,15.98,14.57,13.25,38.1,13.11,58.25-8.63-5.6-15.55-11.99-22.71-18.09-8.96-6.5-9.75-14.65-11.46-24.78-15.37-75.27-96.47-44.79-103.86-136.56-33.32,62.22-51.78,126.42,32.94,152.94,7.65,2.44,18.35,3.34,22.27,11.33,18.68,48.77,27.22,100.82,37.86,151.79,1.92,10.19-2.14,19.86-.94,31.2-5.24-4.32-8.99-8.07-15.35-9.31,17.86,30.68,16.01,74.16,46.91,94.41,5.3,12.01,10.68,23.95,17.36,34.63,49.13,13.41,98.29,26.72,147.44,40.02,32.7-45.77,63.26-95.4,95.3-142.41.76-1.15.7-1.8-.28-2.73Zm-6.47-3.65c-45.23-20.13-91.4-38.82-136.06-59.88-17.9-20.2-35.22-40.91-53.18-61.06-1.88-2.03-3.57-4.24-5.34-6.37-.22-.27-.51-.52-.39-1.03,41.16,19.44,83.41,37.81,123.71,58.86,23.44,23.35,48.51,45.82,71.26,69.47Zm-236.06,100.79c-14.18-29.71-28.13-59.26-42.32-88.92-.71-1.49-.61-2.6.2-4.05,23.16-41.63,46.68-83.06,69.44-124.9,1.63-2.96,3.42-5.82,5.09-8.75.69-1.21,1.13-.41,1.58.13,19.47,22.96,38.8,46.03,58.29,68.97-27.61,52.76-62.2,105.04-92.28,157.53Zm236.29-91.73c-30.13,44.68-59.15,90.87-89.76,134.84-45.95-12.7-91.93-25.34-137.9-38-2.16-.59-2.16-.59-1.06-2.42,26.86-44.95,53.72-89.9,80.58-134.84,3.68-6.16,7.38-12.3,11-18.49.63-1.07,1.15-1.29,2.33-.78,44.74,19.23,89.46,38.52,134.24,57.65,1.34.56,1.26.99.56,2.05Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m425.83,732.57c21.37.91,34.31-35.27,7.04-35.33-20.37-.06-33.49,33.76-7.04,35.33Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m387.79,774.99c-7.5,0-17.14,3.95-21.83,13.25-10.04,23.25,24.41,25.43,32.9,7.6,6.47-9.12-.36-21.37-11.06-20.85Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m448.16,730.13c-1.61,8.5,3.34,15.04,11.88,15.66,11.12,1,23.14-9.64,23.6-20.02-.85-23.96-33.43-13.03-35.48,4.36Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m423.55,786.9c-9.88.62-18.52,6.41-21.44,16.07-5.19,22.15,27.21,18.12,32.93,2.6,5.49-8.64-1.25-19.6-11.48-18.67Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m482.49,745.94c-.49,6.02,3.55,11.14,9.48,12.14,10.95,2.28,24.01-8.91,24.69-18.56-.47-25.53-34.19-11.33-34.17,6.42Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m456.41,798.15c-7.74.97-14.71,5.44-18.65,14.01-3.93,8.55,1.17,16.47,10.52,16.5,17.65,1.36,35.46-29.94,8.12-30.52Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m324.54,736c-6.26,6.64-8.84,14.74-9.42,23.63-.31,4.5,2.11,11.81,7.36,10.05,8.09-3.45,11.47-12.9,13.27-20.89,2.21-7.94-1.19-22.19-11.22-12.8Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m352.43,624.93c-.15-4.99-5.1-7.49-8.8-3.9-7.52,6.33-10.54,14.82-11.51,24.25-.27,6.52,3.93,10.85,9.53,6.04,7.26-6.48,11.5-16.67,10.79-26.4Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m419.66,640.37c6.62,8.47,20.89,16.77,31.25,19.25,12.39-.37-34.65-29.02-31.25-19.25Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m490.67,673.64c7.9,6.8,16.91,12.94,27.02,15.73,8.17-1.26-19.54-17.89-22.12-18.44-4.43-2.32-11.82-3.86-4.9,2.7Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m416.63,618.83c-8.06-6.64-17.13-12.76-27.4-15.16-7.88,1.04,20.89,17.64,23.33,17.76,3.78,1.75,9.75,2.65,4.07-2.6Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m463.28,637.32c-3.74-2.29-16.24-7.19-7.17,1.09,7.29,5.68,15.33,11.39,24.37,13.67,7.06-.38-15.41-14.23-17.21-14.76Z"/>
        </g>
      </g>
    </svg>
  )
}