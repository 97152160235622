export const YellowPlus = (props) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg"
      width={props?.w ?? "20"} 
      height={props?.h ?? "20"} 
      viewBox={props?.v ?? "8 4 20 20"}
    >
      <g opacity="0.5" filter="url(#filter0_d_923_889)">
      <ellipse cx="18.3591" cy="14.5" rx="7.95747" ry="8.5" fill="#ECAA1F"/>
      </g>
      <g filter="url(#filter1_d_923_889)">
      <ellipse cx="18.3609" cy="14.501" rx="6.08628" ry="6.50124" fill="#ECAA1F"/>
      </g>
      <rect x="15.0825" y="12.9998" width="6.55321" height="3" fill="black"/>
      <rect x="19.7632" y="11" width="7" height="2.80852" transform="rotate(90 19.7632 11)" fill="black"/>
      <defs>
      <filter id="filter0_d_923_889" x="0.401611" y="0" width="59.9149" height="61" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="12" dy="16"/>
      <feGaussianBlur stdDeviation="11"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.0985833 0 0 0 0 0.379167 0 0 0 0.33 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_923_889"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_923_889" result="shape"/>
      </filter>
      <filter id="filter1_d_923_889" x="2.27457" y="1.99976" width="56.1726" height="57.0024" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="12" dy="16"/>
      <feGaussianBlur stdDeviation="11"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0868924 0 0 0 0 0.0985833 0 0 0 0 0.379167 0 0 0 0.33 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_923_889"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_923_889" result="shape"/>
      </filter>
      </defs>
    </svg>
  )
}