export const BigFlame23 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="305.14 630.6 460.53 577.66 515.07 712.82 527.39 815.44 357.57 863.91 276.36 725.47 305.14 630.6"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m537.3,602.43c-34.41-25.32-36.56-12.28-40.94-58.1-8.09-37.8-55.77,10.69-94.76-44.95.51,10.84,2.22,21.14,3.88,31.99-35.91-13.03-71.71-24.32-108.8-33.65,23.47,57.24-6.54,113.09-32.15,163.39-34.55-13.7-66.02-30.04-82.57-66.67-3.15,32.36,6.4,61.6,17.63,90.97,7.53,19.8,10.95,40.78,18.07,60.69,2.74,7.65,6.82,14.9,7.92,23.05,6.9,56.49,68.94,79.16,119.67,76.37,5.02,8.1,8.94,14.41,11.05,17.92.68,1.13,1.26,1.41,2.58,1.04,18.47-5.24,130.3-36.57,167.74-47.03,1.29-.36,1.78-.91,1.6-2.26-.56-4.01-2.97-23.57-5.58-44.79,47.37-42.06,79.49-127.14,14.66-167.97Zm-64.81,3.62c-3.89-9.54-7.73-19.09-11.45-28.7l11.45,28.7Zm-18.22-25.78c1.18-.42,1.67-.27,1.66,1.01.49,13.86,1.03,41.61,1.01,41.61.37,13.55.47,27.13,1,40.67.1,1.19-.29,1.72-1.47,2.1-12.5,4.04-129.65,43.09-175.54,57.04,4.54-18.33,21.85-77.69,24.99-88.93.17-.61.41-1.07.76-1.44l52.69-18.77c32.36-11.32,62.53-21.9,94.88-33.29Zm65.77,234.65c-53.29,14.95-106.57,29.95-159.86,44.94-26.52-41.28-51.09-85.17-76.97-127.31-1.03-1.72-1.03-1.68.86-2.3,58.02-18.85,116.05-37.65,174.05-56.54,1.48-.48,2.04-.21,2.65,1.21,19.99,46.05,40.03,92.08,60.1,138.1.49,1.12.46,1.53-.83,1.9Zm5.8-7.01c-.01-.06,0-.11.08-.16-.08.04-.09.1-.08.16Zm-.34-.55c-19.83-46.06-39.52-92.14-59.31-138.22-.98-2.29-.6-4.76-.71-7.13-.99-26.08-2.02-52.12-2.98-78.2,17.65,41.59,34.01,83.91,51.2,125.76,1.1,2.75,1.02,5.75,1.38,8.65,2.38,17.45,9.34,75.98,10.68,87.78.04.38.22.8-.28,1.36Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m391.4,763.06c-12.98,10.98.76,26.62,14.12,26.26,4.89-.09,9.01-1.03,12.8-3.3,21.9-16.48-11.31-37.08-26.92-22.96Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m404.78,667.6c10.65-1.17,22.83-6.09,27.49-16.35,1.92-5.17-3.86-7.6-8.14-7.38-10.17.93-18.98,4.72-25.91,12.43-5.67,6.47-1.26,12.04,6.56,11.31Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m363.77,658.02c7-.3,13.22-2.88,18.95-6.72,5.93-3.16,14.41-14.63,2.35-15.29-10.8.25-22.24,5.53-27.81,14.95-2.45,4.66,2.34,7.37,6.51,7.06Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m324.22,648.89c8.39,2.06,16.97-2.64,23.65-7.36,5.48-3.58,10.9-12.95.13-12.74-8.16.51-15.6,4.21-21.71,9.53-2.84,2.21-7.25,8.46-2.06,10.56Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m486.84,696.36c-1.64-11.41-4.99-23.24-11.07-33.11-.38-.5-.79-1.31-1.59-.99-1.25.84-.33,3.71-.39,5.09,1.8,10.66,5.02,21.28,10.42,30.7.47.77.97,1.92,2.04,1.59,1.09-.45.42-2.09.59-3.29Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m506.28,734.95c-1.19-2.82-3.19-2.5-2.55.66,1.04,10.77,4.51,21.4,9.4,31.05.46.83.86,2.18,2,1.85,1.09-.31.42-1.65.63-2.55-1.37-10.71-4.51-21.41-9.48-31.01Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m499.36,707.49c-.65-6.82-6.34-29.38-11.7-32.61-1.09.88-.31,2.92-.36,4.19,1.72,10.07,4.72,20.08,9.62,29.08.43.73.85,1.88,1.87,1.57.98-.3.4-1.47.58-2.23Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m482.83,652.83c1.04-.27.32-1.54.58-2.4-1.63-10.68-4.87-21.49-10.36-30.85-.79-1.75-2.24-1.12-1.93.67,1.23,10.75,4.87,21.31,9.89,30.89.43.76.96,1.91,1.82,1.69Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m507.62,716.73c.41.79,1.11,1.76,1.95,1.56,1.02-.24.32-1.44.56-2.21-1.57-10.15-4.53-20.39-9.56-29.38-.64-1.45-1.9-.92-1.69.53,1.18,10.18,4.31,20.28,8.74,29.5Z"/>
        </g>
      </g>
    </svg>
  )
}