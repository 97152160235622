export const SmallFlame50 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="277.84 743.67 313.92 804.31 477.83 855.57 554.58 693.23 479.58 649.63 342.64 592.05 277.84 743.67"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m554.31,692.61c-7.14-4.07-14.24-8.24-21.33-12.43-4.6-41.29-30.9-74.47-65.21-96.41-22.57-17.44-49.92-36.29-79.51-34.2-22.73,8.32-47.34,15.23-71.64,11.34-26.19-4.92-37.25-31.13-55.02-48.36-40.72,41.67-102.26,129.93-35.1,173.99,8.98,5.02,17.5,10.82,24.57,18.08-2.95,99.31,88.76,152.75,162.87,134.91-27.59-7.93-54.93-15.79-82.28-23.66,48.58,13.9,97.14,27.85,145.7,41.83,1.06.31,1.64.28,2.18-.88,22.96-48.22,45.75-96.53,68.65-144.78,2.41-5.13,4.85-10.26,7.29-15.38,1.24-2.61,1.39-2.55-1.17-4.05Zm-8.4-3.87c.02-.06.02-.12-.02-.18.05.06.05.12.02.18Zm-54.6-31.86c5.38,3.2,10.94,6.25,16.41,9.37,1.56.95,3.11,1.91,4.65,2.88,6.54,3.85,12.98,9.19,20.09,11.53,4.4,2.51,8.77,5.06,13.04,7.76-.05.07-.11.14-.17.2.11.16.22.23.32.25-.1-.01-.22-.09-.32-.25-48.8-19.55-97.25-40.08-145.91-60.02-12.93-8.11-25.46-16.95-38.14-25.53,6.15,2.43,12.34,4.81,18.53,7.16,5.49,4.08,27.53,13.66,31.42,13.41-3.82-2.12-7.65-4.1-11.52-5.93,31.19,11.67,62.33,23.46,91.58,39.17Zm54.47,31.61s-.01-.01-.02-.02c.01,0,.01.01.02.02Zm-265.32,54.01c-.48-.83-.48-1.47-.11-2.32,20.12-47.14,40.17-94.3,60.3-141.43.82-1.92.86-1.93,2.61-.7,15.49,10.92,30.98,21.85,46.49,32.74,1.03.72,1.15,1.3.66,2.43-25.22,56.61-49.31,114.11-75.07,170.3-11.59-20.36-23.23-40.69-34.88-61.02Zm269.09-43.48c-24.57,50.87-47.76,103.15-72.72,153.52-50.94-14.67-102.17-29.98-153.11-44.61-1.31-.36-1.47-.82-.93-2.05,10.28-23.22,20.58-46.44,30.56-69.78,14.97-33.12,29.36-66.5,44.05-99.74.4-1.04.8-1.28,1.94-.81,49.71,20.39,99.47,40.66,149.23,60.93,1.49.61,1.68,1.08.98,2.54Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m437.32,642.15c.65.26,1.37.31,2.13.06-2.64-3.1-29.77-15.33-34.32-15.41,9.65,6.94,21.29,10.7,32.19,15.35Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m480.02,657.57c3.39,3.56,29.9,15.54,33.76,15.35-10.7-6.34-21.96-11.44-33.76-15.35Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m448.36,638.23c-.11.25-.22.5-.34.74,9.73,5.3,19.72,10.02,30.36,13.24-9.53-5.76-19.57-10.35-30.03-13.98Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m438.22,691.86c-.86-25.99-37.05-20.22-37.99,2.38-1.11,27.69,38.03,22.65,37.99-2.38Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m361.92,780.67c.45,23.92,34.38,19.89,37.76-.59,3.38-28.28-36.72-24.05-37.76.59Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m459.4,690.05c-21.37,1.55-26.87,36.88-2.66,37.5,23.11.32,29.1-38.87,2.66-37.5Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m403.03,790.79c-3.84,27.26,36.21,22.36,36.15-1.87-.75-23.1-33.43-17.52-36.15,1.87Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m499.49,704.82c-24.02-.91-29.21,41.73-.62,35.76,17.84-4.2,22.11-34.75.62-35.76Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m441.87,802.95c-4.11,27.08,35.06,21.03,34.68-2.22-.81-22.5-31.95-16.08-34.68,2.22Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m324.81,730.21c-6.54,6.54-13.65,24.94-9.25,33.57,7.45,6.74,16.83-22.62,16.01-27.99.23-4.61-1.78-10.38-6.76-5.58Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m336.88,629.07c-4.45,5.87-7.09,12.54-8.56,19.69-1.27,3.88-.91,14.2,5.12,8.41,6.5-6.99,9.9-16.67,10.65-26.09.29-6.54-3.77-6.65-7.21-2.02Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m267.46,502.24c11.34-11.03,39.14-33.22,28.37-50.22-7.87-12.63-8.71-27.01-10.44-41.95-12.84,8.35-23.57,18.47-31.47,31.45-2.91,5.22-4.79,10.8-2.35,16.69,5.89,14.23,12.2,28.29,15.9,44.03Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m350.98,403.65c-8.41,8.78-31.64,30.48-18.97,42.58,17.42-7.9,27.35-30.29,18.97-42.58Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m129.19,445c3.63,8.96,13.24,18,18.59,17.35-4.95-7.52-11.42-12.8-18.59-17.35Z"/>
        </g>
      </g>
    </svg>
  )
}