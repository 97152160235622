export const SmallFlame01 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="286.14 628.56 439.17 623.89 528.09 634.21 526.12 800.26 437.94 822.86 284.42 806.15 286.14 628.56"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m405.81,689.6c4.45-4.27,6.32-9.62,6.37-15.68,0-5.17-1-8.91-3.66-12.94-5.7-8.62-16.62-9.79-23.67-2.41-7.62,7.97-7.74,22.4-.16,30.43,5.98,6.34,14.88,6.61,21.13.6Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m311.88,777.47c5.94,6.67,15.23,6.11,20.29-1.21,5.48-7.93,4.36-20.64-2.43-27.47-6.67-6.7-16.02-5.48-20.72,2.73-1.89,3.29-2.8,6.86-2.65,10.07-.07,6.39,1.64,11.52,5.52,15.87Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m471.24,657.11c-2.65-3-6.52-3.05-9.17-.06-.96,1.08-1.82,2.34-2.42,3.65-3.34,7.23-3.57,14.78-1.95,22.45.73,3.47,1.98,6.76,4.61,9.3,2.61,2.52,5.65,2.58,8.21.02,1.09-1.09,2.05-2.41,2.77-3.77,2.4-4.57,3-9.55,3.12-15.06-.1-1.18-.17-2.81-.4-4.42-.63-4.4-1.71-8.66-4.76-12.11Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m470.78,750.37c-2.24-2.83-5.51-3-8.1-.48-1.61,1.57-3,3.53-3.95,5.57-3.13,6.77-3.56,13.93-2.3,21.2.59,3.45,1.71,6.7,4.3,9.24,1.41,1.38,3.03,1.9,5.02,1.42,3.01-.73,4.58-3.01,5.93-5.44,2.73-4.93,3.51-10.35,3.78-17.28-.29-1.75-.54-4.92-1.41-7.9-.66-2.25-1.83-4.49-3.28-6.33Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m512.08,658.86c-1.94-1.87-4.45-1.93-6.29.01-1.13,1.2-2.19,2.61-2.79,4.13-3.17,8.01-3.31,16.16-.46,24.27.62,1.77,1.78,3.46,3.04,4.87,1.71,1.92,4.24,1.99,5.97.1,1.42-1.54,2.61-3.42,3.43-5.35,1.59-3.76,2.01-7.79,2.15-11.88-.29-2.39-.43-4.8-.89-7.16-.65-3.31-1.63-6.54-4.16-8.99Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m511.97,744.66c-1.81-2.44-4.52-2.53-6.65-.36-1.22,1.24-2.25,2.78-3,4.36-2.06,4.33-2.72,8.98-2.66,13.74,0,1.1-.09,2.21.02,3.3.35,3.62.96,7.18,3.03,10.28,2.32,3.49,5.69,3.69,8.26.4,1.05-1.34,1.97-2.87,2.58-4.46,2.7-6.98,3.16-14.18,1.29-21.42-.54-2.07-1.59-4.13-2.86-5.85Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m540.87,606.55c-47.22-61.4-95.53-26.48-116.9-128.33-24.89,11.33-28.5,45.52-25.98,69.86-12.79.91-25.26,2.61-37.32,7.52-11.9,25.89-27.71,49.07-48.95,68.7-27.86-20.96-56.61-40.17-89.12-53.08,46.54,53.33-17.33,117.84,1.16,177.05,8.47,28.61,19.17,59.45,41.94,79.69,58.32,45.17,142.03,27.18,200.77-9.78,19.78-5.35,39.54-10.75,59.32-16.1,1.31-.35,1.57-.91,1.58-2.14.03-2.73.19-15.66.41-33.24,38.92-44.85,56.48-112.45,13.08-160.16Zm-105.56,19.42c-.58,64.52-1.16,128.82-1.74,193.3-3.23-.35-134.49-14.91-144.85-16.02-1.17-.13-1.14-.78-1.13-1.61.02-7.13.53-170.52.58-171.08,49.02-1.53,97.98-3.06,147.15-4.6Zm89.97,168.43q-.14,2.99-2.97,3.72c-26.05,6.79-79.47,20.68-80.4,20.91.6-64.32,1.19-128.46,1.79-192.84,27.77,3.21,55.37,6.39,83.16,9.6v40.15c-.62,47.01-1.57,118.01-1.59,118.46Z"/>
        </g>
      </g>
    </svg>
  )
}