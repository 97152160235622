export const LockIcon = ({ size }) => {
    return (
        <div className="icon">
            <svg 
                width={size ? size : 15}
                height={size ? size / 1.25 : 12}
                // width="15" 
                // height="20" 
                viewBox="0 0 15 20" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M1.875 19.6875C1.35938 19.6875 0.917969 19.5039 0.550781 19.1367C0.183594 18.7695 0 18.3281 0 17.8125V8.4375C0 7.92188 0.183594 7.48047 0.550781 7.11328C0.917969 6.74609 1.35938 6.5625 1.875 6.5625H2.8125V4.6875C2.8125 3.39062 3.26953 2.28516 4.18359 1.37109C5.09766 0.457031 6.20312 0 7.5 0C8.79688 0 9.90234 0.457031 10.8164 1.37109C11.7305 2.28516 12.1875 3.39062 12.1875 4.6875V6.5625H13.125C13.6406 6.5625 14.082 6.74609 14.4492 7.11328C14.8164 7.48047 15 7.92188 15 8.4375V17.8125C15 18.3281 14.8164 18.7695 14.4492 19.1367C14.082 19.5039 13.6406 19.6875 13.125 19.6875H1.875ZM1.875 17.8125H13.125V8.4375H1.875V17.8125ZM7.5 15C8.01562 15 8.45703 14.8164 8.82422 14.4492C9.19141 14.082 9.375 13.6406 9.375 13.125C9.375 12.6094 9.19141 12.168 8.82422 11.8008C8.45703 11.4336 8.01562 11.25 7.5 11.25C6.98438 11.25 6.54297 11.4336 6.17578 11.8008C5.80859 12.168 5.625 12.6094 5.625 13.125C5.625 13.6406 5.80859 14.082 6.17578 14.4492C6.54297 14.8164 6.98438 15 7.5 15ZM4.6875 6.5625H10.3125V4.6875C10.3125 3.90625 10.0391 3.24219 9.49219 2.69531C8.94531 2.14844 8.28125 1.875 7.5 1.875C6.71875 1.875 6.05469 2.14844 5.50781 2.69531C4.96094 3.24219 4.6875 3.90625 4.6875 4.6875V6.5625Z" fill="#C5C5C5"/>
            </svg>
        </div>
    );
};