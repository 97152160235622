export const SmallFlame42 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="284.06 744.6 305.88 802.17 460.81 850.83 476.34 798.48 526.48 632.94 352.14 583.3 284.06 744.6"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m433.99,723.6c-7.52,2.65-12.68,9.4-12.88,16.72-.3,10.9,8.72,19.7,20.27,19.92,4.92,0,8.88-1.23,12.5-3.88,8.14-5.95,9.95-17.01,4.12-25.19-5.34-7.49-15.32-10.63-24.01-7.57Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m395.66,708.88c-4.46.04-8.46,1.32-12.06,3.87-8.08,5.71-10.25,16.67-4.89,24.59,6.41,9.45,20.25,11.79,29.49,4.98,6.1-4.49,8.97-11.89,7.29-18.75-2.16-8.81-10.22-14.78-19.84-14.7Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m364.12,699.71c-7.21-5.66-18.07-5.82-25.45-.29-5.76,4.32-8.61,10.11-7.63,17.31,1.25,9.19,9.51,15.33,19.11,15.53,4.76.08,9.1-1.21,12.94-4.02,9.98-7.29,10.47-21.11,1.03-28.53Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m483.88,650.27c-5.78-7.43-16.7-10.02-25.24-5.97-10.92,5.18-13.24,18.37-4.68,26.91,4.13,4.12,9.24,5.96,14.95,6.01,3.77-.06,7.21-.98,10.38-2.89,8.57-5.13,10.69-16.21,4.58-24.06Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m436.51,660.47c7.19-5.08,9.32-14.37,4.77-21.64-3.87-6.19-9.84-8.76-16.91-9.15-2.78-.07-5.43.47-8.01,1.41-11.1,4.07-14.75,17.51-7.12,26.19,6.72,7.65,18.93,9.08,27.28,3.18Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m394.88,645.94c6.39-5.97,7.28-14.72,2.05-21.5-3.96-5.14-9.5-7.11-14.42-7.32-6.1-.06-10.37,1.32-14.08,4.23-7.56,5.92-8.97,16.55-1.92,23.84,7.3,7.55,20.68,7.93,28.37.75Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m350.6,777.52c-7.4-4.82-15.54-7.68-24.24-8.99-1.95-.29-3.98-.4-5.92.2-1.69.52-2.08,1.37-1.35,2.96.56,1.22,1.6,2.06,2.63,2.86,2.99,2.33,6.33,4.08,9.82,5.52,5.78,2.38,11.67,4.32,17.55,4.64,1.65-.07,2.86.08,4.04-.28,1.95-.58,2.44-1.96,1.19-3.57-1.03-1.33-2.31-2.42-3.72-3.34Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m396.32,805.41c-2.84-2.27-6.05-3.91-9.4-5.3-5.92-2.46-12.02-4.24-18.46-4.62-1.11-.04-2.21.11-3.25.45-1.5.49-1.81,1.42-1,2.76.69,1.14,1.69,1.99,2.78,2.72,6.19,4.21,13.1,6.69,20.36,8.29,2.91.64,5.86,1.12,8.86.65,2.46-.39,3-1.7,1.46-3.64-.38-.49-.86-.92-1.34-1.31Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m434.9,828.5c-7.65-4.61-15.95-7.26-24.83-8.11-1.36-.13-2.73.01-4.01.52-1.1.43-1.4,1.17-.73,2.22.6.95,1.41,1.68,2.3,2.32,2.22,1.6,4.64,2.84,7.15,3.89,6.16,2.58,12.49,4.47,19.2,4.87,1.07.08,2.1-.12,3.11-.39,1.39-.38,1.73-1.51.86-2.66-.83-1.1-1.89-1.96-3.06-2.67Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m489.85,571.09c-21.45-10.79-40.63-24-54.37-44.1-10.82-15.02-16.25-33.23-28.68-47.14-9.73,14.76-15.43,31.84-24.04,47.35-3.1,6.1-6.73,11.88-6.34,19.5-16.69,1.44-32.87,3.77-48.34,8.31,2.93,23.14-5.48,42.3-17.87,60.59-27.93-13-55.61-25.07-85.24-32.44,41.97,49.12-10.9,110.47,3.96,165.24,2.24,9.77,7.18,18.72,9.46,28.35,18.35,81.7,120.31,90.48,185.77,62.92,12.17,3.84,24.34,7.68,36.51,11.52,1.35.43,1.76.14,2.15-1.14,3.04-9.98,6.16-19.94,9.24-29.9.64-2.07,1.29-4.14,1.93-6.21,93.42-57.26,130.62-184.9,15.87-242.84Zm-184.35,231.42c-.02-.09-.05-.19-.07-.28.01,0,.02,0,.03.01,3.31,1,6.6,2.06,9.89,3.1,23.15,7.3,46.3,14.59,69.45,21.89-26.52-8.27-52.8-16.46-79.31-24.72Zm165.11,2.94c-.5,1.76-.99,3.53-1.49,5.29-3.52,12.22-7.06,24.44-10.53,36.68-.41,1.45-.99,1.6-2.34,1.18-21.96-6.96-43.94-13.88-65.91-20.81-26.82-8.45-53.63-16.91-80.46-25.33-1.23-.39-1.87-1.01-2.29-2.23-5.76-16.67-11.58-33.33-17.38-49.99-.15-.43-.56-.92-.21-1.32.42-.48.97-.06,1.45.08,15.45,4.79,30.9,9.58,46.35,14.37,41.85,12.98,83.71,25.96,125.56,38.95,2.1.65,4.2,1.32,6.29,1.97.56.18,1.16.32.97,1.15Zm-178.09-63.72c-1.43-.44-1.32-.96-.87-2.07,16.49-40.76,32.95-81.53,49.42-122.3,4.1-10.14,8.22-20.28,12.27-30.44.44-1.11.89-1.38,2.07-1.03,36.32,10.62,72.66,21.21,108.99,31.81,18.79,5.48,37.59,10.94,56.38,16.44.56.16,1.43.02,1.52.85-.58,1.95-1.12,3.75-1.66,5.55-10.11,33.19-20.22,66.38-30.33,99.57-5.61,18.42-11.2,36.85-16.8,55.28-.64,2.11-.62,2.1-2.73,1.45-59.41-18.38-118.83-36.77-178.25-55.11Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m171.4,511.88c-1.82,41.09-4.01,47.3,36.9,62.69,1.37-1.97.23-3.07-.65-4.28-15.97-24.11-10.61-35.71-36.25-58.41Z"/>
        </g>
      </g>
    </svg>
  )
}