export const BigFlame46 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="342.49 624.75 293.78 777.69 305.31 783.9 487.18 838.4 540.28 657.72 361.98 596.14 342.49 624.75"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m545.19,636.99c-4.61-25.33-13.77-48.83-31.49-68.36-.74,6.62,2.63,12.69-2.2,17.81-7.14,1.03-10.72-4.97-15.76-8.16-.19,1.24-.33,2.18-.47,3.18-3.12-.33-3.71-2.79-4.65-4.81-20.11-34.67-32.41-85.27-77.46-93.76-21.67-2.83-37.42-16.63-53.45-30.03,11.72,26.93,26.48,51.63,44.02,76.03-14.62-3.61-28.41-8.25-43.27-11.07-44.85-9.77-86.46-30.69-127.5-48.74,24.73,39.36,39.03,81.2,32.06,128.92-24.48-7.02-52.91-14.61-58.99-42.77-.18,56.29,4.86,113.65,28.7,164.81-5.64,102.51,120.76,141.76,201.52,104.11,16.88,5.03,33.75,10.06,50.63,15.09,2.54.76,2.52.76,3.26-1.8,6-20.71,11.99-41.41,17.99-62.11,36.79-34.49,52.63-89.27,37.07-138.35Zm-248.6,139.37c14.18-48.95,29.87-97.51,44.64-146.31l1.82-4.65s.08-.87.82-1.65c.66-.69,12.79-17.17,15.58-20.97-1.02,3.32-2.05,6.64-3.1,9.96-9.93,31.43-19.86,62.87-29.81,94.29-7.63,24.11-15.29,48.22-22.93,72.33h0s-7.04-2.99-7.04-2.99Zm189.81,54.65c-.62,2.12-.61,2.12-2.59,1.52-52.61-15.76-105.22-31.51-157.82-47.27-4.97-1.49-9.91-3.04-14.9-4.45-1.22-.35-1.39-.76-1.02-1.93,8.7-27.44,17.36-54.89,26.03-82.34,9.77-30.94,19.53-61.89,29.3-92.83.63-2,.64-2,2.59-1.33,55.5,19.09,111,38.17,166.5,57.28.59.2,1.63.12,1.4,1.06-1.1,3.81-2.14,7.38-3.18,10.94-15.44,53.11-30.88,106.23-46.31,159.34Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m163.03,385.41c-52.88,87.8,15.15,60.24,24.27,136.6,9.25-21.84,23.4-50.83,6.55-72.23-16.45-18.65-23.35-40.44-30.82-64.37Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m367.84,729.24c-11.22.19-19.58,8.92-19.73,20.05-.15,11.14,8.54,19.86,19.77,20.1,11.49-.08,20.26-9.04,20.15-20.59-.1-10.81-9.2-19.75-20.2-19.57Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m412.23,743.28c-10.63.22-19.37,9.5-19.06,20.51.32,11.07,8.8,19.07,19.53,19.33,11.22-.18,19.8-9.17,19.61-20.58-.17-10.76-9.27-19.49-20.09-19.26Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m396.36,679.27c10.86-.01,18.7-8.61,19.06-18.62.41-11.37-8.62-20.66-19.73-20.84-10.48-.22-19.24,7.71-19.44,19.14-.19,11.14,8.59,20.33,20.11,20.32Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m456.22,757.08c-10.39.06-18.85,9.11-18.82,20.14.23,10.56,8.02,19.25,19.25,19.28,10.61.03,18.88-8.5,19.04-19.71.15-11.08-8.59-19.77-19.46-19.72Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m440.21,693.71c10.43-.06,18.49-8.19,18.71-18.73.24-11.8-8.87-20.11-19.29-20.39-10.81,0-19.12,8.36-19.12,19.25,0,10.87,8.84,19.94,19.7,19.87Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m482.64,669.11c-10.54-.18-18.68,8.28-18.74,19.23-.06,10.54,8.74,19.43,19.35,19.54,9.91.11,18.28-8.54,18.32-18.91.08-10.74-8.15-19.68-18.93-19.86Z"/>
        </g>
      </g>
    </svg>
  )
}