export const NoFlame07 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="301.79 619.55 423.35 582.72 553.96 668.54 497.61 798.73 393.82 868.08 271.76 763.64 301.79 619.55"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m556.05,667.52c-19.85,43.81-36.91,89.46-58.21,132.28-34.12,23.18-68.35,46.19-102.48,69.35-1.27.87-2.09.82-3.26-.19-40.35-34.71-82.16-70.29-122.6-104.9,8.67-41.04,19.91-85.41,27.66-128.2,1.14-5.32,2.35-10.63,3.39-15.97.26-1.35.85-1.93,2.1-2.29,40.06-11.72,120.15-37.12,120.15-37.12,0,0,89.47,58.34,133.26,87.04Zm-157.01,196.1c33.04-22.47,97.82-65.6,97.82-65.6,17.68-40.97,36.96-83.69,54.54-124.72-34.17,17.82-68.21,35.85-102.32,53.78-2.82,2.31-3.04,6.69-4.6,9.86-15.18,42.2-30.99,84.26-45.43,126.69-.05.06-.11.11-.16.17.05-.06.1-.12.15-.18Zm-8.08-.47c14.63-45.75,48.01-137.47,48.01-137.47-44.86-33.19-90.4-66.91-135.27-100.1-7.18,31.14-13.43,62.41-20.35,93.6-3.06,14.06-6.11,28.12-9.18,42.18-.23,1.07-.1,1.75.82,2.54,38.7,33.09,77.11,66.33,115.97,99.24Zm158.07-197.05c-41.3-27.17-82.71-54.16-124.05-81.28-.79-.52-1.48-.95-2.58-.6-37.88,11.92-75.73,23.49-113.62,35.42,44.84,33.01,89.79,65.8,134.63,98.8,1.07.79,1.85.94,3.07.29,34.1-17.71,68.47-34.9,102.56-52.63Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m399.04,863.62c-.05.06-.1.12-.15.18.05-.06.11-.11.16-.17,0,0-.01-.01-.01-.01Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m387.69,717.41c12.14.96,19.88,14.06,19.71,25.39.56,9.22-8.59,16.73-17.51,11.05-12.61-5.71-21.1-34.57-2.2-36.45Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m321.69,762.26c-1.35,13.7-13.53,11.86-20.07,2.49-6.4-6.95-9.83-28.95,4.84-26.49,3.9,1.11,6.82,3.59,9.23,6.72,3.68,4.78,5.74,10.17,6,17.28Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m424.71,632.97c9.2.57,16.52,2.89,22.56,8.37,3.53,3,6.07,9.09.63,11.61-15.56,8.33-50.67-16.06-23.2-19.98Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m449.62,759.97c2.11-10.86,10.94-23.14,21.34-27.74,8.72-2.05,3.89,10.33,1.51,14.13-1.67,5.18-22.05,28.9-22.86,13.61Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m424.48,827.1c2.09-10.14,11.56-22.49,21.47-26.32,5.18-1.45,3.67,5.41,2.47,8.14-3.72,8.4-9.16,15.45-16.91,20.56-2.91,1.92-7.41,3.08-7.03-2.39Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m526.43,708.3c-2.94,9.99-9.87,21.67-20.06,25.96-5.21,1.23-2.89-6.3-2.04-8.93.5-5.27,21.62-32.83,22.1-17.03Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m476.7,793.4c2.56-8.45,8.54-17.15,15.9-22.5,2.14-1.73,7.01-3.48,6.65,1.02-2.05,10.05-8.81,18.42-17.03,24.38-2.74,1.79-5.8,2.21-5.52-2.89Z"/>
        </g>
      </g>
    </svg>
  )
}