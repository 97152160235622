export const NoFlame44 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="294.84 764.68 355.49 588.08 530.5 644.25 482.53 823.59 455.83 838.25 301.83 791.4 294.84 764.68"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m456.41,838.6c-.5-.15-.96-.28-1.41-.41-36.37-11.05-72.75-22.09-109.12-33.14-14.73-4.47-29.46-8.96-44.2-13.4-1.13-.34-1.71-.87-2.03-2.03-2.01-7.26-4.07-14.51-6.18-21.75-.37-1.28-.29-2.41.13-3.66,13.21-39.34,26.39-78.69,39.58-118.04,6.65-19.85,13.32-39.69,19.93-59.55.4-1.22.81-1.48,2.06-1.08,42.58,13.55,85.17,27.06,127.75,40.58,16.83,5.34,33.65,10.69,50.49,15.97,1.39.44,1.72.88,1.32,2.37-14.91,54.61-29.77,109.24-44.63,163.86-1.37,5.05-2.79,10.09-4.13,15.14-.27,1.01-.8,1.61-1.73,2.04-8.91,4.19-17.8,8.42-26.7,12.63-.37.18-.77.31-1.14.46Zm24.82-21.19c.78-.05.69-.78.82-1.27,9.73-35.47,19.45-70.95,29.17-106.42,5.66-20.64,11.29-41.28,17-61.91.41-1.5.2-2.06-1.34-2.48-3.18-.86-6.3-1.96-9.44-2.95-52.7-16.7-105.4-33.4-158.1-50.11q-2.06-.65-2.74,1.39c-18.51,55.56-37.01,111.13-55.56,166.68-.49,1.47-.36,1.97,1.23,2.45,25.5,7.73,50.98,15.52,76.46,23.31,33.69,10.3,67.38,20.61,101.07,30.91.45.14.91.26,1.41.4Zm-28.67,19.57c.45-.2.98-.41,1.49-.67,7.49-3.76,14.99-7.51,22.46-11.31.7-.36,1.99-.5,1.89-1.29-.08-.71-1.38-.71-2.16-.95-35.6-10.87-71.2-21.72-106.8-32.59-22.88-6.98-45.76-13.98-68.64-20.97-1.67-.51-1.86-.32-1.44,1.4,1.62,6.65,3.25,13.3,4.86,19.95.24,1.01.66,1.67,1.77,1.92,1.32.3,2.59.78,3.89,1.17,12.56,3.84,25.11,7.7,37.67,11.5,18.56,5.62,37.14,11.18,55.69,16.81,14.86,4.51,29.7,9.1,44.55,13.65,1.54.47,3.1.89,4.77,1.37Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m339.29,732.14c-.07-10.65,8.95-19.59,19.79-19.64,11.08-.04,20.42,8.8,20.47,19.38.05,11.18-8.73,20.12-19.96,20.03-11.6-.09-20.38-8.98-20.3-19.77Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m384.87,745.62c.06-10.47,8.99-19.18,19.54-19.07,11.51.12,20.42,8.98,20.39,20.27-.03,10.67-9.13,19.53-20.11,19.14-11.34-.4-19.94-9.12-19.82-20.34Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m469.8,760.66c-.32,11.53-9.01,19.48-19.57,19.25-11.45-.25-20.23-9.33-20.05-20.82.16-10.08,8.92-18.69,19.1-18.66,11.29.03,20.59,9.19,20.52,20.23Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m386.68,626.89c10.77.12,19.82,8.8,19.83,19.1.01,10.28-8.5,18.43-19.04,18.44-10.19,0-19.22-7.77-19.97-17.73-.57-7.61,3.83-15.62,12.11-18.54,1.32-.47,2.71-.78,4.1-1.04.97-.18,1.98-.16,2.97-.22Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m432.24,678.3c-10.1.23-19.09-7.14-20.31-17.01-1.73-13.99,11.66-23.38,23.77-19.71,8.33,2.52,13.43,7.88,14.61,16.56,1.09,7.99-3.51,15.56-11.05,18.7-2.25.94-4.59,1.42-7.02,1.46Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m476.25,692.12c-14.16.16-24.18-13.77-19.1-26.36,3.5-8.67,13.36-13.14,22.29-10.43,7.25,2.2,12.14,6.73,14.14,14.01,2.97,10.83-4.41,21.88-16.24,22.7-.36.03-.73.05-1.09.07Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m359.69,794.31c-4.71-.44-9.17-1.53-13.61-2.76-6.15-1.71-12.18-3.73-17.95-6.46-1.03-.49-1.99-1.15-2.94-1.79-.76-.51-.57-.88.25-1.1,1.32-.36,2.63-.03,3.88.2,9.71,1.77,19.12,4.56,28.11,8.66.95.43,1.85.98,2.74,1.52.51.31,1.33.65,1.11,1.33-.24.71-1.13.19-1.59.4Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m366.08,800.98c2.76-.59,5.16,0,7.54.51,8.45,1.83,16.73,4.22,24.59,7.89.85.4,1.74.75,2.41,1.44.21.22.54.44.42.78-.13.36-.53.22-.83.26-2.61.37-5.06-.5-7.53-1.05-8.39-1.87-16.55-4.47-24.4-8-.14-.06-.31-.08-.42-.18-.54-.48-1.06-.98-1.78-1.65Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m405.8,817.46c4.62.45,9.12,1.58,13.57,2.85,4.96,1.42,9.88,2.97,14.59,5.1.33.15.68.29.97.51.58.44,1.84.58,1.55,1.42-.23.68-1.43.36-2.16.25-9.81-1.49-19.29-4.15-28.29-8.38-.24-.11-.49-.21-.69-.37-.4-.31-1.18-.52-1.06-1.01.18-.74.99-.19,1.51-.39Z"/>
        </g>
      </g>
    </svg>
  )
}