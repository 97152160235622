export const BigFlame20 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="300.46 648.59 454.02 596.99 480.94 623.5 533.11 800.56 357.77 860.43 291.55 683.73 300.46 648.59"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m283.8,406.52c-.91-6.67-2.01-12.77-7.11-17.05-6.78-5.77-8.07-12.67-12.26-20.97-1.08,17.34,6.03,29,19.37,38.02Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m526.31,764.93c23.47-24.42,41.79-53.86,43.22-88.43-2.61-40.83-15.98-83.32-58.45-97.22-26.2-9.12-52.51-15.61-76.88-30.01.12,7.51,2.29,14.28,1.18,21.48-48.19-6.77-92.29-27.11-140.71-32.44,1.06,35.7-5.49,69.47-20.15,101.8-17.31-3.15-32.9-9.99-47.98-18.47,11.97,25.73,28.5,57.22,19.91,86.18-4.41,9.5-7.58,19.36-10.44,29.39-23.26,70.83,51.46,114.43,113.76,107.68,2.33,6.11,4.36,11.41,5.96,15.61.55,1.45,1.06,1.81,2.62,1.27,12.61-4.38,130.54-44.52,176.86-60.3,2.02-.69,2.03-.69,1.43-2.66-1.07-3.49-5.02-16.42-10.35-33.87Zm-230.93-83.03c.58-2.55,5.71-24.23,7.63-32.53.16-.7.44-1.19.86-1.56,29.13-9.87,58.18-19.72,87.24-29.56,24.37-8.2,47.29-15.93,59.11-19.92,1.43-.48,2.28-.36,3.36.82,3.04,3.32,17.37,17.73,21.32,21.77,1.21,1.24,1.21,1.24-.46,1.81-19.74,6.66-171.27,57.79-177.37,59.86-.4.13-.8.26-1.2.38-.95.01-.62-.6-.51-1.07Zm233.96,116.09c-4.75,1.64-116.26,39.62-167.26,56.98-.3.1-.62.16-.89.31-1.14.6-1.61.12-2.02-.99-6.5-17.18-47.72-125.6-61.86-162.61-.62-1.63-.13-1.98,1.26-2.45,38.61-12.99,157.3-53.02,178.04-60.02q2-.68,2.63,1.36c15.25,49.57,49.19,159.91,50.91,165.51.11.35.21.7.33,1.11-.04.67-.69.64-1.13.79Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m431.03,731.16c-9.17-7.64-22.59-6.07-29.75,3.49-4.58,6.13-5,14.23-1.05,20.63,3.76,6.1,9.42,8.79,16.39,9.12,6.16.24,12.67-2.96,16.83-8.28,5.83-7.47,4.71-19.02-2.42-24.97Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m409.57,636.18c1.06.24,2.15.4,3.27.29,4.6-.48,9.07-1.51,13.46-2.93,5.88-1.89,11.73-3.88,16.92-7.34.93-.62,2.38-1.27,2.09-2.45-.37-1.56-2.06-.98-3.25-1.05-.58,0-1.16-.07-1.72.01-9.77,1.41-19.01,4.44-27.72,9.08-1.34.71-2.59,1.57-3.56,2.78-.68.85-.36,1.41.53,1.61Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m367.01,642.9c3.13-.1,6.14-.81,9.11-1.62,7.08-1.94,14.01-4.28,20.29-8.19,1.03-.65,2.74-1.42,2.26-2.63-.45-1.14-2.26-.86-3.57-.73-3.82.38-7.52,1.28-11.17,2.4-6.2,1.92-12.32,4.07-17.82,7.64-.9.58-2.24,1.26-1.9,2.4.35,1.17,1.83.58,2.79.74Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m325.08,648.77c.31.03.63,0,1.36,0,.1-.01.63-.04,1.14-.12,8.54-1.38,16.61-4.21,24.33-8.06,1.49-.74,2.98-1.54,4.15-2.78.31-.33.83-.61.58-1.17-.23-.52-.79-.47-1.24-.47-1.2.01-2.43-.05-3.6.16-8.04,1.42-15.67,4.1-22.98,7.69-1.59.78-3.17,1.63-4.45,2.9-.34.34-.74.68-.59,1.2.18.62.78.6,1.3.65Z"/>
        </g>
      </g>
    </svg>
  )
}