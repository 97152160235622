import RadSoftLogo from "../../_assets/RadSoftLogo.png"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import { moneyFormatter } from "../../../utils/moneyFormatter"
import { phoneFormatter } from "../../../utils/phoneFormatter"
import { CashierAmountIcon } from "../assets/CashierAmountIcon"
import { CashierBankIcon } from "../assets/CashierBankIcon"
import { CashierDepositIcon } from "../assets/CashierDepositIcon"
import { CashierWithdrawIcon } from "../assets/CashierWithdrawIcon"
import { CashierLocationIcon } from "../assets/CashierLocationIcon"
import { BarDiceNav } from "../../../games/BarDice100/controller/components/BarDiceNav"
import { BarDiceLogo } from "../../../games/BarDice100/assets/BarDiceLogo"

export const CashierRequest = (props) => {
  const navigate = useNavigate();
  const location = useLocation()
  const transactionDropdown = useRef(null)
  const locationDropdown = useRef(null)

  useEffect(()=>{
    if (props.locations.length && !props.locations.filter(l=>l.cashEnabled).length) {
      props.setTransactionType("ACH")
    }
  }, [props.locations])

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (!isNaN(Number(value)) && value !== "") {
      props.setAmount(Number(value)); 
    } else {
      props.setAmount(0);
    }
  };

  const handleTransactionTypeSelect = (e) => {
    props.setTransactionType(e.target.value)
    transactionDropdown.current.blur();
  };

  const handleLocationSelect = (e) => {
    props.setSelectedLocation(JSON.parse(e.target.value));
    locationDropdown.current.blur();
  };

  // prevent ios from adding space under input
  const [iosHack, setIosHack] = useState("")
  useEffect(()=>{
    setIosHack(document?.getElementsByClassName('iosHack')[0]?.clientHeight);
  }, [props.requestType])

  return (
    <div id="cashier">
      <BarDiceNav
          balance={props.balance}
          user={props.user}
          selectedLocation={props.selectedLocation}
          bet={0}
          jogo={props.jogo}
          jackpot={props.jackpotValue}
          modalOpen={props.modalOpen}
          modalData={props.modalData}
          setModalOpen={props.setModalOpen}
          setModalData={props.setModalData}
        />
      <div className="cashierHome">
        <div>
          {/* <nav className="cashierRow">
            <div className="back" onClick={(e)=>{props.setRequestType(undefined)}}>
              {props.i18nBack}
            </div>
          </nav> */}
        {!props.requestType ? (
          <div className="requestType">
            <div 
              className="fieldRow" 
              onClick={(e)=>{props.setRequestType("Deposit")}}
            >
              <CashierDepositIcon w={25} h={25} v={"8 5 24 24"} />
              <div className="text">{props.i18nMakeADeposit}</div>
            </div>
            <div 
              className="fieldRow" 
              onClick={(e)=>{props.setRequestType("Withdraw")}}
            >
              <CashierWithdrawIcon w={25} h={25} v={"8 5 24 24"} />
              <div className="text">{props.i18nMakeAWithdrawal}</div>
            </div>
          </div>
        ) : (
          <div className="requestDetails">
            <div className="fieldRow">
              {props.requestType==="Deposit" ? (
                <CashierDepositIcon w={25} h={25} v={"8 5 24 24"} />
              ) : (
                <CashierWithdrawIcon w={25} h={25} v={"8 5 24 24"} />
              )}
              <div className="text">
                {props.requestType==="Deposit" ? props.i18nDepositRequest : props.i18nWithdrawRequest}
              </div>
            </div>
            <div className="fieldRow">
              <CashierAmountIcon w={55} h={55} v={"8 5 55 55"} />
              <div className="text iosHack" style={{height: `${iosHack}px`, maxHeight: `${iosHack}px`}}>
                <div>{props.requestType==="Deposit" ? props.i18nAmountToDeposit : props.i18nAmountToWithdraw}</div>
                <input id="amount"
                  placeholder={`Min ${props.requestType} $5`}
                  value={props.amount === 0 ? "" : props.amount}
                  onChange={handleInputChange}
                  inputMode="numeric"
                />
              </div>
            </div>
            <div className="fieldRow">
              <CashierBankIcon w={55} h={55} v={"8 5 55 55"} />
              <div className="column text">
                <div>{props.requestType == "Deposit" ? props.i18nDepositType : props.i18nWithdrawalType}</div>
                <select id="type"
                  name="transactionType"
                  value={props.transactionType}
                  onChange={handleTransactionTypeSelect}
                  ref={transactionDropdown}
                  required={true}
                  placeholder={props.i18nSelectAnOption}
                  disabled={(props.locations.filter(eachLoc => eachLoc.cashEnabled).length) && (props.locations.length === 1) ? true : false }
                  > 
                  {/* Add an empty option if there's multiple (2 or more) options to choose from */}
                  {
                    props.locations.length > 1 ?
                    <option selected={true} value={""} hidden={true}>{props.i18nSelectAnOption}</option>
                    :
                    <></>
                  }

                  {/* Add Option Cash if there's at least one location that takes cash*/}
                  { 
                    props.locations.filter(eachLoc => eachLoc.cashEnabled).length > 0 ? 
                    <option value="Cash">{props.i18nCash}</option>
                    : 
                    <></>
                  }
                  {/* {props.requestType !== "Withdraw" ? (
                    <option value={"ACH"}>{"Bank Transfer"}</option>
                  ) : (<></>)} */}
                  {/* {
                  props.locations.filter(l=>l.cashEnabled).length ? 
                  <option value="Cash">{props.i18nCash}</option>
                  : 
                  <></>
                  } */}
                </select>
              </div>
            </div>
            {props.transactionType==="Cash" ? (
              <div className="fieldRow">
                <CashierLocationIcon w={55} h={55} v={"8 5 55 55"} />
                <div className="column text">
                  <div>{props.i18nLocation}</div>
                  <select id="location"
                    name="location"
                    value={JSON.stringify(props.selectedLocation)}
                    onChange={handleLocationSelect}
                    ref={locationDropdown}
                    required={true}
                    placeholder={props.i18nSelectAnOption}
                  >
                    <option value={null} hidden={true}>
                      {props.i18nSelectAnOption}
                    </option>
                    {process.env?.REACT_APP_VENUE_PATH ? (
                      <option value={JSON.stringify(props.selectedLocation)}>
                        {props.selectedLocation?.name ?? ""}
                      </option>
                    ) : props.jogo ? (
                      <option value={JSON.stringify({id: 41, name: "Pix"})}>
                        Pix
                      </option>
                    ) : props.locations.filter(l=>l.cashEnabled).map((l,i)=>(
                      <option key={`venue${i}`} value={JSON.stringify(l)}>
                        {l.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (<></>)}
            <div className="cashierCenter">
              {props.i18nVerifyMsg}
            </div>
          </div>
        )}</div>
        {props.requestType==="Withdraw" && props.transactionType==="ACH" ? (
          <div className="cashierCenter">
            Your withdrawal will be credited to your bank account within 2-3 business days.
          </div>
        ) : (<></>)}
        <div 
          onClick={props.handleClick}
          className={`cashierButton ${props.requestType && !props.isValid ? "disabled" : ""} ${props.requestType ? "submit" : ""}`
          }
        >
          {!props.requestType ? props.i18nReturnHome : props.i18nContinue}
        </div>
      </div>
    </div>
  )
}