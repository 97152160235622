export const NoFlame38 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="273.95 697.33 360.44 584.09 522.31 619.66 520.52 741.37 461.76 866.02 305.55 815.32 273.95 697.33"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m521.05,740.32c-.02.82-.26,1.53-.59,2.25-12.92,27.37-51.58,109.29-57.93,122.91-.6,1.29-1.13,1.62-2.55,1.15-51.07-16.71-102.16-33.39-153.25-50.03-1.33-.43-2.01-1.06-2.39-2.45-9.18-33.19-30.89-111.07-32.54-116.84-.26-.9.04-1.61.59-2.31,10.11-12.96,76.63-98.88,86.48-111.65.76-.99,1.43-1.32,2.72-1.03,22.18,4.93,158.51,35.16,161.28,35.65,1.04.18.99.58.97,1.26-.15,6.6-2.52,108.59-2.8,121.1Zm-158.19-154.34c-.88-.19-1.19.5-1.6,1.03-3.25,4.24-57.1,74.33-80.77,105.14q-1.14,1.49.48,1.94c20.08,5.54,132.49,36.57,168.61,46.56,1,.28,1.55.24,2.12-.8,11.89-21.85,54.86-100.52,64.43-118.03.97-1.77.98-1.77-.89-2.18-16.09-3.56-148.79-32.88-152.37-33.66Zm93.85,274.34s-.04,0-.06,0c-.44-6.63-6.77-97.3-7.48-108.69-.11-1.7-.6-2.38-2.25-2.84-30.46-8.46-142.14-39.74-167.54-46.85-.49-.14-1.06-.56-1.48-.18-.46.41.02.98.15,1.45,5.91,21.73,25.81,94.77,29.79,109.57.32,1.2.88,1.79,2.07,2.18,15.2,4.89,119.39,38.75,141.35,45.89,1.44.47,2.9.92,4.33,1.4.81.28,1.21.08,1.13-.83-.03-.36,0-.73,0-1.1Zm65.16-234.16c-.63.22-.7.8-.93,1.23-4.92,9.12-46.4,86.46-62.3,115.96-1.21,2.25-1.73,4.37-1.54,6.97.89,12.37,6.23,105.39,6.45,108.7.67-.25.68-.8.86-1.19,11.28-23.8,47.39-100.08,54.17-114.43.44-.93.94-1.84.95-2.96.03-3.14,2.16-105.37,2.26-110.4.03-1.28.34-2.56.08-3.89Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m423.96,711.22c-4.81-.21-9.59-1.24-13.73-4.39-5.81-4.43-6.45-11.62-1.42-16.95,5.66-5.99,12.85-7.68,20.75-7.13,3.99.28,7.69,1.49,10.96,3.85,2.53,1.83,4.43,4.12,5.09,7.28.98,4.76-1.15,8.4-4.46,11.4-4.77,4.32-10.64,5.83-17.2,5.94Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m383.61,671.81c5.88.06,10.64,1.05,14.62,4.37,5.17,4.31,5.48,11.2.71,16.01-5.73,5.78-12.84,7.77-20.78,7.15-3.53-.28-6.9-1.17-9.92-3.11-6.63-4.27-7.38-11.95-1.76-17.48,5-4.92,11.18-6.7,17.14-6.93Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m343.7,661.49c4.34-.11,8.42.76,12.03,3.3,5.84,4.12,6.03,10.73,1.41,15.75-4.82,5.24-11.07,7.36-18,7.63-5.04.2-9.8-.8-13.88-4-4.3-3.37-5.04-8.97-1.86-13.43,4.32-6.06,10.57-8.43,17.63-9.24.88-.1,1.78-.01,2.67-.01Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m460.67,649.88c-4.65.02-9.07-.89-13-3.47-6.77-4.45-6.96-11.94-.39-16.71,5.92-4.3,12.6-5.38,19.71-4.16,3.55.61,6.86,1.8,9.66,4.22,5.35,4.63,4.4,10.86-.5,15.01-4.04,3.42-9.37,5.09-15.48,5.12Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m418.3,640.05c-3.44-.09-7.44-.79-11.03-2.97-6.74-4.1-7.18-11.31-.92-16.12,6.1-4.69,13.07-5.84,20.52-4.56,2.65.45,5.17,1.29,7.43,2.83,6.02,4.1,6.3,10.92.62,15.52-4.25,3.44-9.85,5.3-16.62,5.31Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m381.77,607.07c4.78-.01,8.17.58,11.32,2.13,7.28,3.59,8.09,10.94,1.74,16-5.65,4.5-12.2,5.7-19.23,5.05-2.59-.24-5.03-.99-7.31-2.21-6.58-3.52-7.4-10.15-1.9-15.18,4.06-3.72,10.12-5.82,15.38-5.79Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m315.91,729.78c6.57.29,12.23,2.9,17.3,6.94,3.31,2.64,6.15,5.71,7.76,9.69,2.82,6.97-.84,11.62-7.9,11.01-10.83-.94-19-6.5-24.35-16.04-.72-1.29-1.03-2.76-1.15-4.21-.34-4.14,2.27-6.95,6.77-7.39.52-.05,1.05,0,1.57,0Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m379.09,798.42c-6.45-.45-12.33-3.19-17.56-7.45-2.74-2.24-5.08-4.81-6.51-8.11-2.64-6.09.22-10.56,6.94-10.62,5.85-.05,11.04,2.11,15.83,5.3,3.74,2.49,7,5.49,9.05,9.58,3.19,6.38.26,11.17-6.9,11.31-.16,0-.31,0-.85,0Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m423.4,837.58c-6.44-.36-12.42-3-17.76-7.24-2.53-2.01-4.67-4.35-6.09-7.29-2.61-5.38-.05-9.69,5.95-10.06,5.6-.34,10.64,1.41,15.39,4.15,4.37,2.52,8.17,5.67,10.51,10.28.86,1.68,1.2,3.48.85,5.33-.47,2.53-2.83,4.3-6.04,4.67-.78.09-1.57.09-2.81.15Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m468.39,757.23c.17-5.18,1.21-10.25,2.71-15.22,1.49-4.92,3.25-9.74,6.36-13.92.85-1.14,1.83-2.49,3.41-2,1.56.48,1.67,2.17,1.8,3.57.61,6.8-.99,13.3-2.95,19.72-1.18,3.86-2.68,7.6-4.83,11.05-.7,1.12-1.48,2.16-2.5,3.01-1.45,1.21-2.73.87-3.41-.9-.66-1.71-.41-3.52-.6-5.31Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m513.61,672.29c-.54,9.08-2.62,17.75-6.85,25.87-.58,1.12-1.3,2.15-2.26,2.99-1.25,1.09-2.35.83-2.91-.72-.7-1.96-.76-4.01-.58-6.05.77-8.72,2.73-17.12,6.91-24.9.6-1.11,1.31-2.15,2.27-2.98,1.19-1.02,2.22-.78,2.83.67.7,1.65.5,3.39.58,5.13Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m485.87,756.15c.35-7.65,2.18-14.97,5.04-22.05.81-1.99,1.75-3.92,2.97-5.69.24-.34.46-.71.75-1.01.71-.75,1.4-1.77,2.6-1.39,1.15.36,1.31,1.53,1.48,2.57.65,4.06-.14,8.03-.96,11.96-1.29,6.22-3.11,12.27-6.42,17.76-.43.71-.93,1.39-1.47,2.03-.59.68-1.3,1.32-2.29,1.06-.95-.25-1.14-1.17-1.33-1.97-.25-1.07-.46-2.15-.35-3.27Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m470.93,815.09c.86-9.42,3.21-18.4,8.02-26.65.53-.91,1.17-1.73,1.97-2.43,1.34-1.18,2.53-.83,3.02.92.55,1.95.45,3.96.23,5.93-.9,7.81-3.01,15.28-6.53,22.32-.87,1.74-1.82,3.43-3.3,4.74-1.32,1.18-2.4.88-2.98-.76-.46-1.32-.27-2.7-.43-4.07Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m501.11,753.28c.73-8.28,2.8-16.63,7.01-24.39.5-.92,1.1-1.78,1.92-2.46,1.08-.9,1.93-.7,2.41.61.71,1.93.55,3.96.33,5.9-.88,7.79-2.81,15.3-6.41,22.31-.65,1.26-1.4,2.46-2.49,3.39-1.02.87-1.79.67-2.29-.56-.56-1.41-.31-2.89-.47-4.81Z"/>
        </g>
      </g>
    </svg>
  )
}