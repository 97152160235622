export const BigFlame54 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="313.91 824.29 446.44 855.67 559.65 739.25 483.77 632.16 366.05 571.84 268.1 699.99 313.91 824.29"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m318.65,505.19c1.56-40.5,46.04-33.53-28.55-63.16,9.35,12.17,14.4,25.77,16.91,40.67,1.34,7.99,5.77,15,11.63,22.49Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m78.96,348.79c8.99-13.3,1.25-25.21-.4-37.35-23.24,8.45-.51,22.01.4,37.35Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m559.67,738.16c-13.3-18.94-26.54-37.92-39.77-56.9-18.5-64.62-81.79-102.84-146.65-107.13-1.89-1-3.79-2-5.68-3.02-1.32-.69-1.97-.56-2.89.65-.41.54-.83,1.08-1.24,1.62-16.79-2.31-33.4-5.29-47.6-17.5.11,9.24,3.11,16.73,5.66,24.43-12.85-1.57-25.75-5.05-38.96-5.21-52.55-6.25-39.95-77.54-28.5-113.82,3.16-9.67,5.87-19.48,5.89-29.76.03-13.92-2.37-26.61-12.87-37.43-18.72-19.28-38.19-38.14-49.93-64.68-5.46,33.78-27.45,56.56-40.09,86.26-17.91,36.87,7.18,76.02,42.68,89.64,18.3,7.94,31.5,21.25,41.38,38.26,1.14,1.97,1.78,3.84,1.51,6.11-1.69,14.32-.71,28.63.86,42.85,8.18,45.69,4.18,95.31,24.2,137.88,13.27,21.67,14.32,51.4,35.21,67.64,3.48,9.1,6.83,17.96,9.86,26.34.39,1.08,1.01,1.52,2.09,1.77,44.04,10.27,88.06,20.62,132.09,30.91,38.08-37.35,74.84-77.74,112.54-116.03.99-1.03,1.06-1.68.22-2.87Zm-187.68-162.57s0,0,0,0c0,0,0,0,0,0,0,0,0,0,0,0Zm.1.02s.01.02.02.02c0,0-.01-.02-.02-.02Zm-101.26,125.44c-.38-1.01-.25-1.69.41-2.55,30.58-39.75,61.08-79.56,91.63-119.32,1.77-2.3,1.45-2.03,2.71.15,20.38,35.21,40.72,70.44,61.12,105.63.61,1.04.51,1.71-.26,2.61-37.16,43.29-74.09,86.8-111.25,130.1-7.94-17.99-36.75-96.47-44.37-116.62Zm177.49,151.06c-.81.85-1.52,1.12-2.71.84-40.91-9.83-81.85-19.57-122.78-29.32-1.81-.43-1.82-.44-.6-1.86,11.44-13.3,22.89-26.6,34.33-39.89,25.58-29.74,51.21-59.44,76.77-89.19.62-.72,1.12-.67,1.89-.35,39.47,16.69,79.44,32.74,118.62,49.82-35.08,36.73-70.48,73.17-105.52,109.94Zm-8.24-166.5c-3.2-1.36-5.34-3.27-7.1-6.28-19.94-34.65-41.27-68.88-60.78-103.59,37.1,18.6,73.97,37.96,111,56.81,24.99,32.91,47.59,68.21,71.61,102.04-38.33-16.32-76.39-32.73-114.72-48.99Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m324.21,740.54c-5.75,5.73-8.19,12.83-8.63,19.97.43,23.39,19.93,13.54,23.47-2.49,4.01-10.68-1.94-28.75-14.84-17.49Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m352.48,617.94c-7.77,5.52-11.12,13.43-11.81,22.66,1.77,24.39,26.43,3,24.45-15.39.01-6.58-6.07-12.04-12.63-7.27Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m430.43,753.26c3.88-.11,7.15-.79,10.25-2.13,11.41-3.15,25-23.39,6.94-27.46-18.91-4.21-43.72,26.19-17.2,29.59Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m462.15,764.25c9.17.02,19.5-6.15,23.71-13.96,5.01-8.49-1.86-15.49-10.19-14.49-9.55.82-18.99,6.6-22.97,15.51-3.5,7.17,2.66,13.26,9.45,12.95Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m370.4,794.78c-10.21,7.17-10.85,20.61,4.09,20.75,6.18-.18,11.8-2.2,16.85-5.86,3.32-2.4,6.06-5.3,7.52-9.22,4.22-16.71-20.89-13.06-28.46-5.68Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m492.17,774.43c6.04-.17,11.29-2.49,15.97-6.15,6.34-4.08,13.05-16.57,3.06-20.22-11.36-3.17-26.14,5.77-28.84,17.18-1.3,5.84,4.55,9.67,9.82,9.19Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m420.76,798.1c-9.66.52-18.83,5.26-23.32,13.78-3.53,6.8,1.93,12.03,8.35,12.07,10.31.1,21.74-5.7,25.23-15.83,2.1-6.5-4.81-10.41-10.26-10.02Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m434.47,813.66c-8.39,6.07-10.44,18.22,2.74,18.23,7.18-.39,13.36-3.1,18.59-8.03,2.44-2.3,4.43-4.88,5.13-8.27.87-14.03-20.33-7.86-26.45-1.93Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m438.04,654.92c-4.65-.38-3.58,4.06-2,6.55,5.89,9.26,14.76,17.26,25.3,20.58,7.12,1.58,3.57-6.37,1.08-9.26-6.2-7.69-14.59-15.71-24.39-17.87Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m405.54,602.29c-6.14-1.28-2.64,5.63-.5,7.96,6.01,7.14,14.02,14.38,23.15,16.69,5.27.31,2.45-4.93.72-7.17-6.07-7.62-13.89-14.65-23.37-17.48Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m520.94,708.38c10.34-.25-8.86-18.64-11.96-20.28-2.39-1.93-14.19-9.91-13.16-2.32,3.73,9.31,15.55,19.93,25.12,22.6Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m463.88,631.62c-3.72-.33-2.71,2.57-1.61,4.51,5.24,8.12,12.31,14.15,21.03,18.27,5.02,2.23,5.81-1.2,3.13-4.87-5.51-7.66-13.47-15.27-22.56-17.91Z"/>
        </g>
      </g>
    </svg>
  )
}