export const SmallFlame07 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="301.37 619.62 422.93 582.79 553.54 668.61 497.19 798.8 393.4 868.15 271.34 763.71 301.37 619.62"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m556.05,667.52c-4.35-2.85-9.16-6-14.28-9.35-2.71-10.07-6.31-18.59-10.84-26.75-15.12-28.29-29.1-56.88-61.32-69.03-16.23-6.89-30.69-16.7-43.1-29.24-19.41-19.7-44.86-30.44-71.69-35.58,4.94,14.89,7.71,35.42-7.28,42.8-9.85,5.54-20.65,7.61-31.79,5.24-24.5-5.31-47.52-15.69-71-24.16-25.97,38.95-22.19,85.97-.32,125.53,5.72,10.51,8.59,21.92,10.68,33.58-30.06,78.68,24.86,163.99,108.07,163.58,9.69,8.3,19.35,16.58,28.91,24.81,1.17,1.01,1.99,1.05,3.26.19,34.13-23.16,68.36-46.17,102.48-69.35,2.56-5.15,5.06-10.34,7.51-15.56-.03.08-.07.15-.1.23.49.23-.49-.23,0,0,25.19-20.73,40.86-58.81,39.71-91.72,3.63-8.46,7.32-16.88,11.1-25.24Zm-133.64-83.3c1.1-.35,1.78.08,2.58.6,41.34,27.12,82.75,54.11,124.05,81.28-34.09,17.73-68.46,34.92-102.56,52.63-1.21.64-2,.49-3.07-.29-44.84-33-89.79-65.79-134.63-98.8,37.89-11.93,75.75-23.5,113.62-35.42Zm-31.45,278.93c-38.86-32.92-77.27-66.15-115.97-99.24-.92-.78-1.06-1.47-.82-2.54,3.08-14.06,6.13-28.12,9.18-42.18,6.92-31.19,13.17-62.46,20.35-93.6,44.87,33.19,90.41,66.91,135.27,100.1,0,0-33.38,91.72-48.01,137.47Zm105.9-65.13s-64.76,43.11-97.8,65.59c14.44-42.42,30.25-84.47,45.42-126.66,1.56-3.18,1.78-7.55,4.6-9.86,34.11-17.93,68.15-35.96,102.32-53.78-17.58,41.03-36.85,83.75-54.54,124.72Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m407.41,742.81c.17-11.34-7.58-24.43-19.71-25.39-18.89,1.88-10.4,30.73,2.2,36.45,8.92,5.67,18.07-1.83,17.51-11.05Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m306.46,738.25c-14.68-2.46-11.25,19.54-4.84,26.49,6.54,9.37,18.72,11.21,20.07-2.49-.26-7.1-2.32-12.5-6-17.28-2.41-3.13-5.33-5.62-9.23-6.72Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m447.28,641.34c-6.04-5.48-13.36-7.8-22.56-8.37-27.47,3.91,7.64,28.3,23.2,19.98,5.44-2.53,2.9-8.62-.63-11.61Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m470.97,732.22c-10.4,4.6-19.23,16.88-21.34,27.74.81,15.29,21.19-8.43,22.86-13.61,2.38-3.8,7.21-16.18-1.51-14.13Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m445.96,800.79c-9.92,3.82-19.38,16.17-21.47,26.32-.38,5.47,4.12,4.32,7.03,2.39,7.75-5.11,13.19-12.16,16.91-20.56,1.21-2.73,2.71-9.59-2.47-8.14Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m504.33,725.33c-.85,2.63-3.17,10.16,2.04,8.93,10.19-4.29,17.12-15.97,20.06-25.96-.47-15.8-21.6,11.77-22.1,17.03Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m492.6,770.9c-7.36,5.35-13.34,14.05-15.9,22.5-.28,5.1,2.78,4.69,5.52,2.89,8.22-5.95,14.98-14.32,17.03-24.38.36-4.5-4.51-2.75-6.65-1.02Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m327.22,472.16c-.22,5.89,2.36,9.84,7.01,12.83,8.96,5.77,18.99,8.56,29.33,10.46-21.25-21.33-2.23-30.71-40.48-48.48,2.49,9.07,4.45,17.01,4.14,25.19Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m159.96,469.98c-.29,14.29,10.31,22.46,17.16,32.6.24-11-2.77-33-17.16-32.6Z"/>
        </g>
      </g>
    </svg>
  )
}