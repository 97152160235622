export const BigFlame18 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="291.02 661.57 437.91 615.01 487.03 602.01 540.09 785.35 361.67 850.36 341.25 813.38 291.02 661.57"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m492.05,583.46c-33.52-5.34-65.5,1.66-96.83,13.05-1.87,1.59,1.34,3.21-.68,5.33-42.62,3.24-94.38,20.12-129.26-14.03-3.93,38.44-6.86,74.15-13.69,111.87-12.25-1.72-23.35-4.46-30.79-15.91-4.53,24.84,13.98,44.6,26.88,63.59,4.71,6.65,7.68,13.4,7.82,22,.41,51.6,54.85,72.39,99.49,70.24l6.3,11.82,181.17-65.07-10.13-34c41.24-55.95,46.31-158.56-40.28-168.87Zm-48.29,29.78l28.72-6.83-173.98,55.27-7.46-.29,152.71-48.15Zm-102.26,199.24l-47.69-146.71,6.01.86,57.66,176.03-15.97-30.18Zm23.6,31.5l-59.39-178.55,178.06-56,51.83,173.4-170.5,61.15Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m227.1,286.23c-15.63-4.59-27.51-13.92-38.08-26.42-3.6,51.65,8.06,121.22,23.17,138.07,4.2-22.25,13.14-42.27,25.11-61.09,9.11-15.37,10.38-44.34-10.19-50.55Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m306.49,443.1c-5.67-38.41-30.16-20.36-40.56-51.99-4.28,45.42,23.23,34.14,40.56,51.99Z"/>
          <circle fill={props?.color ?? "#e8c305"} cx="424.41" cy="726.02" r="19.57" transform="translate(-416.3 705.35) rotate(-58.28)"/>
          <path fill={props?.color ?? "#e8c305"} d="m347.07,814.95c.33-.12-2.44-8.81-6.2-19.41s-7.07-19.1-7.41-18.98c-.33.12,2.44,8.81,6.2,19.41,3.76,10.6,7.07,19.1,7.41,18.98Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m305.93,700.78c3.59,10.66,6.67,19.24,6.87,19.17.2-.07-2.55-8.76-6.15-19.42-3.59-10.66-6.67-19.24-6.87-19.17-.2.07,2.55,8.76,6.15,19.42Z"/>
        </g>
      </g>
    </svg>
  )
}