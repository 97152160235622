export const BigFlame51 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="274.57 733.04 317.62 815.42 471.86 858.69 557.66 704.44 479.02 644.41 345.86 585.11 274.57 733.04"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m93.25,389.91c-1.59-21.23-12.17-39.38-19.04-59.54-19.57,25.28,4,41.35,19.04,59.54Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m557.47,703.87c-10.99-8.46-22.04-16.84-33.07-25.24,20.71-126.07-67.95-65.64-89.97-166.58-8.82,12-14.9,24.36-18.7,37.59-19.79-6.49-37.21-18.54-55.89-27.72-25.09-13.1-26.05-45.31-13.3-67.33,18.86-39.45-64.2-56.33-89.32-67.35,33.88,36.48,70.61,48.68,60.46,107.59-18.04-11.89-34.21-25.53-49.1-41.1-38.58-44.56-88.16-23.93-93.78-98.56-20.82,48.19-24.68,118.72,34.13,138.74,19.5,6.84,43.24,29.27,50.02,48.74,5.82,33.12,4.21,66-3.51,98.59-4.55,16.43-4.27,33.33-4.98,49.9-3.09-.52-4.88-3.99-8.25-3.8,7.93,17.74,14.97,36.61,19.16,55.93,6.83,25.94,23.6,48,47.24,59.43,2.63,4.67,5.38,9.22,8.32,13.57,51.29,14.36,102.55,28.8,153.81,43.26,1.3.37,1.91.17,2.57-1.05,18.93-34.3,38.41-68.29,57.18-102.68,9.12-16.33,18.51-32.52,27.56-48.89.77-1.36.73-2.05-.57-3.04Zm-6.55-2.89c-45.37-20.2-91.21-39.14-136.87-58.67-4.67-1.95-8.48-4.77-12.12-8.13-16-15.25-33.38-29.67-48.81-45.2,9.87,4.16,19.73,8.44,29.58,12.77l44.56,19.9c16.8,7.57,33.59,15.14,50.4,22.52,3.24,1.46,5.75,4,8.52,6.12,21.34,16.26,42.39,32.86,63.74,49.09.37.29.88.59.99,1.61Zm-234.53,107.16c-13.26-24.98-26.22-50.1-39.4-75.12-.49-.93-.49-1.7-.03-2.65,22.4-46.22,44.84-92.41,67.12-138.69.6-1.24,1.05-1.07,1.9-.26,5.75,5.5,11.54,10.96,17.32,16.43,11.86,11.24,23.71,22.49,35.6,33.69,1.1,1.04,1.24,1.81.54,3.18-27.88,54.34-54.74,109.4-83.06,163.41Zm235.44-98.85c-27.31,48.21-53.67,97.45-81.37,145.19-48.66-13.75-97.25-27.71-145.9-41.49-1.48-.42-1.29-.95-.74-2.03,27.63-54.17,55.27-108.33,82.72-162.59.61-1.22,1.1-1.38,2.35-.84,10.35,4.43,20.74,8.77,31.11,13.15,37.03,15.64,74.08,31.25,111.14,46.81.99.38,1.26.76.67,1.81Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m425.6,722.27c8.38-1.96,14.1-7.07,16.9-15.23,3.7-10.79-2.66-21.65-15.12-21.74-.99.1-1.98.15-2.96.33-6.18,1.12-11.08,4.38-14.63,9.46-2.65,3.79-4.33,8.06-3.98,12.8.77,10.35,9.58,16.77,19.78,14.39Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m386.86,767.79c-10.88-.62-21.23,7.53-23.19,18.26-1.3,7.09,2.78,13.72,9.62,15.93,6.08,1.97,11.72.84,16.97-2.38,6.38-3.92,10.3-9.51,10.69-15.68.12-9.58-5.75-15.66-14.1-16.14Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m463.1,700.82c-9.79,1.29-18.48,11.18-18.5,21.04-.14,9.66,7.67,16.36,17.75,14.51,8.09-1.48,13.63-6.42,16.81-13.88,2.07-4.86,2.31-9.89-.36-14.68-3.02-5.43-8.73-7.91-15.71-6.99Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m424.8,780.42c-9.7-.34-19.68,7.34-21.85,16.8-2.09,9.14,3.43,16.51,12.86,17.16,4.56.31,8.72-.98,12.46-3.47,6.02-4.01,9.8-9.45,10.15-15.56-.01-9.15-5.51-14.64-13.62-14.93Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m498.29,715.48c-9.07,2.07-16.14,10.2-17.07,19.47-.78,7.82,4.72,14.45,12.34,14.89,11.36.66,22.27-9.73,22.19-20.71.04-4.02-.94-7.3-3.47-9.89-3.89-3.98-8.7-4.97-13.99-3.76Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m460.34,792.39c-9.23.24-18.29,7.69-20.41,16.77-2.09,8.99,3.67,16.22,12.87,16.18,8.88.07,18.21-6.82,20.64-16.42,2.34-9.24-3.74-16.76-13.11-16.52Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m327.7,731.14c-1.08.44-2.02,1.11-2.82,1.96-6.74,7.12-9.41,15.86-10.14,25.37.09,1.75.07,3.48.56,5.17,1.15,3.9,4.08,4.92,7.33,2.54,1.1-.81,2.1-1.72,2.94-2.81,4.71-6.14,7.32-13.13,8.24-20.74.37-3.1.47-6.28-.84-9.28-1-2.29-2.95-3.13-5.26-2.19Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m345.4,622.54c-1.64-.8-3.15.07-4.5,1.08-.42.31-.83.65-1.18,1.03-5.8,6.16-8.83,13.61-10.07,21.87-.37,2.46-.4,4.9.61,7.25.79,1.85,2.36,2.48,4.23,1.71,1.38-.56,2.58-1.43,3.55-2.54,5.96-6.82,9.01-14.84,9.65-23.81,0-.79.06-1.58-.01-2.35-.16-1.72-.62-3.43-2.27-4.23Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m444.15,647.31c-7.92-5.77-16.59-10.15-25.68-13.76-1.65-.66-3.34-1.25-5.11-1.49-.43-.06-1.01-.24-1.27.3-.26.52.13.91.48,1.21.99.85,1.95,1.76,3.02,2.5,7.43,5.16,15.44,9.24,23.81,12.65,1.89.77,3.82,1.43,5.8,1.76.5-.06,1.07.2,1.28-.41.16-.44-.14-.81-.45-1.1-.61-.57-1.2-1.18-1.87-1.67Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m483.86,663.26c-.15.34.13.59.34.81.36.38.72.77,1.11,1.11,3.35,2.89,7.17,5.08,11.01,7.22,5.58,3.12,11.25,6.06,17.37,7.99.87.27,2.14,1.12,2.68.08.43-.82-.83-1.52-1.56-2.08-6.98-5.36-14.73-9.37-22.73-12.95-2.33-1.04-4.7-2.06-7.28-2.45-.36-.05-.77-.14-.96.28Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m414.23,622.53c.27-.47-.22-.75-.5-1.03-1.92-1.9-4.22-3.27-6.52-4.62-6.74-3.95-13.64-7.57-21.05-10.13-1.22-.42-2.45-.94-3.95-.65.44,1.37,1.44,1.98,2.37,2.63,6.66,4.71,13.9,8.34,21.37,11.57,2.31,1,4.64,1.93,7.12,2.42.38.07.91.24,1.16-.19Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m479.25,650.52c-1.9-1.74-4.08-3.08-6.28-4.39-6.48-3.84-13.12-7.39-20.26-9.87-.88-.3-1.77-.66-2.94-.28.94,1.43,2.16,2.31,3.4,3.16,5.73,3.88,11.86,7.05,18.13,9.95,2.32,1.07,4.7,2.04,7.19,2.69.49.13,1.14.46,1.45-.02.37-.56-.32-.92-.68-1.24Z"/>
        </g>
      </g>
    </svg>
  )
}