export const SmallFlame12 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="318.81 622.37 468.87 579.62 561.25 727.69 481.83 794.24 349.89 856.07 274.5 710.66 318.81 622.37"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m562.52,726.29c-9.94-16-19.74-32.19-29.5-48.41-5.82-34.14-22.41-65.4-51.25-84.29-3.62-5.79-7.26-11.55-10.93-17.28-3.86,1.09-7.71,2.19-11.56,3.29-29.64-13.23-48.41-21-81.99-15.22-37.87,6.87-71.28-14.44-108.47-16.32-40.09,44.01-81.58,116.37-29.25,167.21,2.48,50.14,52.37,100.36,97.63,117.71,3.85,7.47,7.71,14.94,11.56,22.42.62,1.2,1.23,1.57,2.4.87,10.84-5.08,21.68-10.16,32.51-15.24,63.04,2.22,113.48-39.18,128.73-71.09,16.58-13.84,33.15-27.69,49.75-41.51.86-.72.99-1.17.38-2.14Zm-212.51,123.43c-.99,1.01-1.56.91-2.15-.19-23.53-45.98-47.26-91.89-71.09-137.74-.56-1.07-.63-1.91-.05-3.03,10.32-19.89,20.59-39.81,30.87-59.72,3.71-7.29,6.99-14.14,11.12-21.38,29.5,53.9,58.76,107.76,88.24,161.65.68,1.24.53,1.94-.45,2.92-18.91,19.09-37.73,38.27-56.5,57.49Zm64.94-55.45c45.97-22.86,93.15-40.66,140.05-61.77-22.15,19.26-45.43,38.09-68.12,57.12-3.48,2.52-7.05,4.9-10.8,7.04-6.72,3.21-13.46,6.42-20.2,9.62-6.51,3.04-13.01,6.12-19.5,9.22-26.47,12.47-53.05,24.82-79.47,36.91,18.34-19.18,39.32-39.16,58.04-58.14Zm140.08-61.79h0c.12-.02.25-.05.37-.07-.12.02-.25.05-.37.07Zm.44-7.5c-6.25,2.71-12.48,5.44-18.72,8.17-40.14,17.59-80.32,35.11-120.44,52.76-1.18.52-1.68.3-2.29-.81-29.54-54-59.28-107.89-88.81-161.9,47.85-13.87,95.61-27.62,143.44-41.49,30.02,42.81,56.03,92.47,84.72,137.52.36,1.19,4.48,5.1,2.09,5.75Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m426.52,673.51c1.21,10.14,11.21,18.99,20.78,19.4,10.19.02,16.13-6.41,15.13-15.06-1.51-20.48-36.75-28.68-35.9-4.34Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m358.51,758.2c.44,7.31,2.88,14.38,7.33,20.76,1.85,2.65,3.97,5.01,6.91,6.48,3.16,1.58,5.76.59,7.2-2.61,2.85-8.65-.9-18.01-5.29-25.48-5.03-9.04-16.09-13.91-16.14.85Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m304.28,713.81c-6.03-7.38-10.63-2.38-9.77,5.44,1.14,8.83,4.27,18.94,12.1,24.01,4.65,2.45,6.27-3.7,6.01-7.24-.5-8.25-3.15-15.72-8.34-22.21Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m446.24,790.89c4.75-2.94,11.45-9.42.52-5.49-2.67.35-34.51,17.06-27.29,18.7,9.74-2.35,18.45-7.79,26.77-13.21Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m490.15,772.38c7.16-1.84,27.99-12.5,30.82-18.76-4.85-1.76-23.32,10.42-28.01,13.78-.97.75-6.77,5.01-2.81,4.97Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m417.36,815.73c-10.77,2.86-20.88,8.89-29.48,15.92-5.09,5.66,12.23-2.31,13.36-3.17,1.37-.4,22.27-12.55,16.12-12.75Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m454.56,802.85c7.46-1.83,14.04-6.09,20.56-9.98,2.58-1.3,13.98-9.75,4.13-6.4-2.6.55-29.92,14.45-24.69,16.38Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m260.58,439.05c9.97,8.77,19.85,17.65,29.25,27.05,1,1,1.79,2.41,3.9,2.43,10-34.56-7.21-50.99-37.37-64.08-7.74,12.35-5.94,25.66,4.23,34.6Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m336.51,427.27c1.67-9.84,7.14-28.07-4.17-33.44-6.38,17.68-5.07,28.53,4.17,33.44Z"/>
        </g>
      </g>
    </svg>
  )
}