export const SmallFlame56 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="267.55 677.6 308.57 825.92 426.32 846.37 552.67 762.32 492.57 628.17 386.52 573.19 267.55 677.6"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m554.57,760.69c-4.73-10.24-9.44-20.49-14.15-30.74,31.46-107.65-38.38-130.12-111.91-179.9-16.22,5.41-30.67,14.84-46.56,20.41-30.69,5.14-60.62,15.44-87.07,31.56-16.54,21.2-30.93,43.23-37.12,69.96-19.35-4.15-32.26-15.89-41.51-34.35-9.21,48.08,15.51,91.4,33.66,133.88,22.27,63.66,94.13,86.69,156.42,72.53,6.76,1.09,13.52,2.18,20.3,3.25,42.63-26.94,84.72-56.12,127.19-83.75,1.33-.88,1.35-1.55.75-2.84Zm-246.32,57.74c-12.68-46.43-25.22-92.9-38.01-139.29-.33-1.18-.07-1.91.84-2.72,37.27-32.84,74.27-65.98,111.53-98.83,19.63,47.28,37.34,96.49,56.4,144.38.39.99-.13,1.43-.67,1.82-18.09,13.18-35.92,26.72-53.88,40.09-24.7,18.39-49.36,36.83-74.04,55.25-1.63,1.22-1.65,1.22-2.17-.69Zm238.69-53.13c-39.33,26.14-78.68,52.28-118,78.44-37.86-3.12-76.53-12.47-114.57-18.01,43.45-31.96,86.49-64.28,130.04-96.05,34.06,9.19,68.18,23.09,102.24,33.86.51.17,1.32.11,1.39.77.06.54-.68.71-1.1.99Zm-98.12-44.08c-21.51-46.93-38.11-97.55-58.51-145.51,1.66.86,3.31,1.72,4.97,2.59,7.67,4.07,14.71,7.73,21.16,11.02,22.44,11.7,44.77,23.36,67.31,35.04,5.3,3.99,9.1,8.28,12.79,14.28,17.79,39.62,36.56,79.01,53.88,118.7-33.63-12.63-67.77-23.96-101.59-36.11Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m335.97,743.05c-15.06-9.5-26.71,16.9-20.55,28.88,3.28,8.78,14,8.57,19.06,1.95,4.53-4.99,6.41-11.04,6.65-17.66.02-4.81-1.33-9.99-5.17-13.17Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m361.31,620.89c-6.82,5.2-9.98,12.27-10.36,20.7-.35,6.04,3.21,12.81,9.27,13.36,12.84.27,20.35-15.45,18.52-26.81-.68-8.41-10.25-13.15-17.43-7.24Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m430.91,774.06c7.91-.46,15.27-3.08,21.83-7.81,9.78-6.96,7.26-15-4.95-14.11-9.96,1.23-21.35,5.03-26.42,14.34-2.32,5.9,4.98,7.75,9.54,7.58Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m477.37,760.77c-7.31.21-13.91,2.65-20.04,6.5-2.54,1.6-4.82,3.54-6.46,6.1-2.16,3.38-1.17,6.24,2.63,7.5,8.13,2.05,16.74-1.24,23.77-5.24,8.27-4.25,14.05-14.86.09-14.85Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m392.23,807.65c3.56-5.56-4.1-8.05-8.48-7.62-7.13.14-13.71,2.21-19.8,5.81-10.01,5.54-10.33,14.18,2.71,13.65,9.35-.86,19.82-3.93,25.57-11.84Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m503.45,768.64c-10.12,1.01-21.86,4.6-26.86,14.16-1.45,4.73,4.82,6,8.26,5.57,9.58-1.04,20.25-4.82,25.58-13.3,2.4-4.62-3.27-6.61-6.97-6.43Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m420.83,813.02c2.86-4.9-3.7-7.02-7.53-6.68-7.39.23-14.17,2.45-20.4,6.39-3.61,2.07-9.85,8.31-3.71,11.19,9.84,3.18,25.77-2.44,31.65-10.89Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m439.44,812.17c-8.99.99-19.23,4.05-24.51,11.88-2.28,4.75,3.41,5.97,7.42,5.89,7.64-.49,15.19-3.11,21.8-8.24,6.94-5.46,2.93-10.25-4.71-9.53Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m452.62,677.11c-6.25-1.63-6.2,6.01-4.48,9.97,1.93,5.46,5.02,10.23,8.87,14.49,3.33,4.37,16.87,14.73,17.24,3.37.03,0,.05,0,.07-.01-1.87-11.57-10.63-23.7-21.71-27.81Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m430.73,611.49c-3.29-3.05-12.41-8.34-12.49-.04,2.25,10.84,9.53,19.41,18.89,25.06,2.93,1.79,7.6,1.7,7.44-2.74-1.02-9.04-7.3-16.42-13.84-22.27Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m501.36,707.27c3.4,8.99,9.32,17.89,18.25,22,3.16,1.13,5.16-.24,5.27-3.63-1.93-10.64-9.8-22.8-20.15-26.65-4.99-1.12-4.59,5.2-3.37,8.28Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m479.76,635.55c-2.18-1.81-7.88-4.62-8.43.01.26,8.64,6.44,15.74,12.31,21.51,2.53,2.71,10.86,8.11,11.01,1.01-1.38-9.24-7.79-16.82-14.88-22.53Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m285.47,427.23c-10.17-18.5-17.39-37.96-40.41-45.28-1.55,8.41.29,15.1,5.9,20.54,10.9,9.45,20,20.51,34.51,24.74Z"/>
        </g>
      </g>
    </svg>
  )
}