export const NoFlame23 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="305.17 630.43 460.55 577.49 515.09 712.64 527.41 815.26 357.6 863.73 276.38 725.29 305.17 630.43"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m528.22,815.18c.18,1.35-.32,1.9-1.6,2.26-37.44,10.46-149.27,41.79-167.74,47.03-1.32.37-1.9.09-2.58-1.04-10.76-17.83-68.09-108.38-81.39-136.94,9.14-31.16,19.54-65.32,28.61-96.5,50.66-19.85,100.82-34.5,155.49-53.58,1.17-.41,1.58-.18,2.02.95,5.22,13.46,10.65,26.84,16.16,40.18,10.42,26.12,21.1,52.13,31.61,78.21,2.84,7.66,7.36,14.91,7.48,23.27,3.06,24.39,10.87,88.53,11.93,96.18Zm-168.03,44.68c53.28-14.98,106.56-29.99,159.86-44.94,1.29-.36,1.32-.78.83-1.9-20.07-46.02-40.11-92.05-60.1-138.1-.62-1.43-1.17-1.7-2.65-1.21-58,18.89-116.04,37.68-174.05,56.54-1.89.61-1.89.58-.86,2.3,25.88,42.14,50.45,86.03,76.97,127.31Zm96.75-236.97s-.52-27.75-1.01-41.61c0-1.28-.48-1.43-1.66-1.01-48.72,17.16-92.5,32.46-146.25,51.26-1.18.41-1.75,1.06-2.08,2.24-3.14,11.24-20.45,70.6-24.99,88.93,45.89-13.94,163.03-53,175.54-57.04,1.17-.38,1.57-.91,1.47-2.1-.53-13.55-.63-27.12-1-40.67Zm68.56,184.47c.49-.56.32-.99.28-1.36-1.34-11.8-8.3-70.33-10.68-87.78-.37-2.89-.28-5.89-1.38-8.65-17.2-41.85-33.56-84.17-51.2-125.76.96,26.08,1.99,52.13,2.98,78.2.11,2.37-.28,4.83.71,7.13,19.79,46.08,39.47,92.16,59.31,138.22Zm.5.63c-.03-.08-.06-.16-.09-.24-.16.09-.05.22-.01.35.03-.03.06-.07.1-.1Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m526.01,808s-.07.07-.1.1c-.04-.13-.14-.26.01-.35.03.08.06.16.09.24Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m405.52,789.32c-13.36.36-27.1-15.28-14.12-26.26,15.6-14.13,48.82,6.48,26.92,22.96-3.79,2.27-7.9,3.22-12.8,3.3Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m424.13,643.87c4.28-.22,10.06,2.21,8.14,7.38-4.67,10.27-16.85,15.18-27.49,16.35-7.81.73-12.23-4.84-6.56-11.31,6.93-7.71,15.74-11.5,25.91-12.43Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m363.77,658.02c-4.17.3-8.96-2.4-6.51-7.06,5.58-9.42,17.01-14.7,27.81-14.95,12.05.66,3.57,12.13-2.35,15.29-5.73,3.84-11.95,6.42-18.95,6.72Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m347.99,628.8c10.77-.21,5.35,9.15-.13,12.74-6.68,4.72-15.25,9.42-23.65,7.36-5.19-2.1-.79-8.35,2.06-10.56,6.11-5.32,13.55-9.02,21.71-9.53Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m486.84,696.36c-.17,1.19.5,2.84-.59,3.29-1.07.34-1.57-.82-2.04-1.59-5.4-9.42-8.62-20.04-10.42-30.7.06-1.39-.86-4.25.39-5.09.81-.32,1.21.49,1.59.99,6.08,9.87,9.43,21.7,11.07,33.11Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m515.77,765.96c-.21.89.46,2.23-.63,2.55-1.14.33-1.54-1.03-2-1.85-4.89-9.64-8.37-20.27-9.4-31.05-.64-3.15,1.36-3.47,2.55-.66,4.98,9.6,8.12,20.29,9.48,31.01Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m499.36,707.49c-.18.76.4,1.93-.58,2.23-1.02.31-1.44-.85-1.87-1.57-4.9-9-7.89-19.01-9.62-29.08.05-1.27-.72-3.3.36-4.19,5.36,3.22,11.05,25.79,11.7,32.61Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m483.4,650.43c-.26.85.46,2.12-.58,2.4-.86.22-1.39-.93-1.82-1.69-5.02-9.57-8.66-20.14-9.89-30.89-.3-1.79,1.14-2.42,1.93-.67,5.49,9.35,8.73,20.16,10.36,30.85Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m510.13,716.08c-.24.77.46,1.97-.56,2.21-.84.2-1.54-.77-1.95-1.56-4.43-9.22-7.56-19.32-8.74-29.5-.21-1.45,1.05-1.98,1.69-.53,5.03,8.99,7.99,19.23,9.56,29.38Z"/>
        </g>
      </g>
    </svg>
  )
}