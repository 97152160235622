export const SmallFlame10 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="314.27 620.98 452.32 577.34 560.64 704.69 487.19 797.06 365.26 864.85 271.4 730.7 314.27 620.98"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m563.49,702.63c-11.37-13.06-22.71-26.29-34.05-39.55-7.08-35.96-35.64-63.17-65.14-83-19.67-16-41.6-27.36-66.76-32.02-12.39-1.11-23.64,7.22-35.89,8.89-8.92,2.14-17.98,3.11-27.1,3.98-35.56,4.91-52.05-26.85-72.92-49.32-40.64,41.59-102.37,129.99-35.13,174.05,8.98,5.03,17.5,10.82,24.58,18.08-2.28,74.7,50.02,119.95,92.3,131.27,7.07,9.94,14.03,19.9,20.72,29.94.72,1.03,1.34,1.11,2.43.5,6.61-3.74,13.25-7.41,19.89-11.1,8.51-4.85,17.07-9.62,25.66-14.36,49.83-10.91,86.56-37.96,109.33-83.59-.05,0-.1-.01-.16-.02.08-.09.16-.19.24-.28-.02.09-.04.18-.06.26,0,0,0,0,0,0,0,.01-.01.02-.02.04,0,0,.01,0,.02,0,.4-.13.55-.33.31-.6,14.68-17.64,29.34-35.25,41.75-53.16Zm-8.03-.91c-41.41,20.22-82.76,40.54-124.2,60.71-36.87-46.4-72.66-94.27-109.18-141.15-1.08-1.4-1.08-1.41.67-1.95,43.52-12.16,87.82-29.74,130.98-39.24,34.05,39.8,68.11,79.59,102.22,119.34q1.24,1.44-.49,2.3Zm-191.88,157.71c-29.92-42.31-59.71-84.74-89.68-127.01-.75-.95-.74-1.8-.31-2.88,13.58-34.22,27.08-68.47,40.62-102.71.29-.47.24-1.29.84-1.37,8.96,10.71,17.3,22.63,26.06,33.73,27.49,35.86,54.89,71.8,82.42,107.63.72.9.66,1.53.05,2.46-20.14,29.92-39.59,60.53-59.99,90.15Zm8.16,1.39c-.06.09-.12.13-.17.14.05-.01.11-.06.17-.14-.04-.04-.08-.09-.12-.13h0c19.58-30.02,40.32-59.38,60.31-89.15,40.95-21.61,83.42-42.31,125.05-62.43-12.27,15.92-25.07,31.83-37.91,47.73-.9.62-1.51,1.68-2.22,2.58-1.61,2.03-3.31,4.2-5.07,6.46-8.18,10.14-16.34,20.28-24.35,30.43-37.98,22.21-77.16,43.16-115.69,64.51Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m429.62,648.69c-4.64,2.91-6.13,8.21-3.54,13.64,4.44,9.32,12.3,13.67,20.79,14.61,4.74.03,8.02-.86,9.86-2.25,14.68-13.45-13.52-34.59-27.11-26Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m367.6,748.88c0,6.75,3.01,14.95,7.17,19.94,2.34,2.8,4.89,5.32,8.4,6.64,3.89,1.46,6.81.44,8.98-3.06,4.52-9.48-.47-20.7-6.75-28.18-6.87-8.57-17.95-7.82-17.8,4.67Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m302.4,722.51c-5.73-2.93-8.79,3.67-8.42,8.51.31,7.31,2.92,13.76,7.49,19.42,2.76,4.27,10.76,8.06,13.1,1.46,2.83-10.2-2.91-24.28-12.17-29.39Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m462.51,765.84c-10.36,2.62-18.77,10.08-26.02,17.64-4.8,4.95-5.49,10.43,2.98,6.47,3.86-.44,33.4-22.58,23.04-24.1Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m504.17,756.3c3.13-.91,28.44-20.58,19.8-21.9-9.63,2.7-17.5,10.13-24.27,17.24-6.19,7.13-2.72,8.85,4.48,4.66Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m426.11,813.63c-3.54.16-32.25,20.9-23.07,22.27,9.88-2.74,18.09-9.86,25.13-17.09,3.41-3.49,4.4-8-2.06-5.18Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m485.03,781.82c-3.24.52-29.16,19.48-21.16,21.02,9.67-3.33,17.79-10.24,24.36-17.99,3.19-4.05.55-4.96-3.2-3.03Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m267.51,501.3c11.17-11,37.32-31.75,29.32-48.54-8.68-12.79-9.19-28.39-11.69-43.2-12.51,7.39-23.12,18.33-30.98,30.67-3.01,5.18-5.07,10.79-2.69,16.65,5.85,14.42,12.27,28.61,16.04,44.43Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m350.82,401.85c-7.92,11.32-31.76,29.82-18.72,43.52,17.42-8.42,26.77-29.18,18.72-43.52Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m128.95,444.25c4.36,7.51,9.35,14.38,17.88,17.59-3.99-7.9-10.32-13.37-17.88-17.59Z"/>
        </g>
      </g>
    </svg>
  )
}