export const BigFlame57 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="302.99 825.44 416.99 838.94 550.29 772.05 498.34 626.83 397 575.67 269.11 665.2 302.99 825.44"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m239.08,361.22c15.56-17.66,20.21-51.4-7.9-59.03-14.28-5.31-29.15-9.33-41.77-21.22,5.85,22.36,10.94,43.23-1.21,64.24-16.89,26.73-16.75,66.58,9.45,86.99,5.61-28.5,20.36-51.02,41.42-70.98Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m322.14,461.14c2.67-36.39-41.44-29.59-59.04-52.01,9.72,55.96,29.04,32.54,59.04,52.01Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m539.31,740.49c55.36-86.53-1.03-178.18-101.69-146.34-13.13-6.71-26.32-13.31-39.46-20.01-1.25-.64-2.09-.57-3.24.24-17.08,12.07-34.17,24.14-51.26,36.2-29.22,2.81-71.96,17.87-88.91-12.24-9.17,44.19-19.45,87.56-17.49,132.94-8.88-1.97-15.64-5.41-18.9-14.37-10.89,24.97,7.71,43.57,25.87,57.42,2.42,2,5.29,3.6,6.93,6.38,12.15,20.12,30.66,35.82,51.45,46.22-.02-.13-.05-.25-.07-.38.02.01.03.02.05.04,0,.11,0,.23.02.34,27.68,16.88,62.17,15.7,93.29,10.19,6.96.77,13.92,1.53,20.89,2.29,44.42-21.28,88.49-44.11,132.82-65.85,1.18-.58,1.52-1.1.99-2.38-3.92-10.25-7.65-20.48-11.29-30.7Zm-137.51-160.56s-.08,0-.11.02c.03-.02.06-.03.11-.02Zm-.13.16s0,0,0-.01c0,0,0,0,0,.01Zm-7.55,2.38c16.02,52.72,31.85,105.5,48,158.19.48,1.54.09,2.17-1.23,2.91-27.85,15.54-55.68,31.13-83.51,46.71-17.08,9.57-34.18,19.12-51.27,28.69-.93.57-1.3.43-1.54-.71-11.09-50.36-22.02-100.77-33.15-151.12,39.12-29.12,80.76-56.71,120.71-85.32,1.48-1.04,1.48-1.05,1.99.64Zm26.17,253.83c-2.6,1.31-4.99.76-7.47.5-34.09-4.08-68.31-7.26-102.31-11.91,45.08-25.16,90.44-49.83,135.54-74.97,32.5,4.24,65.38,16.99,97.91,24.24-40.89,21.17-82.57,41.24-123.67,62.15Zm126.31-69.29c-.35.36-.89.02-1.33-.1-30.92-8.58-61.83-17.17-92.75-25.7-1.12-.31-1.59-.87-1.91-1.92-16.16-53.07-33-105.94-48.83-159.08-.03-.02-.06-.04-.08-.07.02.02.04.05.08.07.05-.05.11-.11.16-.16,31.69,16.37,65.3,30.91,95.83,48.69,16.17,45.67,32.43,91.32,48.59,136.99.14.42.62.89.25,1.28Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m376.69,656.3c6.64-5,9.6-11.92,9.83-20.1-3.11-29.59-34.24-9.11-29.22,12.33,1.11,9.37,12.11,13.97,19.39,7.76Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m333.13,777.02c14.16-9.79,7.5-45.75-12.71-31.3-5.43,4.89-7.64,11.19-7.85,18.33-.61,11.16,9.72,22.48,20.57,12.98Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m460.01,690.42c-6.82-3.28-8.93,3.93-7.67,9.19,2.41,10.27,8.23,21.73,18.74,25.46,4.09,1.23,6.32-3.15,6-6.65-.94-11.11-7.3-22.42-17.07-28Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m443.34,620.73c-4.05-6.29-17.72-15.23-16.19-1.06,2.24,10.31,8.93,22.26,19.41,25.66,3.27.77,5.21-2.54,4.96-5.43-.27-7.2-3.91-13.61-8.18-19.17Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m508.61,708.22c-4.02-2.27-6.83.84-6.42,4.93-.03,0-.06,0-.09,0,.59,7.97,4.25,15.13,9.28,21.19,3.99,5.6,13.61,10.14,12.68-.83-1.57-9.86-6.71-20.01-15.45-25.29Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m481.14,635.33c-2.94-.75-4.2,2.29-4.15,4.71,1.16,9.59,6.51,18.75,14.3,24.53,4.24,3.57,8.54.54,7.25-4.67-1.92-9.62-7.79-21.04-17.39-24.57Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m428.96,783.68c9.93-.89,20.76-3.51,27.94-10.83,3.93-4.82-3.23-6.66-6.98-6.35-10.19.63-21.16,3.32-28.67,10.6-4.64,5.21,3.76,7.13,7.71,6.58Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m454.36,788.87c7.91-.02,18.69-3.42,25.09-7.88,2.57-1.46,6.41-6.01,1.88-7.56-9.74-2.26-19.68,1.47-28.25,5.9-7.33,3.88-8.81,9.82,1.28,9.54Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m381.01,804.22c-9.66.62-20.12,2.78-27.51,9.4-4.34,4.73,3.71,6.16,7.15,5.86,6.38-.28,12.53-1.67,18.42-4.12,7.99-2.72,16.15-10.87,1.94-11.14Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m477.99,793.77c8.29-.21,15.96-2.65,23.17-6.63,6.83-3.72,8.56-9.05-1.17-8.8-8.66.6-16.74,3.04-24.07,7.77-5.74,3.51-4.96,7.88,2.07,7.66Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m408.68,808.58c-9.5.71-20.03,2.8-27.03,9.7-2.95,3.59,2.95,4.96,5.61,4.75,8.23-.06,15.91-2.26,23.16-6.03,7.37-3.68,7.13-8.75-1.74-8.42Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m434.02,812.59c-9.22.82-19.32,2.87-26.11,9.56-4.13,8.52,20.7,2.03,23.76.07,6.42-2.2,15.22-9.66,2.35-9.63Z"/>
        </g>
      </g>
    </svg>
  )
}