export const SmallFlame06 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="298.58 620.66 415.32 584.54 549.76 657.43 501.27 798.93 402.43 865.9 274.16 772.92 298.58 620.66"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m396.72,710.35c-10.62-6.27-20.57,5.42-18.08,15.9,1.52,20.14,30.88,32.81,31.51,5.43-1.05-9.26-5.02-16.68-13.44-21.34Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m311,743.18c-5.44-2.47-10.59-.35-12.54,5.24-6.91,16.54,21.4,44.52,25.92,16.97-.42-9.01-4.98-18.24-13.38-22.21Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m445.61,639.71c-6.99-7.73-17.74-10.1-27.8-9.5-8.18,1-10.35,5.83-3.36,11.08,6.36,4.59,13.65,6.37,22.68,6.83,3.77-.52,13.03-2.01,8.48-8.4Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m471.71,719.81c-9.78,5.16-15.46,15.37-18.1,25.77-1.42,7.3,1.31,11.72,8.5,6.8,8.48-6.67,14.58-16.64,15.58-27.51.3-3.61-2.2-6.92-5.98-5.06Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m450.27,794.87c-7.88,3.83-12.82,11.64-16.35,19.36-2.76,5.09-4.19,17.09,5.03,11.48,8.6-6.13,15.2-16,16.43-26.56.27-3.37-1.68-5.78-5.1-4.28Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m525.25,698.55c.19-2.92-1.21-6.09-4.53-4.64-9.39,5.12-14.52,15.39-16.82,25.51-1.22,17.08,15.44-2.3,17.44-8.35,2.06-3.97,3.64-8.11,3.91-12.52Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m498.12,764.77c-8,4.26-12.61,12.67-15.67,20.86-2.36,7.12-.68,12.81,6.85,6.43,6.61-6.14,11.93-14.5,12.93-23.6.29-2.7-1.27-5-4.1-3.69Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m546.42,672.57c1.78-5.28,3.53-10.45,5.23-15.45-2.98-1.54-5.96-3.09-8.93-4.63-.21-.79-.43-1.58-.65-2.37-3.13-11.15-7.02-22.39-11.84-33.21-13.76-30.88-35.16-58.19-68.59-69.19-3.87-1.37-7.46-2.82-10.83-4.34-7.7-3.48-14.2-7.33-20.02-11.38-17.27-12.02-28.54-25.78-47.34-36.61-10.92-6.29-24.37-11.6-43.03-15.02,38.63,68.16-12.76,62.34-61.09,57.33-15.68-1.62-31.04-3.16-42.89-2.06-1.6,9.28-.96,18.27.86,27.12,1.41,6.85,3.52,13.62,5.85,20.36,4.01,11.63,8.66,23.19,11.4,35,.91,3.93,1.61,7.88,2.01,11.88-3.36.25-6.39.48-9.75.73,3.65,6.8,5.95,13.21,7.24,19.43,2.55,12.44,1.05,24.19-1.83,36.76-1.11,4.81-2.41,9.76-3.77,14.92-.42,1.57-.84,3.16-1.26,4.77-8.17,31.57-1.87,61.29,13.59,85.21,6.54,10.12,14.71,19.2,24.12,26.94,23.75,19.52,55.38,30.51,88.43,28.16,9.48,6.78,18.97,13.55,28.45,20.33,1.15.82,1.91.93,3.13.08,32.36-22.51,64.8-44.91,97.23-67.33,2.35-6.21,4.7-12.59,7.06-19.08,26.78-31.3,41.85-66.5,37.22-108.35Zm-133.24-83.7c44.41,19.81,87.61,45.27,131.42,67.24-32.06,15.56-64.19,30.96-96.22,46.57-1.34.68-2.36.7-3.72-.1-46.65-27.47-93.5-54.56-140.13-82.05,36.09-10.9,72.45-21.11,108.66-31.66Zm-12.97,272.3c-41.19-29.15-82.22-58.45-123.41-87.6,4.46-48.1,15.84-98.3,22.93-147,46.83,27.62,93.52,55.38,140.36,82.98,1.07.58,1.28,1.2.95,2.38-13.7,49.73-27.11,99.51-40.82,149.24Zm100.98-63.61s-61.91,41.61-93.17,64.16c15.02-49.89,42.69-150.5,42.69-150.5,32.12-15.89,64.7-32.46,96.83-48.32-.56,1.68-1.14,3.37-1.71,5.06-.09-.66.1.66,0,0-7.11,21.54-14.5,41.12-21.61,62.67-7.85,22.48-15.68,44.94-23.03,66.94Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m162.83,489.5c0,.07,0,.13.01.2.01.13.03.26.04.4.01.12.02.24.04.36,0,.06.02.12.03.19.03.19.06.39.09.58,0,0,0,.02,0,.03.09.51.2,1.02.32,1.53,0,.02,0,.03.01.05.32,1.3.76,2.62,1.3,3.97.02.05.04.1.06.15.09.22.18.45.28.67.02.04.04.09.06.13.1.23.2.46.3.69.05.11.1.23.16.34.07.16.15.32.22.49.06.12.11.24.17.35.08.16.15.31.23.47.12.25.25.5.37.75.01.03.03.05.04.08,2.76,5.44,6.57,10.94,9.77,15.68,2.02,2.99,3.79,5.68,4.91,7.85-1.55-7.52-1.19-15.82-1.96-23.64-.33-3.33-.87-6.56-1.84-9.61-1.55-4.84-4.2-9.22-8.9-12.74-4.31,2.58-5.88,6.2-5.75,10.34,0,.05,0,.1,0,.15,0,.18.02.36.04.54Z"/>
        </g>
      </g>
    </svg>
  )
}