export const SmallFlame43 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="289.82 757.06 351.89 583.88 529.27 638.34 477.02 816.12 457.7 844.43 302.84 796.53 289.82 757.06"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m445.01,731.53c-1.31,0-2.61.15-3.9.37-10.75,1.88-17.84,13.15-14.9,23.49,2.88,10.14,12.7,16.18,22.81,14.76,9.64-1.35,15.95-8.4,16.53-17.6.73-11.6-8.56-20.89-20.54-21.02Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m399.9,717.71c-3.77-.06-7.22.99-10.47,2.86-8.81,5.06-11.96,17.24-6.82,25.72,4.64,7.65,14.03,11.38,22.45,9.51,10.64-2.35,17.28-12.99,14.52-23.5-2.25-8.57-10.25-14.5-19.68-14.59Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m354.94,703.9c-10.77-.1-19.68,7.84-20.2,18.47-.53,10.58,7.83,19.4,20.25,19.91.75.09,2.04-.15,3.33-.34,10.96-1.62,18.42-12.13,16.21-22.85-1.81-8.79-9.96-15.11-19.59-15.19Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m489.66,661.64c-3.01-9.47-13.55-15.31-23.87-13.05-10.42,2.28-16.32,12.75-12.85,22.79,2.69,7.78,10.66,13.15,19.76,13.06.84.03,2.04-.12,3.22-.35,10.9-2.11,16.85-12.67,13.74-22.44Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m434.18,669.93c9.49-3.23,14.44-12.5,11.65-21.8-2.31-7.68-10.36-13.34-18.98-13.34-4.33,0-7.78,1.02-11.25,3.33-7.37,4.9-9.79,14.49-5.62,22.29,4.55,8.52,15.03,12.65,24.2,9.53Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m396.57,652.85c6.92-5.75,8.3-15.47,3.18-22.87-3.94-5.69-9.62-8.09-16.32-8.47-3-.1-5.85.53-8.57,1.71-10.02,4.35-14.11,16.6-7.71,25.8,6.52,9.37,20.49,11.25,29.42,3.83Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m354.99,785.12c-5.78-3.51-12.1-5.71-18.57-7.52-3.58-1-7.2-1.84-10.95-1.89-.68,0-1.36-.02-2.04,0-.65.02-1.33.14-1.57.81-.26.71.12,1.29.65,1.8.99.96,2.12,1.71,3.31,2.37,7.77,4.31,16.12,6.97,24.82,8.56,2.1.38,4.26.72,6.39.15,1.43-.38,1.57-.98.62-2.1-.75-.89-1.67-1.59-2.66-2.19Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m397.58,807.67c-5.45-3.36-11.43-5.4-17.55-7.1-3.63-1.01-7.29-1.87-11.09-1.94-.68,0-1.36,0-2.04,0-.16,0-.31.02-.47.05-1.21.25-1.48.88-.77,1.87.53.74,1.3,1.21,2.05,1.68,3.79,2.39,7.94,4,12.16,5.42,5.41,1.81,10.9,3.36,16.65,3.66,1.25.06,3.05.27,3.41-.88.4-1.29-1.26-2.08-2.35-2.76Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m434.75,827.2c-5.01-2.69-10.34-4.51-15.81-5.97-3.49-.93-6.99-1.75-10.62-1.83-.78,0-1.57-.05-2.35.02-.45.04-.98.13-1.13.69-.13.47.23.77.51,1.08.86.94,1.94,1.58,3.06,2.15,7.29,3.7,15.02,6.03,23.05,7.38,1.78.3,3.62.68,5.44.15,1.15-.34,1.34-.92.51-1.77-.77-.78-1.7-1.38-2.67-1.9Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m459.15,551.12c-28.73-16.71-37.84-51.35-64.6-69.81-1.02-.68-1.93-1.99-3.57-1.04-1.24,34.6-14.97,40.02-33.94,63.2-20.62,1.56-40.89,4.08-60.89,9.19,11.89,16.06,13.02,33.04,8.18,51.28-24.92-1.94-48.92-6.14-73.41-10.13,33.3,43.52-3.07,96.45,2.97,144.63,3.48,17.86,7.69,35.84,14.15,52.85,31.37,66.93,122.05,72.09,182.12,44.9,9.32,2.89,18.64,5.77,27.96,8.66,1.08.33,1.7.22,2.35-.66,3.61-4.95,7.25-9.88,10.87-14.83,3-4.1,5.96-8.22,8.98-12.31.79-1.07,1.36-2.21,1.72-3.49.7-2.49,1.4-4.97,2.09-7.46,106.78-76.81,100.11-202.14-24.98-254.99Zm15.03,265.16c-5.84,8.56-11.7,17.11-17.49,25.7-.71,1.06-1.4,1.13-2.49.79-7.63-2.37-15.27-4.73-22.9-7.09.04-.02.09-.04.13-.06-41.74-12.92-83.13-25.73-124.41-38.51-.45-.31-.76-.73-.97-1.38-3.81-11.75-7.66-23.48-11.48-35.23-.18-.56-.87-1.53.65-1.38,3.16.97,6.31,1.93,9.46,2.9,39.51,12.19,79.03,24.38,118.54,36.58,16.76,5.17,33.51,10.36,50.26,15.54,1.76.55,1.77.57.7,2.13Zm3.79-9.61c-.33,1.19-.76,1.27-1.84.94-30.26-9.35-60.53-18.67-90.79-28-29.42-9.07-58.83-18.15-88.26-27.18-1.36-.42-1.51-.84-1.04-2.13,15.32-42.27,30.61-84.55,45.89-126.83,2.35-6.5,4.7-12.99,7.05-19.48,2.05-5.31,4.08-10.61,6.13-15.92.02,0,.04,0,.06,0,2.14.64,4.15,1.23,6.15,1.84,31,9.44,62,18.88,92.99,28.32,23.04,7.01,46.07,14.04,69.12,21.01,1.42.43,1.69.88,1.27,2.36-11.57,40.67-23.07,81.35-34.59,122.03-4.06,14.35-8.14,28.69-12.15,43.06Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m193.89,539.69c-.5-15.59-10.47-25.91-19.8-38.03-10.37,34.81-.59,44.34,27.51,62.69,1.01-2.53-.55-3.9-1.39-5.43-3.3-6.02-6.1-12.33-6.32-19.22Z"/>
        </g>
      </g>
    </svg>
  )
}