import { useEffect, useState } from "react"
import { useAuthUser, useSignOut } from "react-auth-kit"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { Dashboard } from "./components/Dashboard"
import { Users } from "./components/Users"
import { Games } from "./components/Games"
import { Transactions } from "./components/Transactions"
import { Locations } from "./components/Locations"
import { Stats } from "./components/Stats"

export const SuperAdminPanel = (props) => {
  const auth = useAuthUser()
  const navigate = useNavigate()
  const location = useLocation()
  const signOut = useSignOut();

  useEffect(()=>{
    if (auth()?.id) {
      if (!auth()?.locations.includes(0)) {navigate("/", {replace: true, state: {}})}
    } else {
      navigate("/login", {state: {redirect: location.pathname}})
    }
  }, [auth()?.id])

  return (
    <div id="super-admin">
      <div className="SA-left">
        <div onClick={()=>navigate("/super", {replace: true, state: {}})}>RadSoftware</div>
        <div onClick={()=>navigate("users", {replace: true, state: {}})}>Users</div>
        <div onClick={()=>navigate("games", {replace: true, state: {}})}>Games</div>
        <div onClick={()=>navigate("transactions", {replace: true, state: {}})}>Transactions</div>
        <div onClick={()=>navigate("locations", {replace: true, state: {}})}>Locations</div>
        <div onClick={()=>navigate("stats", {replace: true, state: {}})}>Stats</div>
        <div onClick={()=>signOut()}>Sign Out</div>
      </div>
      <div className="SA-right">
        <Routes>
          <Route path="/" element={
            <Dashboard/>
          }/>
          <Route path="/users/*" element={
            <Users
              backend={props.backend}
            />
          }/>
          <Route path="/games/*" element={
            <Games/>
          }/>
          <Route path="/transactions/*" element={
            <Transactions/>
          }/>
          <Route path="/locations/*" element={
            <Locations/>
          }/>
          <Route path="/stats" element={
            <Stats/>
          }/>
        </Routes>
      </div>
    </div>
  )
}