export const NoFlame22 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="303.53 637.58 460.49 582.54 511.07 722.54 529.75 810.34 357.01 864.43 279.82 713.58 303.53 637.58"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m510.2,720.51c1.19,1.91,1.44,4.13,1.9,6.26,6.01,27.4,12,54.81,17.99,82.21q.77,3.51-2.68,4.56c-32.79,10.01-65.57,20.02-98.36,30.03-23.99,7.33-47.97,14.65-71.95,22.01-1.23.38-1.77.25-2.38-.97-8.89-17.69-17.84-35.35-26.78-53.01-16.14-31.9-32.26-63.8-48.44-95.67-.75-1.47-.82-2.72-.34-4.29,7.59-24.54,15.12-49.1,22.64-73.67.28-.92.67-1.49,1.65-1.83,51.64-18.08,103.26-36.18,154.88-54.32,1.38-.49,1.99-.43,2.4,1.11.67,2.52,1.93,4.85,2.48,7.42-.65-.46-.71-.16-.62.45.86,5.47,8.82,59.07,10.07,62.49,5.12,14,53.96,146.2,54.7,148.17.2-.27.24-.48.2-.68-5.14-23.65-10.29-47.3-15.42-70.95-.67-3.1-1.3-6.21-1.95-9.32Zm-151.54,139.92c.34-.1.64-.19.94-.28,2.2-.67,110.9-33.99,163.06-49.95,1.08-.33,1.27-.7.87-1.73-1.46-3.76-38.82-103.9-56.1-150.19-.78-2.1-.77-2.1-2.82-1.41-19.8,6.67-137.5,46.34-176.55,59.5q-1.88.63-1.01,2.37c18.61,37.06,65.65,130.74,70.58,140.51.23.46.27,1.18,1.03,1.18Zm105.76-213.09c-.47-3.55-6.07-44.39-8.33-60.82-.29-2.09-.27-2.1-2.28-1.4-13.94,4.88-111.39,39.03-146.19,51.18-1.12.39-1.68.99-2.01,2.11-5.46,18.6-19.19,65-20.55,69.62-.11.38-.46.81-.07,1.16.37.34.78-.03,1.17-.13.25-.07.5-.16.75-.24,26.06-8.83,133.14-45.08,160.62-54.37,5.21-1.76,10.42-3.52,15.62-5.3.64-.22,1.56-.26,1.29-1.8Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m477.61,654.41c5.61,11.73,9.58,24.01,12.57,36.93-1.32-.28-1.45-1.09-1.73-1.68-2.98-6.36-5.14-13.03-7.31-19.7-1.39-4.27-2.67-8.58-3.63-12.98-.18-.83-.28-1.64.09-2.56Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m516.84,765.63c-2.76-3.15-11.86-30.88-11.68-35.53.89.19,1.01,1.01,1.28,1.61,3.26,7.27,5.6,14.88,7.92,22.48.95,3.1,1.76,6.24,2.41,9.42.13.65.22,1.29.07,2.03Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m499.33,708.68c-5.11-10.94-8.79-22.34-11.65-34.46,2.69,1.66,12.03,29.57,11.65,34.46Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m471.63,620.51c5.15,10.69,8.86,21.86,11.56,33.37l-.78.26c-4.7-10.84-8.46-22-10.78-33.63Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m508.3,723.58c-1.44-.32-1.61-1.37-1.93-2.17-2.56-6.43-4.83-12.97-6.65-19.65-.65-2.42-1.38-4.81-2.08-7.22-.24-.81-.27-1.6.11-2.47,4.67,10.06,8.02,20.54,10.55,31.51Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m410.13,747.79c5.75.29,11.52,2.44,15.54,8,4.76,6.58,3.88,14.72-2.04,20.3-7.39,6.97-20.34,7.43-28.18,1-8.69-7.13-8.2-19.08,1.08-25.51,3.82-2.65,8.09-3.8,13.6-3.78Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m430.17,635.38c1.6.02,3.17.09,4.66.7,2.52,1.02,3.21,2.9,1.92,5.29-.93,1.73-2.27,3.14-3.81,4.33-7.13,5.51-15.2,8.68-24.14,9.66-2.09.23-4.18.05-6.17-.76-2.07-.85-2.74-2.5-1.87-4.56.75-1.78,1.99-3.21,3.46-4.4,7.57-6.15,16.28-9.44,25.96-10.25Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m365.46,651.69c-1.24.02-2.42-.15-3.57-.53-2.19-.72-2.89-2.35-1.87-4.42,1.03-2.07,2.7-3.6,4.54-4.9,7.35-5.21,15.34-8.71,24.51-8.91.79-.02,1.56.06,2.33.28,2.89.81,3.62,2.6,2.04,5.19-1.05,1.73-2.52,3.07-4.13,4.25-7.12,5.22-15.04,8.32-23.86,9.05Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m328.23,648.25c-1.08-.05-1.97.02-2.85-.22-2.45-.67-3.12-2.19-1.83-4.38,1.11-1.88,2.75-3.3,4.51-4.53,6.57-4.62,13.78-7.66,21.83-8.53,1.32-.14,2.62.03,3.87.45,1.74.59,2.28,1.89,1.5,3.59-.98,2.12-2.68,3.6-4.51,4.93-6.12,4.42-12.89,7.33-20.36,8.56-.77.13-1.57.1-2.16.14Z"/>
        </g>
      </g>
    </svg>
  )
}