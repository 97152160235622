export const NoFlame45 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="297.9 784.92 451.45 831.25 486.44 832.56 535.58 651.06 358.71 592.94 297.9 784.92"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m298.57,785.35c-1.27.08-1.47-.56-1.5-1.73-.18-6.53,2.07-12.51,4.01-18.57,10.84-33.89,47.88-149.83,55.52-173.93.47-1.48,1.01-1.64,2.4-1.17,27.39,9.11,146,48.35,177.92,58.84,1.55.51,1.68,1.07,1.28,2.51-12.69,45.95-45.76,166.11-49.6,180.25-.36,1.33-1.07,1.56-2.24,1.51-10.52-.49-21.04-.94-31.56-1.42-1.6-.07-3.26.12-4.75-.75.47-.35,1.01-.15,1.52-.18,2.3-.15,4.59.23,6.89.27,6.54.13,13.07.25,19.61.32.65,0,1.35.51,2.12-.05-.65-.52-1.28-.72-1.92-.92-13.37-4.05-168.8-50.86-174.06-52.41-.57-.17-1.21-.37-1.55.26-1.33,2.41-3.16,4.53-4.08,7.17Zm233.51-132.1c.08-.7-.55-.73-1.01-.89-2.93-.97-115-37.95-168.1-55.47-2.19-.72-1.93-.98-2.66,1.3-14.15,44.49-50.56,159.01-54.67,171.76-.42,1.31-.32,1.91,1.15,2.28,2.69.68,5.32,1.58,7.97,2.39,39.84,12.05,151.91,45.91,168.09,50.84,1.23.38,1.67.05,1.95-1.12.55-2.29,1.23-4.55,1.86-6.82,14.99-54.23,29.98-108.47,44.97-162.7.14-.5.29-1.01.45-1.57Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m327.17,787.59c12.08,4.7,24.39,8.49,37.37,11.16-5.08-3.01-32.94-11.41-37.37-11.16Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m327.17,787.59c4.43-.25,32.3,8.15,37.37,11.16-12.98-2.67-25.29-6.46-37.37-11.16Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m363.56,720.99c11.14.07,20.41,9.18,20.3,19.93-.11,11.27-8.93,20.29-19.99,20.07-11.84-.23-20.44-9.18-20.17-20.43.27-11.31,8.99-19.37,19.86-19.57Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m408.95,774.93c-11.02-.43-19.91-8.82-19.82-20.68.08-10.32,9.08-19.2,19.37-19.18,11.2.02,20.2,8.9,20.28,20.04.08,10.98-8.75,19.81-19.83,19.83Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m453.28,748.95c11.1.2,20.19,9.33,19.81,20.3-.4,11.6-8.77,19.6-19.52,19.34-11.44-.28-19.7-9.21-19.5-20.58.19-10.84,8.78-18.96,19.2-19.06Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m371.38,651.56c.5-10.95,8.72-18.62,19.07-18.52,11.77.12,20.47,9.36,20.15,20.43-.28,10.04-8.25,18.25-19.03,18.22-11.39-.03-20.3-9.13-20.19-20.13Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m434.97,647.38c10.72.48,19.63,8.63,19.52,20.1-.1,10.4-8.08,18.31-18.56,18.43-11.19.13-20.33-9.1-20.07-20.34.23-10.19,8.27-18.35,19.11-18.19Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m497.82,681.52c-.14,10.46-8.22,18.5-18.45,18.37-11.05-.15-19.74-9.12-19.62-20.24.12-10.21,8.47-18.42,18.76-18.06,11.19.39,19.32,9.28,19.3,19.93Z"/>
        </g>
      </g>
    </svg>
  )
}