export const BigFlame47 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="290.33 770.53 309.41 791.78 487.92 844.39 544.29 666.03 368.81 602.57 340.84 616.08 290.33 770.53"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m163.88,403.97c-3.99-11.43-5.47-23.24-6.24-35.51-26.14,47.39-9.93,54.04,15.94,93.27,11.45-11.52,7.61-29.05-.97-41.03-3.62-5.23-6.63-10.7-8.74-16.74Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m545.05,664.5c-.2-.07-.4-.14-.59-.21-3.77-40.84-21.92-81.98-46.04-114.82-1.4,8.33,1.92,16.5-1.16,24.28-10.93-2.16-12.77-16.06-16.65-24.62-35.47-90.88-88.8-51.59-133.59-110.61,4.92,48.28,56.16,43.01,58.52,90.36-19.82-8.8-39.39-16.35-61.41-16.74-10.81-.23-21.52-1.91-32.11-4.21-39.09-7.54-73.77-26.89-108-44.96,28.68,39.04,46.59,81.91,48.25,131.05-14.09,6.77-28.95,21.7-44.39,8.13,10.74,44.64,51.46,52.58,30.71,109.22-2.73-.36-4.22-3.87-7.8-3.63,6.97,12.56,10.59,25.97,14.57,39.08,20.77,76.46,111.69,105.76,181.49,80.79,20.08,5.93,40.16,11.87,60.23,17.84,1.53.46,2.08.27,2.59-1.33,8.1-25.71,16.24-51.4,24.39-77.1,24.33-28.4,32.89-62.54,30.81-97.18.33-1.03.65-2.05.98-3.08.31-.97.7-1.72-.8-2.25Zm-237.05,121.9c-2.18-2.05-16.49-15.56-16.71-16.31-.23-.82,49.09-153.29,49.65-153.89.56-.6,25.97-10.33,25.98-10.34-2.29,7.03-4.57,14.06-6.87,21.08-8.55,26.2-17.11,52.4-25.66,78.6-6.82,20.88-13.65,41.76-20.47,62.64-1.98,6.07-3.95,12.14-5.93,18.21Zm178.46,50.64c-.86,2.7-.49,2.34-2.92,1.62-46.47-13.8-92.93-27.63-139.39-41.46-9.63-2.87-19.26-5.77-28.91-8.57-1.33-.39-1.42-.86-1.03-2.05,11.6-35.41,23.17-70.84,34.74-106.26,7.68-23.53,15.37-47.07,23.01-70.61.4-1.22.79-1.49,2.06-1.04,32.85,11.85,65.71,23.66,98.58,35.47,21.92,7.88,43.85,15.76,65.77,23.66.59.21,1.62.14,1.24,1.62-.71,2.25-1.51,4.8-2.32,7.34-9.95,31.38-19.91,62.75-29.86,94.13-6.99,22.05-13.97,44.1-20.97,66.15Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m372.24,737.21c-10.96,0-20.05,9.26-19.96,20.58.09,10.57,8.03,18.99,19.23,19.38,11.29.03,20.35-9.1,20.35-20.53,0-10.85-8.66-19.43-19.62-19.44Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m401.41,687.1c11.02,0,19.48-9.08,19.37-20.08-.09-11.03-8.63-19.85-19.98-19.9-10.58-.05-19.13,9.11-19.05,20.26.08,10.32,8.21,19.72,19.66,19.73Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m415.12,751.21c-11.3.97-18.76,10.74-18.42,20.89.53,12.02,10.35,19.26,20.34,18.44,11.1-.91,19.04-10.79,18.11-22.19-.83-10.07-10.06-18-20.02-17.14Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m444.42,701.71c10.35.18,18.97-8.58,19.12-19.43.15-11.18-8.27-19.89-19.33-19.99-9.93-.09-18.53,8.86-18.68,19.43-.16,10.94,8.21,19.79,18.89,19.99Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m458.12,764.88c-11.85.64-20.61,12.76-17.71,24.52,2.27,9.2,9.41,14.19,17.6,14.24,10.75.07,19.28-8.88,19.36-19.81.09-11.15-8.44-19.54-19.26-18.95Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m487.31,677.14c-10.77-.23-19.15,8.49-19.14,20.85-.04,2.5,1.04,5.96,2.95,9.18,3.67,6.22,11.4,9.72,18.39,8.51,11.74-2.03,18.02-14.53,14.91-24.99-2.49-8.38-9.46-13.4-17.11-13.56Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m338.35,638.11c-1.17-.41-4.66,6.57-7.79,15.58-3.13,9.01-4.71,16.65-3.54,17.06,1.17.41,4.66-6.57,7.79-15.58,3.13-9.01,4.71-16.65,3.54-17.06Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m322.27,720.15c-1.19-.37-4.71,7.63-7.86,17.86-3.16,10.23-4.75,18.82-3.57,19.19s4.71-7.63,7.86-17.86c3.16-10.23,4.75-18.82,3.57-19.19Z"/>
        </g>
      </g>
    </svg>
  )
}