export const BigFlame55 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="266.38 688.23 311.34 827.14 436.85 852.24 556.46 751.54 488.82 631.03 374.49 570.93 266.38 688.23"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m267.66,368.31c8.07-31.73-33.03-37.33-49.23-56.61,4.91,46.66-39.15,72.75-53.36,112.5-8.65,23.09-1.73,57.84,25.37,72.53,11.86,8.61,23.49,17.48,33.13,28.66.46.53.91,1.45,1.94.69-27.97-86.13,24.47-87.19,42.15-157.77Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m326.15,484.77c1.06-9.31,3.31-17.04,6.36-25.59,2.99-9.42,1.07-13.55-8.32-16.8-16.54-5.13-32.04-8.33-49.82-11.24,24.9,36.57,28.46,12.76,51.78,53.63Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m87,326.89c-13.64-1.28-3.82,14.2-5.81,22.11,9.66-6.12,10.69-9.85,7.31-25.67-.88,1.32-.15,2.77-1.5,3.56Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m556.93,749.45c-6.99-12.16-13.96-24.33-20.91-36.51.36.55.72,1.11,1.08,1.66,12.57-40.48-11.14-80.53-40.88-106.59-28.7-27.99-67.18-34.44-106.23-30.54-4.48-2.38-8.95-4.79-13.38-7.24-2.58-1.36-1.81-1.42-3.68.61-2.37,2.58-4.73,5.16-7.1,7.74-16.69,0-31.89-2.89-46.45-11.48,2.25,16.45,7.8,30.12,19.14,41.24-3.31,3.61-6.62,7.21-9.93,10.82-33.16.73-79.27,5.71-89.89-33.95-19.09,62.86,10.04,178.56,63.9,220.1,2.21,6.88,4.43,13.77,6.64,20.65.27.84.46,1.58,1.62,1.81,41.69,8.38,83.5,17,125.16,25.41,40.82-31.68,80.02-67.24,120.29-100.21,1.78-1.5,1.78-1.49.61-3.52Zm-145.66-158.46c22.32,11.91,44.61,23.59,67.02,35.45,3.21,1.83,6.42,3.67,9.59,5.53,20.95,37.73,44.55,76.78,64.25,113.83-14.65-5.49-29.05-11.63-43.61-17.36-21.95-9.99-46.86-16.48-67.36-28.28-20.04-41.73-40.98-83.22-60.63-125.03,10.15,5.34,20.43,10.6,30.74,15.87Zm-141.74,99.29c-.28-.87-.47-1.55.31-2.39,32.13-34.77,64.14-69.63,96.2-104.46,1.84-2,3.73-3.97,5.55-6,.73-.81,1.09-1.04,1.68.2,19.71,41.36,39.5,82.68,59.23,124.02,1.18,2.48,1.2,2.48-.72,4.31-40.06,37.9-79.87,76.06-120.09,113.79-14.1-43.21-28.07-86.29-42.15-129.48Zm280.21,64.61c-37.27,31.43-74.57,62.83-111.82,94.29-.8.67-1.54.73-2.45.54-16.29-3.4-32.57-6.79-48.86-10.18-23.27-4.77-45.83-9.79-69.27-14.23,40.52-38.02,80.9-76.04,121.37-114.09,36.63,9.61,73.93,28.74,110.74,41.49,1.93.75,1.93.79.29,2.18Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m328.65,739.46c-9.64,2.84-14.03,13.88-14.32,23.17-.54,7.76,5.34,17.59,13.94,13.34,7.87-4.23,11.89-13.32,12.13-21.94.65-6.73-3.51-16.55-11.75-14.57Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m345.63,645.4c.98,4.4,4.64,7.5,8.77,7.23,10.92-1.83,16.28-13.55,17.03-23.56-2.13-27.58-31.26-3.85-25.81,16.33Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m431.23,763.97c9.02-.7,17.16-4.19,23.45-11.48,5.16-5.33,3.94-14.2-5.01-14.49-18.71-3.31-44.81,23.91-18.44,25.97Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m486.6,756.5c1.52-5.56-4.87-8.36-9.51-8.04-8.05.41-15,3.46-20.94,8.85-8.3,7.08-6.65,16.75,5.36,15.95,10.11-1.05,21.82-6.42,25.08-16.76Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m395.69,803.99c2.52-6.93-4.77-9.59-11.02-9.23-6.49.31-13.28,2.74-19.19,7.46-4.82,3.26-9.21,11.72-1.88,14.78,10.68,3.9,27.29-2.64,32.08-13.02Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m502.81,758.48c-9.18,1.38-18.96,6.22-22.96,14.99-2.39,5.67,3.27,8.92,8.3,8.54,9.05-.72,16.65-4.39,22.68-11.23,6.5-7.63.77-13.62-8.03-12.3Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m424.42,813.76c3.92-5.47,1.09-10.46-5.5-10.78-10.57-.31-21.77,4.14-27,13.66-2.63,5.88,3.79,8.9,8.57,8.64,9.36-.44,18.23-4.09,23.92-11.51Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m450.08,810.94c-15.65-4.74-43.72,18.97-20.65,20.91,5.9-.29,11.43-2.18,16.49-5.48,5.2-2.73,13.11-12.28,4.16-15.43Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m466.21,695.74c6.3.25,3.05-8.15,1.02-11.18-5.25-8.18-12.47-16.55-22.23-19.05-2.78-.47-4.22.76-4.2,3.54,1.45,10.51,15,24.74,25.41,26.68Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m421,607.7c-4.87-3.95-15.09-7.16-10.48,3.13,3.82,7.58,9.65,13.32,16.62,17.99,2.9,1.92,10.08,5.63,10.02-.72-1.94-8.39-9.42-15.33-16.16-20.4Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m516.57,717.37c2.33,1.61,7.83,3.27,7.63-1.27-2.08-10.13-10.1-17.82-18.35-23.51-6.14-4.08-10.25-1.29-6.68,5.63,4,8.01,9.83,14.38,17.4,19.15Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m481.33,654.97c2.23,1.68,8.96,5.7,9.27.63-1.86-9.04-9.51-15.89-16.7-21.13-4.74-3.59-11.1-3.96-7.37,3.75,3.48,6.86,8.64,12.26,14.8,16.76Z"/>
        </g>
      </g>
    </svg>
  )
}