import { moneyFormatter } from "../../../../utils/moneyFormatter"
import { Ticket } from "../../assets/jackpot/Ticket"

export const BarDiceControlScreen = (props) => {
  return (
    <div className="gradientBg">
      <div className="blackBg">
        <Ticket 
          tickets={props.tickets} 
          setModalOpen={props.setModalOpen}
          entries={props.i18n.entries}
        />
        <div className={`barDiceScreen ${props.cashedOut ? "starBg" : ""}`}>
          <div className={`chartText`}>
            {props.cashedOut ? (<>
              <div className="green">You Won</div>
              <div className="green">
                {moneyFormatter(props.bet*props.guess, props.currency, true)}!
              </div>
            </>) : props.bet ? (<>
              <div className="slightlySmallerText">
                <div>Bet: {moneyFormatter(props.bet, props.currency, true)}</div>
                <div>Guess: {props.guess}</div>
                <div>Win: <span className="payout">
                    {moneyFormatter(props.bet*props.guess, props.currency, true)}
                  </span>
                </div>
              </div>
            </>) : props.phase === "preGame" || props.timeRemaining > 0 ? (<>
              <div>Next roll</div>
              <div className="smallGap">in {props.timeRemaining}s</div>
            </>) : (<>
              <div>Wait for</div>
              <div className="smallGap">next roll</div>
            </>)
            }
          </div>
        </div>
      </div>
    </div>
  )
}