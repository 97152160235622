export const NoFlame57 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="303.01 825.27 417.02 838.76 550.31 771.87 498.36 626.66 397.03 575.5 269.13 665.02 303.01 825.27"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m416.79,839.42c-37.29-4.07-74.4-8.2-111.67-12.29-2.93-.32-2.93-.28-3.54-3.12-11.42-52.54-22.79-105.08-34.24-157.61-.28-1.27.03-1.95,1.07-2.69,42.16-29.79,84.35-59.55,126.51-89.34,1.15-.81,1.99-.88,3.24-.24,15.54,7.92,31.15,15.69,46.64,23.69,17.27,9.02,36.95,19.06,54.3,27.94,18.32,48.71,32.94,96.89,51.5,145.42.52,1.28.18,1.8-.99,2.38-44.33,21.74-88.4,44.57-132.82,65.85Zm-14.86-259.37c-.22-.31-.42,0-.16.16,15.83,53.14,32.67,106.01,48.83,159.08.32,1.06.79,1.61,1.91,1.92,30.93,8.53,61.84,17.12,92.75,25.7.44.12.98.46,1.33.1.37-.39-.1-.86-.25-1.28-16.16-45.68-32.42-91.32-48.59-136.99-30.52-17.78-64.14-32.32-95.83-48.69Zm-130.51,87.09c11.13,50.35,22.06,100.76,33.15,151.12.24,1.14.6,1.28,1.54.71,17.08-9.57,34.18-19.12,51.27-28.69,27.83-15.58,55.66-31.17,83.51-46.71,1.32-.74,1.71-1.37,1.23-2.91-16.15-52.68-31.98-105.47-48-158.19-.51-1.7-.51-1.68-1.99-.64-39.95,28.61-81.58,56.2-120.71,85.32Zm272.53,107.01c-32.53-7.24-65.41-20-97.91-24.24-45.1,25.14-90.46,49.81-135.54,74.97,34,4.65,68.22,7.83,102.31,11.91,2.48.26,4.87.81,7.47-.5,41.11-20.91,82.78-40.98,123.67-62.15Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m401.77,580.22c-.26-.16-.06-.48.16-.16-.05.06-.11.11-.16.16Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m386.52,636.2c-.23,8.18-3.19,15.09-9.83,20.1-7.28,6.2-18.28,1.61-19.39-7.76-5.01-21.44,26.11-41.92,29.22-12.33Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m312.56,764.04c.21-7.13,2.42-13.43,7.85-18.33,20.21-14.44,26.88,21.51,12.71,31.3-10.85,9.5-21.18-1.81-20.57-12.98Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m477.08,718.42c.32,3.5-1.91,7.88-6,6.65-10.51-3.73-16.33-15.19-18.74-25.46-1.26-5.26.85-12.47,7.67-9.19,9.77,5.58,16.13,16.89,17.07,28Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m451.52,639.9c.25,2.89-1.69,6.2-4.96,5.43-10.48-3.4-17.16-15.36-19.41-25.66-1.53-14.17,12.13-5.23,16.19,1.06,4.28,5.57,7.91,11.97,8.18,19.17Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m502.2,713.14c-.41-4.09,2.4-7.2,6.42-4.93,8.74,5.28,13.88,15.43,15.45,25.29.94,10.97-8.69,6.43-12.68.83-5.02-6.06-8.69-13.22-9.28-21.19.03,0,.06,0,.09,0Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m476.98,640.04c-.05-2.41,1.22-5.46,4.15-4.71,9.6,3.53,15.47,14.95,17.39,24.57,1.29,5.22-3.01,8.25-7.25,4.67-7.78-5.78-13.14-14.94-14.3-24.53Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m449.93,766.49c3.75-.3,10.91,1.53,6.98,6.35-7.18,7.32-18.01,9.94-27.94,10.83-3.94.55-12.35-1.38-7.71-6.58,7.51-7.28,18.48-9.98,28.67-10.6Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m454.36,788.87c-10.09.28-8.62-5.66-1.28-9.54,8.57-4.43,18.51-8.16,28.25-5.9,4.54,1.54.7,6.09-1.88,7.56-6.41,4.47-17.19,7.87-25.09,7.88Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m381.01,804.22c14.21.28,6.05,8.42-1.94,11.14-5.89,2.44-12.04,3.84-18.42,4.12-3.45.29-11.5-1.13-7.15-5.86,7.39-6.62,17.85-8.78,27.51-9.4Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m499.99,778.34c9.72-.24,8,5.09,1.17,8.8-7.21,3.98-14.87,6.42-23.17,6.63-7.02.22-7.81-4.16-2.07-7.66,7.33-4.73,15.41-7.17,24.07-7.77Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m408.68,808.58c8.88-.33,9.11,4.74,1.74,8.42-7.25,3.78-14.94,5.98-23.16,6.03-2.66.21-8.56-1.16-5.61-4.75,7-6.89,17.53-8.99,27.03-9.7Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m434.02,812.59c12.87-.03,4.07,7.43-2.35,9.63-3.06,1.97-27.89,8.45-23.76-.07,6.79-6.7,16.89-8.74,26.11-9.56Z"/>
        </g>
      </g>
    </svg>
  )
}