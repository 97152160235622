export const SmallFlame13 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="320.58 625.5 477.97 581.71 559.35 739.1 479.77 793.11 346.21 850.82 278.16 699.91 320.58 625.5"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m562.1,737.69c-7.89-15.05-15.69-30.22-23.47-45.41,1.28-35.68-23.68-82.05-50.84-98.64-2.66-5.02-5.31-10.05-7.99-15.04-4.29,1.18-8.58,2.38-12.87,3.56-58.9-27.38-40.73-22.2-104.27-15.01-29.42-2.07-59.5-6.46-88.69-1.02-28.39,33.22-60.82,83.15-46.12,129.95-1.5.82-2.17-1.78-3.74-.55-3.99,68.17,53.88,120.31,114.51,140.36,1.94,4.34,3.88,8.68,5.82,13.02,1.08,2.42,1.05,2.42,3.48,1.38,8.74-3.77,17.47-7.54,26.21-11.31-1.87.86-3.75,1.72-5.61,2.59,56.84,8.13,116.2-23.01,146.87-70.84,15.34-10.29,30.54-20.83,46.12-30.69,1.06-.7,1.18-1.22.59-2.34Zm-283.7-35.59c.06-.1.11-.21.17-.31,4.06,9.05,8.11,18.1,12.16,27.14-4.11-8.94-8.22-17.88-12.33-26.83Zm67.79,142.49c-1.12.91-1.5.79-2.06-.47-21.21-47.39-42.39-94.8-63.68-142.16,10.85-23.94,26.9-47.85,39.98-70.99,8.43,16.35,15.8,33.89,23.88,50.63,18.52,39.78,37.19,79.5,55.61,119.33-17.17,15.06-36.05,28.93-53.73,43.67Zm148.55-61.4c-10.54,6.21-21.41,11.84-32.47,17.14-36.45,15.45-72.69,32.36-109.24,46.72,17.79-13.84,35.72-27.35,53.39-41.34,2.39-1.86,5.33-2.61,8.04-3.78,46.08-19.23,92.19-38.66,138.3-57.65-17.34,12.44-38.38,25.88-58.03,38.92Zm59.69-46.53c-48.94,20.17-97.84,40.43-146.77,60.61-23.87-47.15-45.54-97-68.61-145.06-3.75-8.01-7.49-16.03-11.3-24.02-.59-1.24-.65-1.75.93-2.2,47.45-13.45,101.71-28.54,148.82-42.24,1.06,2.07,2.15,4.17,3.26,6.3-.05-.02-.1-.04-.15-.06,16.11,30.91,32.18,61.74,48.25,92.57,8.84,17.21,17.68,34.42,26.52,51.64.85,1.66,1.33,1.53-.94,2.46Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m426.98,679.93c-.85,20.18,33.27,31.74,36.28,7.52,1.95-21.75-34.42-33.45-36.28-7.52Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m361.36,753.81c-4.83-1.97-6.19,4.36-5.96,7.91.57,9.01,3.54,17.15,9.04,24.32,3.9,5.59,10.66,7.04,11.02-1.56-.02-11.14-4.36-24.57-14.1-30.67Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m306.23,709.93c-3.97-6.47-9.5-6.99-9.26,1.87.55,8.32,3.07,15.98,7.92,22.81,4.62,6.58,9.03,3.89,8.48-3.49-.6-7.65-2.91-14.75-7.14-21.19Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m412,809.23c3.9,1.24,34.07-12.95,35.08-16.57-5.05-.91-31.35,12.3-35.08,16.57Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m519.42,761.93c-4.69-.44-30.98,12.23-33.24,16.27,6.1-.17,29.47-11.59,33.24-16.27Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m382.81,831.64c11.57-3.57,22.38-8.73,32.48-15.4-3.9-.85-30.36,11.71-32.48,15.4Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m482.14,786.95c-5.6,1-28.1,10.99-30.41,15.45,11.03-3.9,21.2-8.68,30.7-14.83-.1-.2-.19-.41-.29-.61Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m306.69,452.8c-13.16-36.98-22.91-50.28-64.33-50.61,8.33,28.34,43.28,33.82,64.33,50.61Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m339.55,418.97c-1.85-10.97-1.59-30.29-16.19-29.53,5.03,10.13,3.97,22.75,16.19,29.53Z"/>
        </g>
      </g>
    </svg>
  )
}