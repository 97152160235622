export const NoFlame34 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="446.72 853.95 526.78 777.81 524.37 620.39 377.59 605.76 275.8 650.62 294.84 815.69 446.72 853.95"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m527.52,777.6c.02,1.15-.32,1.95-1.14,2.75-26.89,24.75-51.79,54.44-79.7,76.79-50.31-12.77-100.65-25.43-150.99-38.08-1.17-.29-1.56-.76-1.71-1.95-1.58-13.04-3.24-26.07-4.87-39.1-5.27-42-10.37-84.03-15.75-126.02-.2-1.28.19-1.88,1.35-2.37,18.5-7.88,79.95-33.32,98.64-42.72,3.41-1.72,4.8-1.5,8.17-1.21,30.57,2.62,112.93,10.62,143.44,13.58,2.41,25.44,2.39,148.28,2.56,158.34Zm-89.04-11.07c-1.44-30.22-3.15-57.41-4.62-87.18-.06-1.88-.8-2.28-2.43-2.5-14.38-1.88-28.75-3.81-43.11-5.76-36.19-4.99-72.39-9.84-108.59-14.78-1.47-.2-1.57.24-1.42,1.42,4.53,36.81,9.14,73.6,13.57,110.42,1.86,15.21,3.75,30.42,5.6,45.63.13,1.11.57,1.58,1.68,1.85,44.72,11.21,89.56,21.94,134.15,33.64,2.17.34,9.51,3.5,9.7,1.02-1.4-27.06-3.08-57.71-4.52-83.76Zm12.26,82.81c24.65-24.37,52.24-48.18,74.72-74.08-1-49.74-1.02-99.85-2.09-149.42-26.71,15.71-53.23,33.01-79.9,49.2-3.05,2.86-.19,8.52-.76,12.39,2.74,53.97,5.29,107.95,8.04,161.92Zm67.46-228.86c-.03-.13-.05-.26-.08-.39-46.02-4.3-93.51-8.56-139.54-12.83-32.4,13.82-64,28.18-96.8,41.79.02.13.04.26.05.39,50.13,6.47,100.24,13.1,150.38,19.55,2,.25,3.94.85,6.05-.42,26.6-16.1,53.3-32.05,79.94-48.09Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m385.15,797.28c.11-7.07,3.66-11.72,9.89-12.76,3.3-.55,6.48.12,9.45,1.61,7.17,3.6,11.76,9.36,13.51,17.14.85,3.8.77,7.69-1.41,11.24-2.62,4.26-6.58,5.93-11.34,5.5-10.52-.94-20.27-12.08-20.1-22.74Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m375.79,755.72c.06,7.39-2.75,12.15-7.51,13.94-4.39,1.65-8.63.69-12.46-1.63-7.72-4.68-12.06-11.64-12.22-20.68-.13-7.25,3.98-11.99,9.83-12.85,4.31-.64,8.26.67,11.7,3.16,6.66,4.83,10.33,11.37,10.66,18.06Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m333.7,708.29c.12,2.91-.61,6.04-2.66,8.73-3.08,4.06-8.26,5.43-13.08,3.72-5.23-1.86-9.05-5.38-11.82-10.05-3.06-5.16-4.47-10.73-2.99-16.7,1.85-7.44,8.39-10.79,16.03-7.99,8.55,3.13,14.66,13.33,14.51,22.29Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m475.84,699.95c-.24,7.59-1.49,14.1-4.95,20.04-.95,1.63-2.1,3.13-3.65,4.26-2.59,1.88-5.39,1.4-7.24-1.2-1.53-2.15-2.29-4.6-2.58-7.16-1.06-9.3.2-18.14,5.45-26.13.81-1.23,1.88-2.22,3.13-3.02,2.48-1.59,4.95-1.16,6.71,1.26,1.59,2.17,2.29,4.7,2.68,7.29.27,1.8.54,3.63.44,4.66Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m478.67,785.49c-.2,6.04-1.69,12.71-5.31,18.79-1.16,1.95-2.6,3.68-4.66,4.74-2.09,1.07-3.98.57-5.37-1.33-1.11-1.51-1.73-3.24-2.01-5.06-1.4-9.28.31-17.98,5.03-26.08.74-1.27,1.7-2.39,2.82-3.34,2.91-2.49,6.04-1.9,7.74,1.53,1.53,3.08,1.78,6.41,1.76,10.75Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m497.13,726.53c-.27,7.05-1.44,13.58-4.87,19.54-.76,1.32-1.71,2.5-2.86,3.51-2.58,2.24-5.22,1.83-6.93-1.12-2.02-3.49-2.32-7.36-2.21-11.24.14-5.21,1.07-10.29,3.14-15.11,1.16-2.71,2.52-5.3,4.96-7.12,2.31-1.74,4.56-1.38,6.22.98,1.36,1.94,1.85,4.2,2.19,6.48.22,1.44.27,2.91.37,4.09Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m514.48,672.49c-.08,6.62-1.17,13.01-4.69,18.76-.49.8-1.09,1.54-1.73,2.21-2.42,2.51-5.2,2.15-6.98-.86-1.14-1.93-1.7-4.06-1.94-6.25-.88-8.03.18-15.74,3.98-22.96.81-1.54,1.85-2.89,3.36-3.85,2.08-1.32,3.86-1.07,5.32.92,1.94,2.64,2.41,5.74,2.67,8.89.09,1.04.01,2.09.01,3.14Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m515.83,750.75c-.21,7.11-1.58,13.89-5.65,19.92-.63.93-1.48,1.64-2.37,2.26-1.85,1.3-3.8.79-5.05-1.28-1.72-2.84-2.05-6.05-2.04-9.23.03-5.74,1.06-11.33,3.38-16.64.85-1.93,1.91-3.72,3.36-5.26,2.57-2.72,5.4-2.27,6.94,1.14,1.31,2.89,1.46,5.96,1.42,9.08Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m423.21,645.15c3.36-.05,7.16.23,10.88,1.27.7.2,1.42.4,2.07.71,1.22.58,2.64,1.11,2.76,2.71.12,1.62-1.2,2.29-2.39,2.99-3.5,2.08-7.41,2.82-11.35,3.39-6.72.98-13.45,1.09-20.12-.42-1.38-.31-2.72-.78-3.94-1.51-2.08-1.25-2.2-2.84-.31-4.37,1.87-1.52,4.14-2.23,6.41-2.86,5.08-1.39,10.27-1.96,16-1.92Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m384.99,640.73c2.66-.08,6.09.21,9.46,1.04,1.42.35,2.82.82,3.91,1.88.97.94.96,1.91.07,2.89-.94,1.03-2.1,1.76-3.38,2.22-9.47,3.35-19.16,4.09-29.03,2.29-1.34-.24-2.62-.71-3.81-1.41-1.92-1.12-2.03-2.72-.26-4.1,1.76-1.38,3.85-2.09,5.96-2.7,5.31-1.53,10.75-2.13,17.08-2.1Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m337.72,647.3c-3.21.04-6.38-.26-9.5-.98-.96-.22-1.93-.54-2.8-1.01-1.95-1.04-2.1-2.57-.36-3.94,1.92-1.51,4.22-2.29,6.57-2.87,8.21-2.04,16.48-2.83,24.86-1.18,1.34.26,2.65.66,3.79,1.45,1.29.89,1.39,2.03.28,3.15-1.52,1.54-3.51,2.26-5.51,2.88-5.64,1.75-11.45,2.41-17.33,2.5Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m465.35,622.17c4.26-.04,8.28.16,12.18,1.32,1.05.31,2.1.67,2.95,1.42,1.1.99,1.12,1.74.05,2.75-1.05.99-2.33,1.62-3.68,2.02-8.89,2.63-17.89,3.01-26.98,1.23-1.28-.25-2.54-.67-3.64-1.41-1.64-1.11-1.68-2.31-.1-3.49,1.53-1.15,3.34-1.72,5.16-2.2,4.68-1.24,9.46-1.71,14.07-1.64Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m422.75,628.12c-3.98-.14-7.86-.24-11.58-1.48-1.23-.41-2.79-.76-2.88-2.28-.1-1.67,1.46-2.31,2.75-2.84,3.56-1.48,7.31-2.18,11.12-2.58,5.91-.61,11.81-.7,17.63.77,1.07.27,2.11.66,3,1.33.99.74,1.07,1.62.23,2.54-.91,1-2.14,1.5-3.37,1.94-5.5,1.97-11.23,2.39-16.89,2.6Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m394.08,615.2c3.34.12,6.76.04,10.06,1.03.6.18,1.19.4,1.77.62.85.33,1.73.81,1.76,1.79.03.96-.79,1.54-1.58,1.99-3.2,1.84-6.77,2.48-10.34,3-5.72.83-11.46,1.05-17.2.08-1.34-.23-2.66-.55-3.89-1.17-.77-.39-1.59-.79-1.56-1.77.03-.92.76-1.43,1.52-1.86,2.17-1.22,4.52-1.89,6.94-2.42,4.11-.9,8.27-1.21,12.52-1.28Z"/>
        </g>
      </g>
    </svg>
  )
}