export const BigFlame49 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="282.27 750.43 313.61 804.42 481.6 852.45 550.66 683.69 384.23 620.34 338.75 603.4 282.27 750.43"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m67.42,354.35c7.67,18.81,26.1,29.29,37.21,45.56,6.17,9.51,10.87,19.76,15.61,30.12,1.49-31.91-15.58-76.77-52.82-75.68Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m552.04,683.22c-3.63-1.43-7.25-2.87-10.88-4.3,7.89-39.58-1.62-84.69-31.46-113.6-5.3-5.13-9.86-10.77-13.87-16.92-12.38-19-22.61-39.17-32.62-59.47-.75-1.52-.8-3.89-3.77-4.31,2.57,8.1,13.12,60.13-4.84,51.67-4.9-2.22-8.16-6.06-10.84-10.64-9.42-16.09-19.68-31.64-31.53-46.06-27.54-40.64-68.5-29.67-79.91-62.63-2.51-7.31-5.32-14.52-8.26-22.49-8.94,20.26-12.27,44.35,3.36,61.84,12.93,13.91,35.69,18.92,32.47,42.45-21.7-12.03-45.52-12.86-69.3-14.46-58.52-2.35-120.94-16.96-142.41-77.72-10.91,46.22,17.98,92.38,59.81,111.22,9.29,4.47,16.7,10.75,22.29,19.33,19.01,32.55,22.09,76.72,18.28,113.46-4.18,15.74-8.09,31.4-7.96,48.91-6.62-8.16-11.23-16.78-20.37-20.69,11.4,19.01,17.5,40.42,21.55,61.96,10.78,70.47,102.05,101.6,165.34,92.19,24.85,7.23,49.7,14.46,74.54,21.72,1.32.39,1.85.15,2.39-1.17,11.56-28.41,23.18-56.79,34.78-85.19,11.29-27.62,22.57-55.23,33.87-82.85.38-.94.88-1.66-.65-2.26Zm-238.86,114.75c-.73.08-.88-.79-1.19-1.29-5.35-8.51-10.67-17.04-16-25.56-4.1-6.56-8.17-13.13-12.29-19.67-.48-.76-.49-1.36-.17-2.18,10.91-28.5,21.79-57.01,32.67-85.52,7.41-19.41,14.83-38.83,22.25-58.24.2-.51.22-1.16.97-1.33.28.11.57.23.86.35,13.31,5.64,26.61,11.28,39.93,16.89,1.09.46,1.21.98.81,2.03-11.17,28.79-22.32,57.59-33.46,86.4-11.19,28.9-22.37,57.8-33.56,86.7-.2.51-.18,1.34-.8,1.41Zm233.13-109.5c-10.92,26.75-21.84,53.5-32.75,80.26-10.7,26.22-21.4,52.44-32.06,78.68-.53,1.31-1.06,1.55-2.4,1.16-31.48-9.29-62.98-18.54-94.47-27.79-20.95-6.15-41.9-12.29-62.84-18.46-2.32-.68-2.25-.21-1.31-2.64,22.4-57.73,44.81-115.46,67.18-173.2.49-1.28.93-1.68,2.34-1.12,51.6,20.3,103.22,40.56,154.83,60.83.69.27,1.5.38,2,1.01-.21.5-.37.89-.53,1.28Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m412.66,704.04c.83-.1,1.67-.14,2.49-.31,11.01-2.25,18.27-12.65,16.59-23.76-1.61-10.7-11.74-17.46-22.28-15.03-9.71,2.24-16.83,12.24-15.81,22.66.93,9.58,9.4,17.03,19.01,16.45Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m380.16,752.76c-10.65-.09-19.78,7.87-21.03,18.8-1.05,9.22,4.38,17.69,14.88,19.39,11.95,1.94,23.54-8.29,23.53-20.55,0-9.94-7.46-17.56-17.38-17.64Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m457.15,718.08c8.73-2.21,15.3-10.82,15.33-19.79-.06-1.06-.1-1.79-.15-2.52-.68-9.87-10.19-17.26-19.93-15.5-10.75,1.94-18.23,12.23-16.82,23.13,1.38,10.69,11.13,17.32,21.57,14.68Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m421.26,766.32c-10.73.19-20.03,9.74-20.05,20.62-.14,11.01,9.36,18.33,19.66,16.65,8.76-1.43,15.53-8.2,17.2-17.13,2.01-10.82-5.92-20.33-16.8-20.14Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m490.2,695.68c-10.27,2.94-16.74,14.39-14.05,24.75,2.35,9.05,11.31,14.18,20.31,11.65,8.9-2.51,14.66-11.05,14.83-19.83.02-12.25-10.55-19.58-21.09-16.56Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m460.41,779.45c-10.23.88-18.65,9.92-18.82,20.56.05.52.11,1.4.23,2.28,1.25,9.15,8.91,14.57,17.46,13.6,9.36-1.06,16.48-8.14,18.02-17.49,1.76-10.66-6.44-19.85-16.89-18.95Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m324.81,726.34c-1.34.81-2.26,2.03-3.09,3.3-4.73,7.22-7.33,15.24-8.65,23.71-.33,2.12-.52,4.26-.04,6.4.22,1,.51,2.05,1.52,2.41,1.04.37,1.91-.34,2.65-1.02.46-.42.89-.89,1.27-1.38,2.71-3.46,4.5-7.42,6.03-11.5,2.02-5.36,3.55-10.84,3.73-16.61,0-1.42.03-2.83-.42-4.2-.55-1.64-1.52-2-3.01-1.1Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m340.52,631.08c-.43-1.41-1.19-1.71-2.47-.98-.88.51-1.54,1.25-2.13,2.06-4.72,6.58-7.39,14.02-9.04,21.86-.49,2.35-.95,4.73-.28,7.14.41,1.48,1.36,1.83,2.58.91.96-.72,1.78-1.61,2.48-2.6,2.08-2.92,3.65-6.1,4.99-9.4,2.14-5.25,3.79-10.63,4.04-16.34,0-.68.01-1.36,0-2.04,0-.2-.09-.4-.16-.6Z"/>
        </g>
      </g>
    </svg>
  )
}