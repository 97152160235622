import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Router } from "react-router-dom";
import { AuthProvider } from "react-auth-kit";
// Attempts to lock the screen to portrait view.
// const lock = window?.screen?.orientation?.lock;
// if (lock) lock("portrait");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const baseUrl = window?.location?.href?.match(/\/[^.]{2,4}.[^.]+.com/)
const local = window.location.href.includes("localhost")
if (!local && !window.location.href.includes("https")) {
  window.location.assign("https"+window.location.href.slice(4))
}

// Dev tools warning message
const msg = `Caution: This browser feature is designed for developers. 
If you're instructed to copy and paste something here to enable a ${local && baseUrl ? baseUrl[0] + " " : ""}feature or "hack", 
it's a scam that could grant hackers access to your account.`;
console.log(`%c${msg}`,"font: 2em sans-serif; color: yellow; background-color: red;");

root.render(
  <BrowserRouter basename={local && baseUrl ? baseUrl[0] : ""}>
    <AuthProvider
      authType={"cookie"}
      authName={"_auth"}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"}
    >
      <App/>
    </AuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
