import { useEffect, useRef, useState } from "react";
import { Die } from "../../assets/cube/Die";

export const BarDiceChart = (props) => {
  const x = useRef(0)
  const y = useRef(0)

  // CHART OBJECT
  const [chartH, setChartH] = useState(0)
  const [chartW, setChartW] = useState(0)
  const [canvasRatio, setCanvasRatio] = useState(0)
  const [canvasH, setCanvasH] = useState(0)
  const [canvasW, setCanvasW] = useState(0)
  const [graphElement, setGraphElement] = useState();
  const [yElement, setYElement] = useState();
  const [xElement, setXElement] = useState();
  const [graph, setGraph] = useState();
  const [yAxis, setYAxis] = useState();
  const [xAxis, setXAxis] = useState();
  // const [yLabels, setYLabels] = useState([]);
  // const [xLabels, setXLabels] = useState([]);

  // CHART SETUP
  useEffect(()=>{
    setChartH(document.documentElement.querySelector(".barDiceChart").clientHeight)
    setChartW(document.documentElement.querySelector(".barDiceChart").clientWidth)
  }, [graphElement, yElement, xElement])

  // CALCULATE POSITION
  useEffect(()=>{
    setCanvasRatio((chartH*0.9)/(chartW-chartH*0.1))
    setCanvasH(chartH*0.9)
    setCanvasW(chartW-chartH*0.1)
    setGraphElement(document.getElementById("graph"));
    setYElement(document.getElementById("y-axis"));
    setXElement(document.getElementById("x-axis"));
  }, [chartH, chartW])

  useEffect(()=>{
    setGraph(graphElement?.getContext("2d"));
    setYAxis(yElement?.getContext("2d"));
    setXAxis(xElement?.getContext("2d"));
  }, [graphElement, yElement, xElement])

  useEffect(()=>{
    if (canvasW && canvasH) {
      x.current = canvasW*(0.90-0.91**props.timeElapsed)
      y.current = canvasH*(1-0.85*props.multiplier/100)
    }
  }, [props.multiplier])

  return (
    <div className="barDiceChart">
      <div className={props.phase+" chartText"}>
        {props.phase==="preGame" ? (<>
          <div>{props.i18nNextRoll}</div>
          <div>{props.i18nIn}{props.timeRemaining}s</div>
        </>) : props.phase === "inGame" ? (
          <div>{props.multiplier}</div>
        ) : (
          <div className={
            parseFloat(props.multiplier) > 2 
            ? "yellow"
            : "red"
          }>
            <div>{props.i18nRolled}</div>
            <div>{props.busted}</div>
          </div>
        )}
      </div>
      <canvas id="y-axis" style={{
        height: chartH*0.9, 
        width: chartH*0.1,
      }}/>
      <canvas id="graph"
        height={chartH*0.9}
        width={chartW-chartH*0.1}
        style={{
          // height: chartH*0.9, 
          // width: chartW-chartH*0.1, 
          left: chartH*0.1
        }}
      />
      <canvas id="x-axis" style={{
        // height: chartH*0.1, 
        // width: chartW-chartH*0.1,
        top: chartH*0.9,
        left: chartH*0.1
      }}/>
      <Die
        timeElapsed={props.timeElapsed}
        multiplier={props.multiplier}
        phase={props.phase}
        x={x.current*0.9}
        y={y.current}
        w={chartH*0.5}
        h={chartH*0.5}
        z={chartH*0.35}
        color={"#ECAA1F"}
      />
    </div>
  )
}