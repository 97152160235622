export const BigFlame06 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="298.97 620.77 413.55 587.85 549.75 657.33 501.67 799.04 402.83 866 274.56 773.02 298.97 620.77"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m232.64,468.55c.05.02.1.05.16.07-.03-.05-.06-.09-.09-.14l-.07.07Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m163.01,385.37c-52.87,87.78,15.15,60.22,24.26,136.57,9.25-21.83,23.39-50.82,6.55-72.22-16.45-18.65-23.35-40.43-30.81-64.35Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m232.71,468.48c-.12-.07-.24-.13-.36-.2.1.09.2.17.29.26,0,0,.07-.07.07-.07Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m551.65,657.12c-.73-.38-1.46-.76-2.19-1.13-.98-6.48-2.41-12.89-4.35-19.16-4.71-25.04-13.54-49.08-31.48-68.04-.81,6.41,2.61,12.49-2.26,17.6-7.01.83-10.64-4.68-15.32-8.03-1.3.72.11,2.36-1.25,2.99-25.49-33.82-34.38-92.73-85.22-99.4-20.33-3.05-34.99-16.5-50.26-29.89,11.92,27.57,26.81,52.27,43.21,76.26-12.32-2.18-24.28-7.44-36.92-9.39-46.89-9.8-89.89-30.42-132.83-50.3,24.43,38.52,39.34,83.23,32.34,129.29-24.55-6.93-52.98-14.55-59.1-42.68-.6,56.64,5.79,114.04,28.62,165.64-5.21,70.08,66.09,118.67,125.81,116.86,13.77,9.84,27.54,19.68,41.31,29.52,1.15.82,1.91.93,3.13.08,32.36-22.51,64.8-44.91,97.23-67.33,3.86-10.21,7.74-20.86,11.6-31.74,28.06-27.31,40.68-67.88,36.43-106.79.5-1.46.99-2.91,1.48-4.35Zm-138.47-68.25c44.41,19.81,87.61,45.27,131.42,67.24-32.06,15.56-64.19,30.96-96.22,46.57-1.34.68-2.36.7-3.72-.1-46.65-27.47-93.5-54.56-140.13-82.05,36.09-10.9,72.45-21.11,108.66-31.66Zm-12.97,272.3c-41.19-29.15-82.22-58.45-123.41-87.6,4.46-48.1,15.84-98.3,22.93-147,46.83,27.62,93.52,55.38,140.36,82.98,1.07.58,1.28,1.2.95,2.38-13.7,49.73-27.11,99.51-40.82,149.24Zm100.98-63.61s-61.91,41.61-93.17,64.16c15.02-49.89,42.69-150.5,42.69-150.5,31.71-15.69,63.87-32.04,95.61-47.72-7.62,23.11-15.19,46.06-22.73,68.93-7.63,21.87-15.25,43.72-22.4,65.12Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m396.72,710.35c-10.62-6.27-20.57,5.42-18.08,15.9,1.52,20.14,30.88,32.81,31.51,5.43-1.05-9.26-5.02-16.68-13.44-21.34Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m311,743.18c-5.44-2.47-10.59-.35-12.54,5.24-6.91,16.54,21.4,44.52,25.92,16.97-.42-9.01-4.98-18.24-13.38-22.21Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m445.61,639.71c-6.99-7.73-17.74-10.1-27.8-9.5-8.18,1-10.35,5.83-3.36,11.08,6.36,4.59,13.65,6.37,22.68,6.83,3.77-.52,13.03-2.01,8.48-8.4Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m471.71,719.81c-9.78,5.16-15.46,15.37-18.1,25.77-1.42,7.3,1.31,11.72,8.5,6.8,8.48-6.67,14.58-16.64,15.58-27.51.3-3.61-2.2-6.92-5.98-5.06Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m450.27,794.87c-7.88,3.83-12.82,11.64-16.35,19.36-2.76,5.09-4.19,17.09,5.03,11.48,8.6-6.13,15.2-16,16.43-26.56.27-3.37-1.68-5.78-5.1-4.28Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m525.25,698.55c.19-2.92-1.21-6.09-4.53-4.64-9.39,5.12-14.52,15.39-16.82,25.51-1.22,17.08,15.44-2.3,17.44-8.35,2.06-3.97,3.64-8.11,3.91-12.52Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m498.12,764.77c-8,4.26-12.61,12.67-15.67,20.86-2.36,7.12-.68,12.81,6.85,6.43,6.61-6.14,11.93-14.5,12.93-23.6.29-2.7-1.27-5-4.1-3.69Z"/>
        </g>
      </g>
    </svg>
  )
}