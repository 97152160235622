export const TerminalLogo = (props) => {
  return (
    <svg 
      width={props?.w ?? "406"}
      height={props?.h ?? "57"}
      viewBox={props?.v ?? "0 0 406 57"}
      fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_4697_146)">
        <path d="M284.295 46.2635C283.071 46.2635 282.459 45.6743 282.459 44.4959V38.162C282.459 36.9836 283.071 36.3944 284.295 36.3944H289.575V17.5401C289.218 18.2766 288.478 19.0622 287.356 19.8969C286.285 20.6825 285.239 21.3699 284.219 21.9591C283.607 22.3519 283.071 22.5728 282.612 22.6219C282.204 22.6219 282 22.0327 282 20.8543V14.0049C282 12.9247 282.179 12.1882 282.536 11.7954C282.893 11.3535 283.377 10.9116 283.989 10.4697C284.959 9.73322 286.106 8.80032 287.433 7.67103C288.759 6.54173 289.958 5.26514 291.029 3.84124C291.386 3.49754 291.743 3.20295 292.1 2.95745C292.508 2.71195 292.967 2.58919 293.477 2.58919H300.134C301.358 2.58919 301.971 3.17839 301.971 4.35679V36.3944H306.715C307.939 36.3944 308.551 36.9836 308.551 38.162V44.4959C308.551 45.6743 307.939 46.2635 306.715 46.2635H284.295Z" fill="#ECAA1F" />
        <path d="M336.092 47C333.287 47 330.762 46.4353 328.517 45.3061C326.324 44.1277 324.437 42.5319 322.855 40.5188C321.325 38.5057 320.126 36.1244 319.259 33.3748C318.443 30.6252 318.035 27.6547 318.035 24.4632C318.035 21.2226 318.494 18.252 319.412 15.5516C320.381 12.802 321.682 10.4206 323.314 8.40753C324.947 6.39444 326.86 4.82324 329.053 3.69394C331.246 2.56465 333.618 2 336.169 2C338.974 2 341.5 2.56465 343.744 3.69394C346.039 4.82324 347.952 6.39444 349.483 8.40753C351.064 10.4206 352.263 12.802 353.079 15.5516C353.946 18.3011 354.38 21.2717 354.38 24.4632C354.38 27.6547 353.946 30.6252 353.079 33.3748C352.263 36.1244 351.064 38.5057 349.483 40.5188C347.901 42.5319 345.963 44.1277 343.667 45.3061C341.423 46.4353 338.898 47 336.092 47ZM336.169 36.7627C338.362 36.7627 339.893 35.6334 340.76 33.3748C341.627 31.1162 342.061 28.1702 342.061 24.5368C342.061 22.7692 341.959 21.1489 341.755 19.6759C341.551 18.1538 341.219 16.8772 340.76 15.8462C340.301 14.766 339.689 13.9313 338.923 13.3421C338.209 12.7529 337.317 12.4583 336.245 12.4583C334.103 12.4583 332.598 13.5876 331.731 15.8462C330.864 18.1047 330.43 21.0016 330.43 24.5368C330.43 28.1702 330.864 31.1162 331.731 33.3748C332.598 35.6334 334.077 36.7627 336.169 36.7627Z" fill="#ECAA1F" />
        <path d="M377.713 47C374.907 47 372.382 46.4353 370.138 45.3061C367.944 44.1277 366.057 42.5319 364.476 40.5188C362.945 38.5057 361.746 36.1244 360.879 33.3748C360.063 30.6252 359.655 27.6547 359.655 24.4632C359.655 21.2226 360.114 18.252 361.032 15.5516C362.002 12.802 363.302 10.4206 364.935 8.40753C366.567 6.39444 368.48 4.82324 370.673 3.69394C372.867 2.56465 375.239 2 377.789 2C380.595 2 383.12 2.56465 385.364 3.69394C387.66 4.82324 389.573 6.39444 391.103 8.40753C392.684 10.4206 393.883 12.802 394.699 15.5516C395.566 18.3011 396 21.2717 396 24.4632C396 27.6547 395.566 30.6252 394.699 33.3748C393.883 36.1244 392.684 38.5057 391.103 40.5188C389.522 42.5319 387.583 44.1277 385.288 45.3061C383.043 46.4353 380.518 47 377.713 47ZM377.789 36.7627C379.983 36.7627 381.513 35.6334 382.38 33.3748C383.247 31.1162 383.681 28.1702 383.681 24.5368C383.681 22.7692 383.579 21.1489 383.375 19.6759C383.171 18.1538 382.839 16.8772 382.38 15.8462C381.921 14.766 381.309 13.9313 380.544 13.3421C379.83 12.7529 378.937 12.4583 377.866 12.4583C375.723 12.4583 374.219 13.5876 373.351 15.8462C372.484 18.1047 372.051 21.0016 372.051 24.5368C372.051 28.1702 372.484 31.1162 373.351 33.3748C374.219 35.6334 375.698 36.7627 377.789 36.7627Z" fill="#ECAA1F" />
      </g>
      <path d="M67.1469 19.6792L67.6604 29.7832H97.6621L97.8822 19.6792H67.1469Z" fill="#ECAA1F" />
      <path d="M66.7316 4.78902L67.2589 15.159H98.0715L98.2975 4.78902H66.7316Z" fill="white" />
      <path d="M0 4.19075V47H11.2142V20.6763L19.9364 47H27.8971L36.8962 20.6763V47H51.5716V4.19075H32.2582L25.0589 25.7283L18.3442 4.19075H0Z" fill="white" />
      <path d="M67.9776 36.763L68.4622 46.2688H96.7746L96.9823 36.763H67.9776Z" fill="#DC9F1D" />
      <path d="M134.571 46.8671C131.294 46.8671 128.317 46.3353 125.641 45.2717C122.964 44.2081 120.657 42.6792 118.719 40.685C116.78 38.6908 115.28 36.2755 114.219 33.4393C113.158 30.6031 112.627 27.4123 112.627 23.8671C112.627 20.2331 113.227 16.9981 114.427 14.1618C115.673 11.2813 117.334 8.88825 119.411 6.98266C121.487 5.03276 123.887 3.54817 126.61 2.5289C129.379 1.50963 132.286 1 135.332 1C138.009 1 140.432 1.24374 142.601 1.73122C144.77 2.21869 146.546 2.70617 147.931 3.19364C148.623 3.41522 148.969 3.99133 148.969 4.92197V11.7023C148.969 12.3227 148.808 12.7881 148.485 13.0983C148.208 13.3642 147.815 13.3863 147.308 13.1647C146.016 12.6329 144.424 12.1455 142.531 11.7023C140.639 11.2148 138.632 10.9711 136.509 10.9711C134.986 10.9711 133.532 11.1927 132.148 11.6358C130.763 12.079 129.517 12.8102 128.41 13.8295C127.348 14.8487 126.495 16.2004 125.849 17.8844C125.202 19.5241 124.879 21.5626 124.879 24C124.879 26.3044 125.179 28.2765 125.779 29.9162C126.379 31.5116 127.187 32.841 128.202 33.9046C129.264 34.9239 130.486 35.6773 131.871 36.1647C133.255 36.6079 134.755 36.8295 136.371 36.8295C138.493 36.8295 140.57 36.5636 142.601 36.0318C144.677 35.4557 146.385 34.9239 147.723 34.4364C148.231 34.2148 148.623 34.237 148.9 34.5029C149.177 34.7688 149.315 35.2119 149.315 35.8324V42.3468C149.315 43.1888 148.992 43.7649 148.346 44.0751C146.685 44.8285 144.654 45.4711 142.255 46.0029C139.901 46.579 137.34 46.8671 134.571 46.8671Z" fill="white" />
      <path d="M185.583 46.8671C182.306 46.8671 179.329 46.3353 176.653 45.2717C173.976 44.2081 171.669 42.6792 169.73 40.685C167.792 38.6908 166.292 36.2755 165.231 33.4393C164.169 30.6031 163.639 27.4123 163.639 23.8671C163.639 20.2331 164.239 16.9981 165.438 14.1618C166.685 11.2813 168.346 8.88825 170.423 6.98266C172.499 5.03276 174.899 3.54817 177.622 2.5289C180.391 1.50963 183.298 1 186.344 1C189.021 1 191.443 1.24374 193.612 1.73122C195.781 2.21869 197.558 2.70617 198.943 3.19364C199.635 3.41522 199.981 3.99133 199.981 4.92197V11.7023C199.981 12.3227 199.82 12.7881 199.496 13.0983C199.22 13.3642 198.827 13.3863 198.32 13.1647C197.028 12.6329 195.435 12.1455 193.543 11.7023C191.651 11.2148 189.644 10.9711 187.521 10.9711C185.998 10.9711 184.544 11.1927 183.16 11.6358C181.775 12.079 180.529 12.8102 179.422 13.8295C178.36 14.8487 177.506 16.2004 176.86 17.8844C176.214 19.5241 175.891 21.5626 175.891 24C175.891 26.3044 176.191 28.2765 176.791 29.9162C177.391 31.5116 178.199 32.841 179.214 33.9046C180.275 34.9239 181.498 35.6773 182.883 36.1647C184.267 36.6079 185.767 36.8295 187.382 36.8295C189.505 36.8295 191.582 36.5636 193.612 36.0318C195.689 35.4557 197.397 34.9239 198.735 34.4364C199.243 34.2148 199.635 34.237 199.912 34.5029C200.189 34.7688 200.327 35.2119 200.327 35.8324V42.3468C200.327 43.1888 200.004 43.7649 199.358 44.0751C197.697 44.8285 195.666 45.4711 193.266 46.0029C190.913 46.579 188.352 46.8671 185.583 46.8671Z" fill="white" />
      <path d="M214.858 46.0029C214.212 46.0029 213.774 45.8478 213.543 45.5376C213.312 45.183 213.289 44.7399 213.474 44.2081L228.149 3.06069C228.472 2.21869 229.118 1.79769 230.087 1.79769H240.679C241.648 1.79769 242.294 2.24085 242.617 3.12717L256.877 44.2081C257.062 44.7399 257.038 45.183 256.808 45.5376C256.577 45.8478 256.139 46.0029 255.492 46.0029H246.424C245.455 46.0029 244.832 45.5597 244.555 44.6734L242.202 37.4277H228.149L225.657 44.6734C225.38 45.5597 224.757 46.0029 223.788 46.0029H214.858ZM239.571 27.922C238.048 23.3574 236.941 19.9229 236.248 17.6185C235.556 15.2698 235.164 13.9846 235.071 13.763C235.025 13.896 234.656 15.1368 233.964 17.4855C233.318 19.79 232.256 23.2688 230.78 27.922H239.571Z" fill="white" />
      <defs>
        <filter id="filter0_d_4697_146" x="280" y="0" width="126" height="57" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.247059 0 0 0 0 0.109804 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4697_146" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4697_146" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
