export const BigFlame40 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="278.3 722.21 308.94 812.12 464.2 860.15 514.41 721.5 524.54 623.93 354.66 580.6 278.3 722.21"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m569.24,668.52c-5.21-38.54-17.94-76.12-58.26-89.23-26.2-9.19-52.5-15.6-76.88-30.02.13,7.54,2.27,14.31,1.19,21.5-48.14-6.76-92.26-27.12-140.62-32.43.88,35.66-5.42,69.45-20.25,101.73-17.51-3.18-33.11-10.06-48.18-18.67,13.38,28.31,31.37,62.08,17.33,93.43-5.3,9.47-7,20.29-9.49,30.71-17.86,88.7,90.92,112.95,157.47,93.64,30.27,9.27,59.39,18.01,71.15,21.86,1.04.34,1.59.4,2.06-.88,8.41-23.97,17.32-47.81,26.09-71.69-.99,2.78-2,5.59-3.02,8.42,42.74-28.55,83.5-73.83,81.42-128.37Zm-272.94,111.57c3.61,10.61,7.18,21.1,10.57,31.2-.2-.06-.39-.12-.59-.19-3.34-10.37-6.66-20.7-9.99-31.02Zm163.65,38.61c-.06,0-.19,22.2-.19,34.3,0,3.08.25,4.16-2.6,3.23-48.66-15.79-97.36-30.2-146.03-45.94-9.4-20.26-26.25-77.21-28.09-82.88-.15-.46-.64-1.01-.18-1.44,16.42,4.35,133.81,40.52,175.98,53.36.97.29,1.45.66,1.44,1.81-.2,12.51,0,25.04-.33,37.55Zm2.63-47.32c-58.31-17.71-118.62-35.31-176.94-53-1.76-.54-1.75-.54-.86-2.19,24.36-43.82,46.99-90.18,72.19-132.76,53.44,13.91,106.85,27.94,160.29,41.86,1.37.36,1.44.84.98,2.04-19.99,46.93-36.19,98.63-55.66,144.05Zm5.28,6.05c17.84-48.45,36.45-97.15,55.43-145.06-2.7,27.63-6.35,55.07-9.08,82.67-.04,3.19-.54,6.29-1.31,9.32-1.88,7.42-33.71,93.57-46.81,129.42.31-25.45,1.23-50.9,1.77-76.35Zm51.18-95.21c-.47,4.8-.99,9.59-1.58,14.37.53-4.79,1.05-9.58,1.58-14.37Zm-11.66,60.19c1.57-4.52,3.13-9.05,4.65-13.58-.24,1.05-.46,2.1-.71,3.15-1.35,3.5-2.65,6.97-3.94,10.42Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m283.74,406.55c-.91-6.65-1.99-12.77-7.11-17.03-6.79-5.87-8.09-12.68-12.26-21.02-1.07,17.38,5.99,29.07,19.37,38.04Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m433.32,702.86c-22.29-.05-29.05,27.11-6.83,33.31,29.25,7.23,38.23-31.78,6.83-33.31Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m401.7,694.06c-27.79-17.11-51.55,25.92-14.02,29.68,15.86.64,29.15-18.67,14.02-29.68Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m357.64,681.07c-26.65-15.26-50.56,25.75-14.41,29.55,15.63,1.21,30.28-19.05,14.41-29.55Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m477.27,636.73c-15.13-13.18-46.36,5.21-26.44,22.07,3.91,2.85,8.34,4.03,11.04,4,15.53,1.51,29.02-14.3,15.4-26.08Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m416.32,650.57c25.77,4.54,34.32-27.53,5.61-29.55-21.38-.32-28.19,24.86-5.61,29.55Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m378.44,639.46c26.53-.66,25.89-31.91-1.09-29.14-22.11,3.01-22.04,28.46,1.09,29.14Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m318.08,750.52c-11.05,1.46-1.67,12.22,3.52,14.96,5.97,3.89,12.4,6.51,19.59,6.96,8.15.33,9.38-5.33,3.99-10.6-7.13-6.93-17.05-11.66-27.1-11.32Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m364.66,786.02c-4.54-.04-6.51,4.02-3.64,7.53,6.74,8.03,16,11.52,25.5,12.77,7.35.18,10.35-3.79,5.05-9.41-7.06-6.9-16.91-11.29-26.91-10.89Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m412.7,819.15c-6.41-1.22-13.19,1.96-6.81,8.34,6.76,6.47,15.04,9.4,24.14,10.38,3.35.21,8.75-1.33,6.6-5.66-5.03-8.02-15-11.5-23.93-13.05Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m487.11,741.85c-5.02,2.53-11.39,28.43-11.69,34.72.47,14.19,15.48-33.53,11.69-34.72Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m503.63,706.27c1.15.24,1.5-1.56,2.07-2.44,4.24-9.51,7.23-19.71,8.41-30.06.8-3.64-1.36-3.91-2.44-.63-4.42,9.57-7.31,20.01-8.61,30.44.27.96-.53,2.44.56,2.69Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m496.44,732.21c-4.07,9.36-7.1,19.3-8.32,29.45-.07.82-.43,2.09.57,2.35,4.56-3.31,10.28-26.52,10.63-32.86.19-4.65-2.09-.76-2.89,1.06Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m484.52,785.65c-4.35,1.96-10.35,26.13-10.72,31.54.67,11.49,13.67-30.58,10.72-31.54Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m506.05,722.71c-3.18,8.33-5.73,17.05-6.79,25.93-.06.74-.49,1.87.33,2.11,3.89-2.34,9.59-25.12,9.88-30.41.27-5.44-2.66.82-3.41,2.37Z"/>
        </g>
      </g>
    </svg>
  )
}