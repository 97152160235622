export const BigFlame13 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="320.97 625.61 478.36 581.82 559.75 739.21 480.17 793.22 346.15 850.55 278.55 700.02 320.97 625.61"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m312.17,524.05c-.46-37.87,64.93-32.44-4.14-72.13,21.67,37.19-24.71,32.07,4.14,72.13Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m60.54,320.05c5.2,9.94,16.59,14.98,19.55,26.38,3.95-18.31-5.47-33.58-10.73-49.83-11.02,4.64-14.21,13.4-8.82,23.46Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m562.1,737.69c-27.66-52.78-54.28-106.9-82.31-159.1-20.07,5.54-40.14,11.11-60.21,16.67-22.16-11.81-44.96-19-68.58-27.05-14.84-4.21-26.53-12.96-37.17-23.08-.35,10.06,1.34,19.74,4.8,29.15-.09.08-.18.16-.26.24-18.77-9.32-30.59-29.64-44.74-45.2,2.69-29.12,11.04-67.13-19.57-84.52-41.8-17.04-74.32-48.54-76.27-96.11-20.66,40.01-66.35,89.42-31.71,133.76,22.03,28.37,51.7,33.66,84.44,41.13,12.42,5.17,23.07,13.92,25.32,26.91,12.29,46.37-14.48,93.48,3.04,138.74,15.57,42.9,17.81,99.98,67.04,118.31,6.17,13.78,12.33,27.57,18.5,41.35,1.08,2.42,1.05,2.42,3.48,1.38,15.81-6.82,31.63-13.65,47.44-20.47,33.45,2.2,86.21-21.71,91.99-40.68-.52.23-1.04.46-1.57.69,25.74-15.68,50.23-33.66,75.73-49.79,1.06-.7,1.18-1.22.59-2.34Zm-215.91,106.9c-1.12.91-1.5.79-2.06-.47-21.21-47.39-42.39-94.8-63.68-142.16,10.85-23.94,26.9-47.85,39.98-70.99,8.43,16.35,15.8,33.89,23.88,50.63,18.52,39.78,37.19,79.5,55.61,119.33-17.17,15.06-36.05,28.93-53.73,43.67Zm136.83-53.55c-9.78,4.32-19.59,8.65-29.46,13.01-33.52,14.41-66.88,29.78-100.52,43,17.79-13.84,35.72-27.35,53.39-41.34,2.39-1.86,5.33-2.61,8.04-3.78,46.08-19.23,92.19-38.66,138.3-57.65-20.84,14.96-47.05,31.37-69.75,46.77Zm71.41-54.38c-48.94,20.17-97.84,40.43-146.77,60.61-23.87-47.15-45.54-97-68.61-145.06-3.75-8.01-7.49-16.03-11.3-24.02-.59-1.24-.65-1.75.93-2.2,47.45-13.45,101.71-28.54,148.82-42.24,7.68,15.01,16.94,31.77,24.64,46.77,17.74,34.56,35.5,69.11,53.24,103.68.85,1.66,1.33,1.53-.94,2.46Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m426.98,679.93c-.85,20.18,33.27,31.74,36.28,7.52,1.95-21.75-34.42-33.45-36.28-7.52Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m361.36,753.81c-4.83-1.97-6.19,4.36-5.96,7.91.57,9.01,3.54,17.15,9.04,24.32,3.9,5.59,10.66,7.04,11.02-1.56-.02-11.14-4.36-24.57-14.1-30.67Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m306.23,709.93c-3.97-6.47-9.5-6.99-9.26,1.87.55,8.32,3.07,15.98,7.92,22.81,4.62,6.58,9.03,3.89,8.48-3.49-.6-7.65-2.91-14.75-7.14-21.19Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m412,809.23c3.9,1.24,34.07-12.95,35.08-16.57-5.05-.91-31.35,12.3-35.08,16.57Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m519.42,761.93c-4.69-.44-30.98,12.23-33.24,16.27,6.1-.17,29.47-11.59,33.24-16.27Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m382.81,831.64c11.57-3.57,22.38-8.73,32.48-15.4-3.9-.85-30.36,11.71-32.48,15.4Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m482.14,786.95c-5.6,1-28.1,10.99-30.41,15.45,11.03-3.9,21.2-8.68,30.7-14.83-.1-.2-.19-.41-.29-.61Z"/>
        </g>
      </g>
    </svg>
  )
}