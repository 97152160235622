export const NoFlame26 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="272.47 767.44 367.98 847.47 523.93 821.47 524.25 677.54 454.46 573.92 300.58 621.1 272.47 767.44"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m524.82,749.84c0,23.62,0,47.25.02,70.87,0,1.26-.17,1.91-1.69,2.16-15.34,2.52-30.66,5.15-45.99,7.74-19.66,3.33-39.33,6.65-58.99,9.97-16.21,2.74-32.41,5.47-48.62,8.23-1.09.19-1.86-.12-2.69-.81-19.3-15.95-38.63-31.87-57.95-47.81-12.77-10.53-25.53-21.08-38.32-31.59-.79-.65-1.02-1.22-.81-2.25,5.35-26.3,10.65-52.61,15.96-78.92.77-3.79,1.55-7.58,2.32-11.38,3.69-18.3,7.39-36.59,11.06-54.9.19-.95.67-1.38,1.57-1.65,51.04-15.73,102.07-31.47,153.1-47.24.98-.3,1.47-.13,2.03.72,22.78,34.61,45.57,69.21,68.39,103.79.53.81.61,1.62.61,2.52,0,23.52,0,47.04,0,70.55Zm-74.91-171.53c.12-1.71-.98-.69-1.46-.54-21.6,6.59-43.19,13.23-64.79,19.85-26.53,8.13-53.06,16.24-79.6,24.34-.93.28-1.34.78-1.52,1.71-.7,3.75-1.46,7.49-2.21,11.24-5.09,25.65-10.19,51.3-15.29,76.95-3.2,16.11-6.39,32.22-9.58,48.33-.34,1.69-.32,1.7,1.44,1.29,21.57-4.95,43.15-9.89,64.72-14.85,32.03-7.36,64.05-14.76,96.09-22.05,1.73-.39,2.14-1.14,2.24-2.67.59-8.98,1.24-17.96,1.86-26.93.95-13.57,1.89-27.14,2.84-40.71.73-10.49,1.47-20.98,2.2-31.47,1.02-14.82,2.03-29.65,3.05-44.47Zm-172.61,191.09c8.69,7.21,17.07,14.16,25.44,21.13,21.9,18.21,43.8,36.42,65.66,54.67.96.8,1.85,1,3,.8,16.23-2.8,32.47-5.6,48.71-8.36,19.97-3.4,39.95-6.77,59.92-10.16,12.17-2.07,24.33-4.17,36.51-6.21,1.19-.2,1.16-.45.45-1.27-7.98-9.29-15.94-18.61-23.9-27.92-9.05-10.58-18.11-21.16-27.16-31.74-7.63-8.91-15.27-17.81-22.86-26.74-.79-.93-1.53-1.11-2.71-.83-8.21,1.89-16.44,3.69-24.66,5.54-41.81,9.39-83.62,18.79-125.43,28.18-4.22.95-8.45,1.91-12.98,2.93Zm245.35,45.7c.04-.88.07-1.34.08-1.79.09-13.3.06-26.6.13-39.9.15-31.11.1-62.22.15-93.33,0-.99-.24-1.8-.79-2.63-21.3-32.11-42.58-64.24-63.87-96.36-.26-.4-.38-1-1.14-1.11-.31,5.31-.61,10.57-.94,15.84-.71,11.43-1.45,22.87-2.16,34.3-.33,5.33-.65,10.65-.99,15.98-.71,11.33-1.44,22.66-2.15,33.99-.34,5.38-.66,10.76-1,16.13-.6,9.5-1.21,19-1.83,28.5-.06.9.21,1.58.79,2.27,10.5,12.52,20.97,25.05,31.45,37.59,10.61,12.68,21.21,25.36,31.82,38.04,3.39,4.05,6.79,8.1,10.47,12.48Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m394.6,712.1c-6.75.1-11.83-4.06-12-10.31-.09-3.16.63-6.03,2-8.74,3.82-7.56,9.87-12.49,18.07-14.62,3.68-.96,7.36-.79,10.81,1.13,3.18,1.77,5.41,6.09,4.94,9.66-1.54,11.83-10.46,20.96-22.25,22.79-.62.1-1.25.07-1.56.08Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m356.3,683.92c-3.31.05-6.67-1.92-8.13-5.78-1.01-2.66-.88-5.41.04-8.01,3.31-9.27,9.86-15.09,19.34-17.58,2.12-.56,4.34-.6,6.53-.01,5.12,1.36,7.77,5.84,6.63,11.64-1.37,6.94-5.66,11.9-11.32,15.77-3.46,2.36-7.26,3.93-13.09,3.97Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m347.22,638.67c-.22,3.81-2.57,7.82-5.77,11.4-3.01,3.37-6.62,5.9-10.87,7.5-2.29.86-4.65,1.25-7.07,1.07-5.24-.4-8.3-4.72-7.3-10.13,1.29-6.95,5.54-11.79,11.13-15.65,3-2.07,6.35-3.34,10.01-3.82,5.81-.76,9.88,2.69,9.87,9.63Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m400.81,786.56c4.43.07,10.47,1.19,15.86,4.78,1.01.68,1.88,1.53,2.6,2.48,1.88,2.48,1.63,5.17-.66,7.32-2.18,2.05-4.92,2.99-7.78,3.47-8.34,1.39-16.37.62-23.8-3.81-1.19-.71-2.17-1.66-3.01-2.72-2.32-2.95-1.61-6.28,1.78-8.4,4.01-2.51,8.49-3.12,15.01-3.11Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m461.88,703.57c.05-2.8.02-4.96.68-7.04.21-.65.42-1.3.73-1.9,1.37-2.73,3.65-3.15,6.02-1.14,2.09,1.77,3.46,4.07,4.6,6.49,3.16,6.72,4.85,13.8,4.83,21.25,0,2.26-.2,4.49-.95,6.65-1.39,4-4.32,4.62-7.22,1.51-2.73-2.93-4.41-6.47-5.72-10.2-1.85-5.27-2.97-10.68-2.98-15.63Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m465.72,628.96c0-1.9.12-3.77.73-5.58,1.09-3.25,3.33-3.83,5.79-1.47,2.43,2.33,3.97,5.23,5.24,8.29,2.52,6.1,4.03,12.41,4.02,19.05,0,1.79-.22,3.55-.85,5.23-1.07,2.88-2.95,3.41-5.31,1.48-2.07-1.7-3.38-3.96-4.54-6.32-3.21-6.52-4.84-13.44-5.08-20.68Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m497.9,708.6c.02,1.66-.02,4.02-.94,6.25-1.21,2.91-3.16,3.34-5.46,1.2-2.6-2.42-4.04-5.57-5.25-8.81-2.43-6.47-3.84-13.13-3.25-20.1.13-1.47.41-2.91,1.08-4.23,1.14-2.24,2.93-2.58,4.85-.95,2.18,1.84,3.47,4.28,4.64,6.81,2.6,5.62,4.31,13.17,4.32,19.83Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m499.68,750.24c.05-2.52.13-5.13,1.21-7.59,1.11-2.53,3.05-2.98,5.09-1.13,2.28,2.07,3.54,4.8,4.62,7.58,2.45,6.3,3.73,12.85,3.52,19.64-.06,1.93-.31,3.86-1.08,5.67-1.22,2.86-3.51,3.19-5.62.84-1.88-2.1-3.18-4.54-4.18-7.14-2.21-5.71-3.42-11.63-3.56-17.86Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m514.75,696.38c.02,2.83-.16,4.75-.83,6.59-.91,2.5-2.58,2.9-4.54,1.13-2.17-1.95-3.36-4.53-4.45-7.13-2.46-5.84-3.67-11.98-3.84-18.3-.05-1.81.18-3.65.83-5.39.91-2.45,2.66-2.91,4.56-1.13,1.83,1.71,3,3.87,4.01,6.13,2.69,6.04,4.03,12.39,4.26,18.11Z"/>
        </g>
      </g>
    </svg>
  )
}