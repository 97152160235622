export const NoFlame37 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="274 683.01 365.42 586.6 521.75 618.1 522.26 751.86 458.86 866.66 304.51 816.7 274 683.01"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m523.18,684.01c0,21.74.2,49.71.02,67.7-22.07,38.62-43,75.32-63.54,114.57-.66,1.29-1.3,1.35-2.55.95-22.14-7.11-123.99-39.62-152.78-48.77-1.16-.37-1.64-.94-1.92-2.1-9.41-44.41-22.26-89.96-31.06-133.68,26.77-32.9,63.22-65.41,93.59-97.33,48.8,8.06,154.67,30.94,157.27,31.15,1.43.12,1.3,1.01,1.3,1.98-.07,21.84-.28,65.52-.32,65.52Zm-247.06,5.45c9.69,41.57,19.81,83.02,29.57,124.57.33,1.38.91,2.08,2.31,2.52,48.11,15.22,96.2,30.5,144.29,45.79,1.52.48,1.56.41,1.41-1.19-.28-3.02-.53-6.04-.77-9.07-3.09-39.33-6.24-78.65-9.21-117.98-.09-1.13-.4-1.73-1.63-2.04-55.38-14.2-110.56-28.41-165.97-42.6Zm90.73-100.13c-1.19-.3-1.55.31-1.97.75-28.65,30.53-58.22,60.9-86.38,91.56,17.04,4.86,128.79,36.11,166.65,42.07,23.07-34.34,47.48-69.4,70.61-103.71.89-1.31.46-1.56-.81-1.8-49.31-9.55-98.81-19.33-148.1-28.88Zm93.89,270.65c20.27-35.94,40-72.2,60.07-108.27l.24-126.98c-22.7,35.6-47.74,70.33-69.38,106.31,3.1,43.1,6.05,85.76,9.08,128.93Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m338.71,740.5c-2.15,20.95-35.75.02-33.58-14.36.41-4.41,3.55-7.21,8.27-7.39,11.7.12,24.05,9.85,25.31,21.76Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m359.92,764.03c10.26.5,20.11,7.41,23.82,17.06,2.38,6.65-.93,12.36-8.68,11.85-11.48-.72-23.04-9.26-24.28-21.14.27-5.1,4.09-7.98,9.14-7.77Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m404.47,807.99c6.44.26,11.91,2.89,16.78,6.93,3.1,2.58,5.61,5.62,6.99,9.47,4.4,16.71-18.98,11.53-25.79,3.99-7.91-5.67-11.88-20.66,2.03-20.4Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m421.32,697.66c-7.88.18-20.21-3.56-18.76-12.86.61-3.44,2.86-5.87,5.67-7.57,9.37-6.27,28.66-7.49,34.07,4.32,1.3,4.04-.57,7.21-3.38,9.79-5.05,4.63-11.33,6.04-17.61,6.33Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m382.22,663.06c12.04-.84,25.25,8.82,12.94,18.79-9.24,9.94-46.48,5.38-30.54-12.56,5.23-4.56,11.61-6,17.6-6.24Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m336.12,677.25c-21.65-.75-19.23-18.37-.35-22.44,7.84-2.15,23.8-.98,23.3,9.73-2.46,9.63-14.06,12.41-22.95,12.71Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m462.15,622.82c5.4.12,11.43,1.15,15.34,5.2,3.35,3.79,2.45,7.49-1.19,10.83-7.39,6.25-18.56,6.82-27.37,3.46-5.79-1.9-9.59-9.04-4.22-13.65,4.79-4.28,11.17-5.85,17.45-5.85Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m422.16,614.91c6.66.14,14.93,1.19,16.84,8.15.67,10.13-14.71,13.1-22.46,12.5-7.1.2-18.91-6.25-11.93-14.06,4.31-4.77,11.54-6.51,17.55-6.59Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m380.17,627.28c-21.82,0-19.61-16.89-.2-19.67,7.72-2.02,26.78,2.32,18.18,13.13-4.56,4.69-11.71,6.55-17.98,6.54Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m480.28,725.74c-.65,10.03-2.79,20.89-9.73,28.53-2.15,1.93-4.04,1.45-5.1-1.24-2.5-9.99.79-20.62,5.04-29.72,4.65-9.38,10.21-8.25,9.79,2.42Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m468.41,812.54c.7-9.35,2.84-18.26,8.22-26.16,1.26-2.01,4.47-4.14,5.57-.84,2.34,8.76-1.03,18.17-4.35,26.3-2.89,7.19-9.48,12.79-9.45.7Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m484.17,751.74c.44-8.47,2.43-16.52,6.59-23.97,1.21-2.43,5.13-6.49,6.68-2.13,1.07,2.94.88,6.02.56,9.02-.7,6.6-2.38,12.95-5.35,18.92-3.99,8.39-8.8,7.94-8.48-1.84Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m513.31,672.32c.94,3.98-6.34,34.67-12.5,25.79-2.75-9.54.52-20,4.38-28.86,4.55-9.35,8.63-5.7,8.12,3.07Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m501.13,755.84c.25-5.52,3.84-25.31,10.7-26.1,3.53,2.16,1.59,10.95,1.06,14.49-1.36,6.35-3.11,13.06-7.43,18.09-4.13,3.31-4.34-3.52-4.33-6.49Z"/>
        </g>
      </g>
    </svg>
  )
}