export const BigFlame17 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="291.24 670.6 309.29 651.96 489.71 598.24 545.8 777.31 368.08 841.51 340.28 819.91 291.24 670.6"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m240.08,360.28c13.4-14.86,20.47-45.83-1.46-56.25-16.61-7.22-33.75-10.95-47.97-23.18,5.76,21.76,10.57,42.82-1.41,63.36-16.87,26.96-16.93,66.09,9.47,87.12,5.33-21.69,12.92-42.32,29.47-58.27,4.09-4.13,8.01-8.44,11.89-12.78Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m323.45,460.21c-.14-38.49-40.13-28.31-59.28-52.02,9.23,55.81,30.76,32.92,59.28,52.02Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m548.97,778.26l-10.94-34.64c48.79-68.32,21.99-167.41-75.1-155.6-36.71,7.29-73.51,13.36-110.32,20.14-28.06,3.2-80.4,21.09-96.72-11.1-9.29,44.5-19.51,87.92-17.61,133.48-8.63-2.21-15.54-5.27-18.85-14.62-12.75,31.22,13.85,45.51,32.33,63.69,24.25,37.76,67.05,61.97,111.87,60.01l5.64,3.98,17.25-6.27c31.81-3.94,60.93-15.21,88.02-32.01l74.42-27.07Zm-208.37,41.36l-48.48-148.05,15.42-14.51,58.65,180.07-25.59-17.51Zm-26.9-165.1l173.38-52.87,55.07,172.96-169.07,61.58-59.39-181.67Z"/>
          <circle fill={props?.color ?? "#e8c305"} cx="431.5" cy="716.62" r="19.64" transform="translate(-153.1 118.31) rotate(-13.28)"/>
          <path fill={props?.color ?? "#e8c305"} d="m302.45,690.24c-1.06.34.5,8.14,3.49,17.43,2.98,9.29,6.26,16.54,7.32,16.2,1.06-.34-.5-8.14-3.49-17.43-2.98-9.29-6.26-16.54-7.32-16.2Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m351.8,809.39c1.4-.46-.23-9.2-3.65-19.52s-7.32-18.31-8.72-17.84c-1.4.46.23,9.2,3.65,19.52s7.32,18.31,8.72,17.84Z"/>
        </g>
      </g>
    </svg>
  )
}