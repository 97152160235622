export const NoFlame17 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="291.26 670.42 309.31 651.78 489.74 598.06 545.82 777.13 368.1 841.33 340.3 819.74 291.26 670.42"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <path fill={props?.color ?? "#e8c305"} d="m491.11,595.16l-184.85,55.6-16.26,16.84,51.35,156.28,27.92,19.72,179.7-65.35-57.86-183.1Zm-150.51,224.45l-48.48-148.05,15.42-14.51,58.65,180.07-25.59-17.51Zm-26.9-165.1l173.38-52.87,55.07,172.96-169.07,61.58-59.39-181.67Z"/>
        <circle fill={props?.color ?? "#e8c305"} cx="431.5" cy="716.62" r="19.64"/>
        <ellipse fill={props?.color ?? "#e8c305"} cx="307.85" cy="707.06" rx="2.01" ry="17.66" transform="translate(-201.58 128.11) rotate(-17.82)"/>
        <ellipse fill={props?.color ?? "#e8c305"} cx="345.62" cy="790.7" rx="2.67" ry="19.68" transform="translate(-231.02 148.71) rotate(-18.32)"/>
      </g>
    </svg>
  )
}