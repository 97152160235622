export const NoFlame13 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="321 625.43 478.39 581.64 559.77 739.03 480.19 793.04 346.63 850.75 278.58 699.84 321 625.43"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m479.79,578.6c28.02,52.2,54.64,106.32,82.31,159.1.58,1.12.46,1.64-.59,2.34-27.39,17.32-53.61,36.78-81.46,53.23-44.04,19.01-88.08,38.01-132.12,57.02-2.43,1.05-2.4,1.04-3.48-1.38-22.17-49.55-44.32-99.11-66.54-148.64-.57-1.26-.56-2.22.14-3.45,8.08-14.24,16.09-28.52,24.12-42.79,5.59-9.94,11.19-19.89,16.73-29.86.56-1,1.35-1.36,2.33-1.63,52.87-14.63,105.7-29.35,158.58-43.95Zm72.97,165.68c-46.12,18.99-92.22,38.42-138.3,57.65-2.72,1.17-5.66,1.92-8.04,3.78-17.66,13.99-35.59,27.49-53.39,41.34,39.79-15.63,79.19-34.29,118.92-50.78,3.59-1.6,7.41-2.79,10.7-4.97,22.78-15.47,49.16-31.98,70.12-47.02Zm-145.11,52.99c48.93-20.18,97.84-40.44,146.77-60.61,2.26-.93,1.79-.81.94-2.46-17.74-34.56-35.49-69.12-53.24-103.68-7.7-15-16.96-31.76-24.64-46.77-47.11,13.7-101.36,28.8-148.82,42.24-1.58.45-1.52.96-.93,2.2,3.8,7.99,7.54,16.01,11.3,24.02,23.07,48.06,44.74,97.91,68.61,145.06Zm-7.74,3.66c-18.42-39.83-37.09-79.55-55.61-119.33-8.08-16.74-15.45-34.27-23.88-50.63-13.08,23.14-29.13,47.05-39.98,70.99,21.29,47.36,42.47,94.77,63.68,142.16.56,1.26.94,1.38,2.06.47,17.68-14.74,36.56-28.61,53.73-43.67Zm153.85-56.98c-.07-.12-.12-.12-.21-.02.07.1.13.12.21.02Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m553.77,743.94c-.08.1-.14.08-.21-.02.09-.1.14-.1.21.02Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m552.76,744.27c.24-.31.62-.12.2.21-.07-.07-.14-.14-.2-.21Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m426.98,679.93c1.86-25.93,38.23-14.23,36.28,7.52-3.01,24.21-37.13,12.66-36.28-7.52Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m355.41,761.73c-.24-3.55,1.12-9.88,5.96-7.91,9.74,6.1,14.08,19.53,14.1,30.67-.36,8.61-7.12,7.16-11.02,1.56-5.51-7.17-8.47-15.32-9.04-24.32Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m296.97,711.8c-.24-8.86,5.29-8.35,9.26-1.87,4.22,6.43,6.53,13.53,7.14,21.19.54,7.39-3.86,10.07-8.48,3.49-4.85-6.83-7.36-14.49-7.92-22.81Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m412,809.23c3.73-4.27,30.03-17.48,35.08-16.57-1.01,3.61-31.18,17.81-35.08,16.57Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m486.18,778.2c2.26-4.04,28.55-16.7,33.24-16.27-3.77,4.68-27.13,16.1-33.24,16.27Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m415.29,816.24c-10.1,6.67-20.91,11.83-32.48,15.4,2.11-3.69,28.58-16.25,32.48-15.4Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m482.43,787.56c-9.49,6.15-19.67,10.93-30.69,14.83,2.31-4.46,24.81-14.45,30.41-15.45.1.2.19.41.29.61Z"/>
        </g>
      </g>
    </svg>
  )
}