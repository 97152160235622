export const BigFlame11 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="318.48 620.25 463.22 577.43 562.24 717.24 486.04 796.14 358.1 860.42 273.5 722.1 318.48 620.25"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m435.29,510.95c-8.58,12.03-14.94,24.11-18.46,37.43-19.87-5.79-37.38-18.66-56.16-27.55-24.62-12.99-26.1-45.15-13.33-66.93,20.99-36.57-65.83-60.13-90.08-66.95,34.88,32,71.74,51.98,61.43,106.03-18.38-9.77-34.02-25.28-48.87-40.02-37.78-44.13-88.16-24.59-93.88-97.44-21.21,46.02-24.29,116.87,32.59,136.79,22.46,8.7,42.42,27.2,51.36,49.3,5.83,33.11,4.17,65.98-3.52,98.56-4.64,16.46-4.08,33.4-5.07,49.96-2.92-.85-4.87-3.93-8.7-4.03,28.85,59.61,12.84,94.8,87.58,126.51-19.24-31.62-37.86-62.22-56.6-93.01,14.34-32.73,28.67-65.42,43.06-98.28,48.76-14.46,97.44-28.88,146.37-43.39,21.23,29.15,42.26,58.03,63.89,87.73,13.22-110.94-70.49-57.02-91.62-154.7Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m94.25,388.85c-1.66-21.2-12.15-39.38-19.07-59.5-19.55,25.21,3.98,41.37,19.07,59.5Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m426.46,827.13s0,0,0-.01c-.01,0-.03,0-.04.01h.04Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m428.52,824.42c-1.25.63-2.02,1.3-2.06,2.69,19.28-5.06,36.63-13.75,51.4-27.32-16.79,6.89-32.79,16.79-49.34,24.63Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m426.45,827.13s-.04,0-.04,0c-.57.04-1.14.08-2.05.15,1.1.98,1.66.77,2.09-.15Z"/>
        </g>
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m463.09,575.18c34.11,45.53,66.99,92.98,100.73,139.11.78,1.08.87,1.7-.15,2.7-25.7,25.91-52.08,53.74-77.65,79.78-41.16,22.92-83.06,41.61-124.85,63.6-1.63.7-3.69,2.29-4.47.8-28.15-46.85-56.31-93.7-84.52-140.51,8.66-28.28,25.12-56.13,36.37-84.24,2.46-5.47,4.95-10.93,7.35-16.43.51-1.18,1.22-1.77,2.43-2.13,48.26-14.18,96.49-28.49,144.75-42.68Zm94.35,145.78c-44.24,20.82-89.24,41.16-132.94,62.98-19.81,24.46-40.53,48.42-60,73.02l120.97-61.38c23.35-24.67,49.12-49.74,71.96-74.64.06-.04.12-.08.18-.12-.12-.03-.14.04-.17.14Zm.56-8.01c-32.55-43.43-62.92-91.57-96.79-132.66-45.02,13.53-90.07,26.96-135.1,40.46-1.85.55-1.86.54-.81,2.15,12.02,18.54,24.05,37.08,36.07,55.62,20.54,31.68,41.1,63.34,61.6,95.05.89,1.37,1.55,1.48,2.94.83,43.91-20.6,88.41-40.78,132.1-61.45Zm-240.01-86.81c-14.33,31.04-28.33,62.18-42.61,93.24-.46.99-.35,1.69.2,2.6,26.65,44.2,53.28,88.42,79.92,132.63.56.93.84,1.48,1.76.24,19.6-24.59,39.36-49.07,59.05-73.59.75-.93.71-1.51.11-2.43-32.87-50.9-65.54-101.79-98.43-152.7Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m557.44,720.97c.03-.1.05-.17.17-.14-.06.04-.12.08-.18.12,0,0,.02.02.02.02Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m440.15,652.55c14.05-.61,31.75,19.69,17.54,30.36-11.39,6.48-26.19-2.4-30.02-14.07-3.44-8.73,3.84-16.81,12.48-16.29Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m388.29,771.55c.33,4.67-2.41,11.49-8.18,9.79-10.42-4-15.27-16.2-16.22-26.62-.6-13.27,9.82-13.46,16.63-4.14,4.7,6.2,7.52,13.12,7.77,20.97Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m294.63,723.94c-.17-3.19,1.83-9.25,6.37-7.54,9.05,4.36,12.74,15.53,13.51,24.95.24,11.04-7.73,10.35-13.02,2.67-3.92-5.12-6.84-13.45-6.87-20.08Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m427.92,798.31c-8.98-1.21,22.36-21.33,25.41-21.36,1.61-.43,4.94-2.35,6.17-.76.56.93-.19,1.82-.74,2.56-8.23,8.86-19.03,16.58-30.85,19.56Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m495.02,765.93c-7.76-1.6,20.63-19.78,23.65-20.26,6.95-3.19,7.43-.5,2.4,4.19-7.61,6.71-16.14,13.38-26.05,16.06Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m395.95,835.39c-8.54-.76,21.74-19.57,24.44-19.51,9.36-3.53,2.87,3.76-.66,6.22-7.18,5.41-14.92,11.18-23.78,13.29Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m459.75,803.32c-6.84-1.35,17.42-16.6,19.86-17.3,1.58-.59,5.96-3.23,7.2-1.8.47.8-.18,1.54-.75,2.17-7.26,7.26-16.41,14.02-26.31,16.93Z"/>
        </g>
      </g>
    </svg>
  )
}