import { useEffect, useRef, useState } from "react"
import { fetcher } from "../../../../utils/fetcher"
import { useAuthHeader, useAuthUser } from "react-auth-kit"
import { useLocation, useNavigate } from "react-router-dom"

export const Users = (props) => {
  const auth = useAuthUser()
  const authHeader = useAuthHeader()
  const navigate = useNavigate()
  const location = useLocation()

  const username = useRef("")
  const phoneNumber = useRef("")
  const email = useRef("")
  const firstName = useRef("")
  const lastName = useRef("")
  const role = useRef("")
  const sortBy = useRef("")
  const order = useRef("")
  const offset = useRef(0)
  const signupStart = useRef("")
  const signupEnd = useRef("")

  const [search, setSearch] = useState([])
  const [more, setMore] = useState(true)

  const handleSubmit = async () => {
    const queryParams = {
      username: username.current.value,
      phoneNumber: phoneNumber.current.value,
      email: email.current.value,
      firstName: firstName.current.value,
      lastName: lastName.current.value,
      role: role.current.value,
    }

    let queryString = `?attributes=id_username_phoneNumber_email_firstName_lastName_role_locations_balance_details&limit=50&offset=${offset.current}&order=${sortBy.current?.value}-${order.current?.value}`

    for (let [k,v] of Object.entries(queryParams)) {
      if (v) {queryString = queryString+`&${k}=${v}`}
    }
    navigate("/super/users"+queryString, {
      state: {lastQuery: queryString}
    })
  }

  const handleSearch = async () => {
    await fetcher("GET", authHeader(),
      props.backend.current+`/users${window.location.search}`,
    ).then(data => {
      console.log(data)
      setSearch(data)
    })
  }

  const getMore = () => {
    if (more) {offset.current = offset.current+50}
    console.log("got more")
  }

  useEffect(()=>{
    // TODO: check cached DB
    if (window.location.href.includes("?") 
      && ((window.location.search !== location.state?.lastQuery ?? '') || search.length === 0)
    ) {
      console.log("run search")
      handleSearch()
    }
  }, [window.location.href])

  return (
    <div>
      <div className="row">
        <input ref={signupStart} type="date"/>
        <input ref={signupEnd} type="date"/>
        <select ref={role}>
          <option value={""}>N/A</option>
          <option value={"player"}>Player</option>
          <option value={"admin"}>Admin</option>
        </select>
        <select ref={order}>
          <option value={"DESC"}>Descending</option>
          <option value={"ASC"}>Ascending</option>
        </select>
        <select ref={sortBy}>
          <option value={"id"}>Id</option>
          <option value={"updatedAt"}>Last Updated</option>
          <option value={"balance"}>Balance</option>
        </select>
      </div>
      <div className="row">
        <input ref={username} type="text"/>
        <input ref={phoneNumber} type="tel"/>
        <input ref={email} type="email"/>
        <input ref={firstName} type="text"/>
        <input ref={lastName} type="text"/>
      </div>
      <div className="row">
        <div>Results: {search.length}</div>
        <div disabled={more} onClick={()=>{getMore()}}>More</div>
        <div className="submit" onClick={()=>{handleSubmit()}}>
          Search
        </div>
      </div>
      <div className="SA-table">
        <div className="SA-tableHeader"></div>
        <div className="SA-tableBody">
          {search.map((r,i)=>(
            <div className="SA-tableRow" key={`qr${i}`}>
              <div>{r.id}</div>
              <div>{r.username}</div>
              <div>{r.phoneNumber}</div>
              <div>{r.email}</div>
              <div>{`${r?.firstName ?? ""} ${r?.lastName ?? ""}`}</div>
              <div>{r.balance}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}