export const NoFlame05 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="294.75 622.64 404.75 593.53 546 648.78 507.6 797.37 412.62 862.09 276.96 782.99 294.75 622.64"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m547.02,648.11c-11.74,46.84-24.13,93.47-35.69,140.36-1.28,3.59-2.15,9.12-4.9,11.9-9,6.24-16.97,11.66-26,17.85-21.89,14.99-43.8,29.97-65.65,45.02-1.5,1.03-2.52.99-4.04.11-45-26.23-90.02-52.42-135.09-78.54-1.12-.65-1.41-1.28-1.26-2.58,1.78-15.34,3.48-30.69,5.21-46.04,4.34-38.06,8.65-76.13,12.88-114.21,0,0,75.51-18.91,112.41-29.33,47.67,16.75,94.72,37.31,142.12,55.46Zm-250.77-20.45c-6.4,50.61-11.59,101.48-17.76,152.15-.2,1.6.29,2.28,1.58,3.02,43.09,24.76,86.01,49.69,129.09,74.49l32.45-163.39c-48.31-21.73-97.12-44.41-145.36-66.27Zm247.18,26.26c-30.42,13.26-60.68,26.86-91.08,40.18-1.31.57-1.74,1.38-2,2.64-10.85,53.61-22.49,107.11-33.03,160.75,28.4-19.15,56.6-38.51,85.06-57.55,3.61-2.42,5.51-5.17,6.55-9.36,11.36-45.69,22.96-90.96,34.49-136.66Zm-242.42-31.98c-.02.12-.05.24-.07.36,49.11,20.93,96.51,45.91,145.93,64.57,30.43-13.27,62.67-26.04,92.92-39.61-44.95-16.88-89.5-35.97-134.81-52.32-34.64,8.84-69.33,18.16-103.97,27.01Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m393.09,699.18c15.34,1.25,25.21,23.46,14.96,35.4-3.42,3.57-7.65,4.75-12.35,3.29-17.21-3.79-24.18-37.09-2.61-38.69Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m327.12,767.91c.74,7.86-6.68,15.73-14.53,11.62-10.86-5.06-18.24-22.94-8.6-32.21,3.27-2.62,8.36-2.53,12.33.23,7.1,4.93,10.4,11.88,10.8,20.36Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m478.96,713.93c-.45,8.99-9.21,31.02-19.97,29.02-2.33-.99-2.81-3.27-2.97-5.51-.2-10.83,4.95-21.9,13.21-28.9,6.3-4.55,9.03-1.31,9.73,5.4Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m438.65,815.86c.36-5.78,2.23-10.85,4.86-15.67,2.81-5.01,6.29-10.25,11.78-12.56,2.72-1.01,4.98.14,5.3,3.04,3.43,13.43-19.94,44.5-21.94,25.19Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m503.83,711.84c1.39-10.18,4.71-19.11,12.4-25.85,3.76-3.3,7.05-2.06,7.41,2.87.17,9.87-3.87,19.88-11.01,26.8-5.57,4.67-8,2.88-8.8-3.83Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m505.49,765.64c-1.06,9.28-5.51,19.29-13.21,25.06-3.45,2.2-5.91.97-6.11-3.12.1-9.75,4.96-19.47,12.12-25.99,4.5-3.63,7.45-1.49,7.2,4.05Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m416.75,627.53c8.59.72,17.72,2.1,24.43,7.84,2.09,2.04,1.79,4.34-.93,5.28-8.74,2.91-18.36,1.21-26.72-2.22-2.68-1.6-7.79-3.07-7.46-6.91.32-1.67,1.74-2.37,3.17-2.69,2.48-.56,5.01-.87,7.52-1.29Z"/>
        </g>
      </g>
    </svg>
  )
}