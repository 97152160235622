import { ChangeEventHandler } from "react";
import { ChatIcon } from "../assets/ChatIcon";
import { ProfileIcon } from "../assets/ProfileIcon";
import { MailIcon } from "../assets/MailIcon";
import { LockIcon } from "../assets/LockIcon";

const TextFieldGroupStyle: { [key: string]: React.CSSProperties } = {
    error: {
        border: "#E8C305 solid 2px", // branding yellow
    },
    valid: {

    },
    fieldGroup: {
        marginBottom: "1rem",
        display: "flex",
        flexDirection: "column",
        // border: "white solid 3px",
    },
    fieldGroupLabel: {
        // display: "flex",
        color: "#ffffff",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "13px",
        textAlign: "end",
        // border: "white solid 3px",
    },
    fieldGroupBox: {
        display: "flex",
        // alignItems: "center",
        background: "#181c27",
        border: "2px solid #42404b",
        borderRadius: "6px",
        padding: "1rem",
        margin: "0.2rem 0 0 0", // TRBL
    },
    fieldGroupInput: {
        display: "flex",
        flexGrow: "1",
        background: "#181c27",
        border: "none",
        borderRadius: "6px",
        // marginTop: "0.5rem",
        marginLeft: "0.5rem",
        fontFamily: "Open Sans",
        fontWeight: "400",
        fontSize: "12px",
        color: "#c5c5c5",
    },
    blurred: {
        color: "transparent",
        textShadow: "0 0 2px rgba(197, 197, 197, 0.5)",
    }
};

type Props = {
    disabled?: boolean,
    id?: string,
    name?: string,
    reference?: React.MutableRefObject<null> | undefined,
    value: string,
    placeholder?: string,
    onChange: ChangeEventHandler<HTMLInputElement> | undefined,
    onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined,
    icon: "profile" | "email" | "message" | "none",
    iconSize?: number,
    requiredText?: string,
    className?: string,
}

export const TextFieldGroup = ({
    disabled=false,
    id="",
    name="",
    reference = undefined,
    value,
    placeholder = "",
    onChange,
    icon,
    iconSize = 19,
    requiredText = "",
    className = "",
}: Props) => {
    return (
        <div 
            style={TextFieldGroupStyle.fieldGroup}>
            <p style={{
                ...TextFieldGroupStyle.fieldGroupBox,
                // something ? TextFieldGroupStyle.valid : TextFieldGroupStyle.error
            }} className={className}>
                {icon == "profile" ? <ProfileIcon size={iconSize} /> : null}
                {icon == "email" ? <MailIcon size={iconSize} /> : null}
                {icon == "message" ? <ChatIcon size={iconSize} /> : null}
                <input
                    disabled={disabled}
                    ref={reference}
                    id={id}
                    name={name}
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    style={{
                        ...TextFieldGroupStyle.fieldGroupInput,
                        ...(disabled ? TextFieldGroupStyle.blurred : undefined),
                    }}
                />
                {disabled ? <LockIcon size={iconSize} /> : null}
            </p>
            {requiredText ?
                <label style={TextFieldGroupStyle.fieldGroupLabel} htmlFor="message">
                    *{requiredText}
                </label>
                :
                null
            }

        </div>
    );
};

