export const SmallFlame16 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="287.41 675.16 314.01 643.43 488.67 592.3 548.22 768.79 376.25 833.33 338.74 830.57 287.41 675.16"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m552.05,768.25l-12.55-36.8c21.73-54.82,1.83-117.16-50.47-143.38-21.19-11.5-40.08-25.89-60.47-38.97-13.97,5.29-27.55,11.53-40.86,18.37-30.72,7.05-60.86,15.08-88.65,30.66-1.62.84-3.27,1.62-4.42,3.15-15.93,21.12-31.38,43.18-36.47,69.76-19.67-4.14-32.56-15.89-41.79-34.19-10.01,52.31,20.02,100.07,39.28,146.48,48.37,93.36,186.35,74.55,247.56,4.61l48.01-17.92c.71-.27,1.09-1.05.84-1.77Zm-181.15,60.65l-31.12-1.66c-.58-.03-1.08-.41-1.26-.96l-46.34-138.9c-1-3.01-1.99-6.01-2.99-9.02-.09-.41,0-.83.26-1.16l21.31-26.95c.69-.88,2.08-.63,2.44.43l59.1,176.37c.32.94-.42,1.91-1.41,1.85Zm173.62-62.48l-163.18,60.26c-.74.28-1.57-.12-1.82-.87l-60.37-179.27c-.26-.76.17-1.58.95-1.8l166.64-47.98c.72-.21,1.48.19,1.72.9l56.9,166.98c.25.72-.13,1.51-.85,1.78Z"/>
          <ellipse fill={props?.color ?? "#e8c305"} cx="435.89" cy="707.78" rx="18.5" ry="19.85" transform="translate(-208.53 182.73) rotate(-19.2)"/>
          <path fill={props?.color ?? "#e8c305"} d="m301.66,692.86c-1.83.54-1.06,8.59,1.72,17.99,2.78,9.39,6.51,16.57,8.34,16.03,1.83-.54,1.06-8.59-1.72-17.99-2.78-9.39-6.51-16.57-8.34-16.03Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m342.8,767.47c-2,.7-.74,9.54,2.82,19.76,3.56,10.22,8.06,17.93,10.06,17.23,2-.7.74-9.54-2.82-19.76-3.56-10.22-8.06-17.93-10.06-17.23Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m285.67,426.31c-10.46-18.45-17.46-38.1-40.46-45.28-1.88,8.6.39,15.3,6.14,20.82,10.91,9.42,19.76,20.16,34.31,24.47Z"/>
        </g>
      </g>
    </svg>
  )
}