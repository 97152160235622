export const NoFlame47 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="290.35 770.35 309.43 791.6 487.94 844.21 544.31 665.85 368.84 602.39 340.86 615.9 290.35 770.35"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <g>
            <path fill={props?.color ?? "#e8c305"} d="m368.46,601.14c.52-.53,1-.02,1.43.13,24.25,8.74,48.48,17.5,72.72,26.26,21.78,7.87,43.55,15.74,65.33,23.61,12.37,4.47,24.72,8.96,37.11,13.37,1.5.53,1.11,1.28.8,2.26-8.31,26.19-16.61,52.38-24.91,78.57-10.44,32.92-20.88,65.85-31.26,98.79-.5,1.6-1.06,1.78-2.59,1.33-31.33-9.31-62.67-18.56-94.02-27.82-28.13-8.31-56.26-16.61-84.38-24.93-.79-.23-1.66-.29-2.3-.9.1-.46.17-.92.31-1.37,2.41-7.41,4.82-14.82,7.23-22.23,6.82-20.88,13.65-41.76,20.47-62.64,8.56-26.2,17.11-52.4,25.66-78.6,2.81-8.6,5.6-17.2,8.4-25.81Zm171.15,68.29c.38-1.48-.66-1.41-1.24-1.62-21.92-7.9-43.84-15.78-65.77-23.66-32.86-11.81-65.73-23.62-98.58-35.47-1.27-.46-1.67-.19-2.06,1.04-7.65,23.55-15.33,47.08-23.01,70.61-11.57,35.42-23.14,70.85-34.74,106.26-.39,1.19-.3,1.66,1.03,2.05,9.65,2.8,19.28,5.7,28.91,8.57,46.46,13.82,92.92,27.65,139.39,41.46,2.43.72,2.06,1.08,2.92-1.62,7-22.05,13.98-44.1,20.97-66.15,9.95-31.38,19.91-62.75,29.86-94.13.81-2.54,1.61-5.09,2.32-7.34Z"/>
            <path fill={props?.color ?? "#e8c305"} d="m371.51,777.18c-11.19-.39-19.14-8.82-19.23-19.38-.09-11.32,9-20.59,19.96-20.58,10.96,0,19.62,8.59,19.62,19.44,0,11.42-9.06,20.56-20.35,20.53Z"/>
            <path fill={props?.color ?? "#e8c305"} d="m420.78,667.02c.12,11-8.34,20.09-19.37,20.08-11.46,0-19.59-9.4-19.66-19.73-.08-11.14,8.47-20.3,19.05-20.26,11.35.05,19.89,8.87,19.98,19.9Z"/>
            <path fill={props?.color ?? "#e8c305"} d="m396.7,772.11c-.34-10.15,7.12-19.92,18.42-20.89,9.97-.86,19.2,7.07,20.02,17.14.93,11.4-7,21.28-18.11,22.19-9.99.82-19.81-6.41-20.34-18.44Z"/>
            <path fill={props?.color ?? "#e8c305"} d="m463.54,682.28c-.15,10.85-8.77,19.61-19.12,19.43-10.68-.19-19.05-9.04-18.89-19.99.15-10.57,8.76-19.52,18.68-19.43,11.06.1,19.48,8.81,19.33,19.99Z"/>
            <path fill={props?.color ?? "#e8c305"} d="m477.38,783.84c-.09,10.94-8.61,19.88-19.36,19.81-8.19-.05-15.33-5.05-17.6-14.24-2.9-11.76,5.86-23.88,17.71-24.52,10.82-.58,19.35,7.81,19.26,18.95Z"/>
            <path fill={props?.color ?? "#e8c305"} d="m468.17,697.99c-.02-12.36,8.36-21.09,19.14-20.85,7.64.16,14.62,5.18,17.11,13.56,3.11,10.46-3.17,22.96-14.91,24.99-6.98,1.21-14.71-2.29-18.39-8.51-1.9-3.22-2.99-6.68-2.95-9.18Z"/>
          </g>
          <path fill={props?.color ?? "#e8c305"} d="m308.28,786.67s-16.76-15.76-16.99-16.58,49.09-153.29,49.65-153.89,25.98-10.34,25.98-10.34c0,0,3.62-5.79,1.54-4.72s-26.37,13.48-27.27,13.91-50.96,153.15-51.16,153.95-.49,1.73.32,2.74,16.02,20.06,16.02,20.06l1.91-5.12Z"/>
          <ellipse fill={props?.color ?? "#e8c305"} cx="332.68" cy="654.43" rx="17.27" ry="2.25" transform="translate(-394.66 754.09) rotate(-70.86)"/>
          <ellipse fill={props?.color ?? "#e8c305"} cx="316.55" cy="738.67" rx="19.39" ry="2.25" transform="translate(-482.62 823.36) rotate(-72.85)"/>
        </g>
      </g>
    </svg>
  )
}