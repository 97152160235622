export const BigFlame37 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="273.98 683.19 365.4 586.78 521.73 618.28 522.23 752.04 458.83 866.83 304.48 816.88 273.98 683.19"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m507.37,779.92c-.05.05-.1.1-.16.15l.07.07c.03-.07.06-.14.09-.22Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m561.07,665.18c-3.03-77.64-76.71-86.45-137.25-68.8-21.97-4.37-43.33-8.44-58.89-11.01-8.16,8.57-16.75,17.19-25.48,25.84-27.12,3.72-69.34,15.68-83.57-13.42-9.33,44.62-19.56,88.06-17.64,133.56-8.83-2.17-15.66-5.36-18.83-14.57-11.09,24.58,7.48,43.7,25.53,57.35,32.35,40.93,70.79,72.61,126.11,65.61,35.77,11.42,73.56,23.49,86.04,27.5,1.24.4,1.89.34,2.55-.95,15.76-30.1,31.74-58.71,48.29-87.83-.2.48-.39.97-.57,1.46,31.28-29.13,58.48-69.92,53.7-114.74Zm-196.19-75.1c.42-.44.78-1.04,1.97-.75,49.28,9.54,98.79,19.33,148.1,28.88,1.27.24,1.7.48.81,1.8-23.13,34.3-47.53,69.36-70.61,103.71-37.87-5.96-149.61-37.21-166.65-42.07,28.16-30.66,57.73-61.03,86.38-91.56Zm87.41,272.27c-48.09-15.29-96.18-30.57-144.29-45.79-1.4-.44-1.99-1.14-2.31-2.52-9.76-41.55-19.88-83.01-29.57-124.57,55.41,14.19,110.59,28.4,165.97,42.6,1.24.31,1.55.91,1.63,2.04,2.97,39.34,6.11,78.66,9.21,117.98.24,3.02.48,6.05.77,9.07.15,1.6.11,1.67-1.41,1.19Zm-.63-131.3c21.64-35.98,46.68-70.71,69.38-106.31l-.24,126.98c-20.07,36.07-39.8,72.32-60.07,108.27-3.03-43.17-5.98-85.84-9.08-128.93Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m230.69,371.37c17.74-14.77,34.94-51.43,8.61-65.93-15.93-7.51-33.73-10.7-47.37-22.72-.12-.1-.54.14-1.03.29,26.08,69.46-49.4,85.52,7.55,149.4,6.03-22.92,14.09-44.49,32.25-61.04Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m323.31,461.32c-.17-38.44-38.07-28.01-57.14-50.58-.59-.63-.97-1.82-2.54-1.28,11.93,56.32,28.04,31.52,59.68,51.86Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m507.29,780.14s-.07-.07-.07-.07c-.09.17-.18.34-.19.35,0,0,.13-.14.26-.28Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m313.4,718.75c-4.73.19-7.86,2.98-8.27,7.39-2.17,14.38,31.43,35.31,33.58,14.36-1.26-11.91-13.61-21.64-25.31-21.76Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m359.92,764.03c-5.05-.21-8.87,2.68-9.14,7.77,1.24,11.88,12.8,20.42,24.28,21.14,7.75.51,11.06-5.21,8.68-11.85-3.71-9.65-13.56-16.56-23.82-17.06Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m421.24,814.92c-4.87-4.04-10.34-6.68-16.78-6.93-13.91-.26-9.94,14.73-2.03,20.4,6.81,7.54,30.19,12.72,25.79-3.99-1.38-3.85-3.89-6.9-6.99-9.47Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m421.32,697.66c6.28-.28,12.56-1.7,17.61-6.33,2.81-2.58,4.67-5.75,3.38-9.79-5.4-11.81-24.69-10.59-34.07-4.32-2.82,1.7-5.06,4.13-5.67,7.57-1.45,9.3,10.87,13.04,18.76,12.86Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m395.16,681.85c12.31-9.98-.9-19.64-12.94-18.79-5.99.24-12.37,1.67-17.6,6.24-15.94,17.94,21.3,22.5,30.54,12.56Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m336.12,677.25c8.89-.3,20.5-3.09,22.95-12.71.5-10.71-15.46-11.88-23.3-9.73-18.88,4.07-21.3,21.69.35,22.44Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m448.92,642.31c8.82,3.36,19.98,2.79,27.37-3.46,3.64-3.34,4.54-7.04,1.19-10.83-3.91-4.06-9.94-5.08-15.34-5.2-6.28,0-12.65,1.56-17.45,5.85-5.36,4.61-1.57,11.75,4.22,13.65Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m416.54,635.57c7.75.6,23.13-2.37,22.46-12.5-1.91-6.97-10.19-8.02-16.84-8.15-6.01.08-13.24,1.82-17.55,6.59-6.99,7.81,4.83,14.26,11.93,14.06Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m380.17,627.28c6.27,0,13.42-1.85,17.98-6.54,8.6-10.81-10.45-15.15-18.18-13.13-19.41,2.78-21.62,19.68.2,19.67Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m480.28,725.74c.42-10.68-5.14-11.81-9.79-2.42-4.25,9.1-7.54,19.73-5.04,29.72,1.07,2.69,2.95,3.17,5.1,1.24,6.94-7.64,9.07-18.5,9.73-28.53Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m476.63,786.39c-5.38,7.89-7.52,16.8-8.22,26.16-.04,12.09,6.55,6.49,9.45-.7,3.31-8.12,6.68-17.53,4.35-26.3-1.1-3.3-4.31-1.17-5.57.84Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m490.76,727.77c-4.16,7.45-6.15,15.5-6.59,23.97-.32,9.78,4.49,10.24,8.48,1.84,2.97-5.97,4.65-12.33,5.35-18.92.32-3,.52-6.08-.56-9.02-1.55-4.36-5.47-.31-6.68,2.13Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m513.31,672.32c.51-8.78-3.57-12.42-8.12-3.07-3.86,8.86-7.13,19.32-4.38,28.86,6.16,8.89,13.44-21.81,12.5-25.79Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m511.83,729.74c-6.87.79-10.46,20.58-10.7,26.09,0,2.96.2,9.79,4.33,6.49,4.32-5.03,6.07-11.74,7.43-18.09.54-3.54,2.47-12.33-1.06-14.49Z"/>
        </g>
      </g>
    </svg>
  )
}