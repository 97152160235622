export const NoFlame48 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="285.38 762.31 311.34 798.83 485.82 849.63 547.92 675.39 374.83 609.59 338.87 608.58 285.38 762.31"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <g>
            <path fill={props?.color ?? "#e8c305"} d="m284.96,762.51c.77-.11,1.3-4.27,2.03-3.75.91.65,22.84,33.78,23.43,33.69.67-.1.54-.78.7-1.26,1.99-5.74,54.42-155.59,61.44-175.65.74-2.11.72-2.03-1.56-2.18-5.52-.36-33.1-1.69-33.1-1.69,0,0,1.39-3.92,1.34-4.49,4.82.11,28.38.74,34.4.9,1.43.04,2.77.29,4.12.8,25.56,9.7,138.92,52.57,170.02,64.35,2.39.9,2.15.5,1.34,2.79-19.44,54.62-60.68,170.14-61.7,173.33-.44,1.37-.98,1.61-2.33,1.21-13.45-4-165.1-48.49-174.26-51.14-1.08-.31-1.8-.86-2.41-1.79-3.88-5.9-23.18-34.65-23.47-35.14Zm258.32-84.8c.04-.72-.65-.67-1.07-.83-11.46-4.32-22.94-8.63-34.41-12.94-41.91-15.76-83.82-31.51-125.72-47.27-2.28-.86-2.28-.87-3.07,1.38-20.51,58.54-41.02,117.08-61.56,175.61-.46,1.32-.27,1.72,1.07,2.11,27.48,8.08,54.94,16.21,82.4,24.33,27.21,8.05,54.43,16.08,81.63,24.16,1.08.32,1.45.05,1.79-.95,1.55-4.5,3.17-8.98,4.77-13.47,17.85-50.1,35.69-100.2,53.54-150.29.21-.59.41-1.19.63-1.84Z"/>
            <path fill={props?.color ?? "#e8c305"} d="m325.33,727.55c-.53,6.9-2.53,13.44-4.99,19.85-1.26,3.28-2.66,6.49-4.67,9.4-.41.6-.83,1.22-1.35,1.73-.43.42-.91,1.01-1.61.79-.74-.24-.86-.95-.94-1.65-.4-3.23.25-6.35.97-9.45,1.81-7.77,4.27-15.31,8.46-22.19.49-.8,1.04-1.57,1.76-2.2.43-.37.85-.9,1.48-.64.64.26.82.89.86,1.56.02.47.15.93.15,1.4,0,.47-.08.94-.12,1.4Z"/>
            <path fill={props?.color ?? "#e8c305"} d="m340.23,635.4c-.53,6.9-2.53,13.44-4.99,19.85-1.26,3.28-2.66,6.49-4.67,9.4-.41.6-.83,1.22-1.35,1.73-.43.42-.91,1.01-1.61.79-.74-.24-.86-.95-.94-1.65-.4-3.23.25-6.35.97-9.45,1.81-7.77,4.27-15.31,8.46-22.19.49-.8,1.04-1.57,1.76-2.2.43-.37.85-.9,1.48-.64.64.26.82.89.86,1.56.02.47.15.93.15,1.4,0,.47-.08.94-.12,1.4Z"/>
            <path fill={props?.color ?? "#e8c305"} d="m426.13,675.03c0,10.81-8.27,19.72-18.85,20.32-11.5.66-20.93-9.34-19.79-21.41,1.02-10.79,9.1-18.51,19.4-18.59,11.13-.09,19.34,8.73,19.24,19.67Z"/>
            <path fill={props?.color ?? "#e8c305"} d="m376.04,745.05c10.73.02,18.87,8.17,18.87,18.91,0,11.11-9.4,20.57-20.62,20.4-10.65-.15-18.51-8.01-18.51-18.59,0-11.74,9.24-20.63,20.26-20.73Z"/>
            <path fill={props?.color ?? "#e8c305"} d="m467.9,689.64c.08,9.41-6.08,17.57-14.97,19.86-8.31,2.15-17.13-2.11-20.67-9.97-4.45-9.88-.81-21.3,8.73-26.51,9.98-5.45,22.25-.55,25.9,10.3.69,2.05,1.1,4.14,1.02,6.32Z"/>
            <path fill={props?.color ?? "#e8c305"} d="m419.08,758.9c11.39.12,19.35,9.61,17.75,21.02-1.44,10.22-10.6,18.46-21.22,17.52-8.31-.74-14.67-6.14-16.14-14.87-1.7-10.11,4.59-19.96,14.34-22.91,1.71-.52,3.47-.77,5.26-.75Z"/>
            <path fill={props?.color ?? "#e8c305"} d="m488.99,724.22c-10.97-.14-18.74-10.63-16.79-21.69,1.18-6.68,4.65-11.74,10.66-14.83,10.02-5.16,21.8.31,24.74,11.21,2.82,10.43-3.58,22.66-14.73,24.94-1.28.26-2.57.45-3.88.37Z"/>
            <path fill={props?.color ?? "#e8c305"} d="m441.17,792.43c.03-10.65,8.4-19.69,18.9-20.15,8.49-.37,15.63,5.33,17.4,13.91,2.13,10.33-5.06,21.44-15.38,23.58-10.98,2.28-21.04-5.53-20.93-17.35Z"/>
          </g>
          <path fill={props?.color ?? "#e8c305"} d="m284.96,762.51l54.29-155.31.75.02s-33.45,95.85-34.84,99.28-19.8,56.63-19.8,56.63l-.4-.61Z"/>
        </g>
      </g>
    </svg>
  )
}