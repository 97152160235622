export const SmallFlame09 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="311.72 618.87 442.98 577.14 560.73 691.17 491.36 796.62 373.94 867.2 271.25 741.13 311.72 618.87"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m562.53,690.86c-9.6-9.23-19.17-18.49-28.73-27.76-7.97-38.01-36.82-64.12-67.34-86.08-24.8-22.37-53.15-41.69-86.88-46.48-23.25,31.9-87.05,44.59-105.54,2.03-6.73-12.56-9.28-26.63-16.47-38.92-41.72,39.96-114.19,144.39-33.53,181.33,10.11,3.68,19.54,9.07,27.72,16.02-14.98,81.27,43.33,138.15,97.51,147.82,8.31,10.04,16.63,20.08,24.96,30.12,19.24-11.86,38.89-23.29,58.41-34.86,29.5-1.12,96.24-65.65,95.36-91.02,11.53-17.38,23.07-34.75,34.54-52.2Zm-6.1,5.66s0,0,0,0t0,0s0,0,0,0Zm-114.64-116.81c.97-.31,1.6-.23,2.35.51,37.06,36.28,74.43,72.29,111.42,108.63-37.87,19.41-75.91,38.48-113.77,57.89-1.71.88-4.76,2.14-6.3.49-39.53-43.12-78.53-84.86-117.95-128.08,24.13-7.65,106.88-33.91,124.24-39.44Zm-69.31,282.48c-26.66-32.02-92.55-111.52-98.97-119.19-.72-.86-.87-1.57-.5-2.66,12.96-38.53,25.76-77.1,38.73-115.64,28.18,30.29,106.81,116.65,118.37,129.33.62.68.75,1.18.28,2.04-19.35,35.36-38.55,70.76-57.91,106.13Zm67.85-105.44c38.67-19.95,77.14-40.39,115.94-60.05,0,0,.02.02.02.03-20.6,32.49-42.29,64.39-63.36,96.62-1.39,1.61-2.79,3.06-4.31,4.44-35.32,22.75-72.09,44.11-108.47,65.34,20.34-35.19,38.78-72.09,60.17-106.38Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m458.38,661.44c.38-2.65-.37-5.13-1.79-7.33-5.2-8.04-12.89-11.75-22.13-12.64-8.81-.05-14.64,5.34-10.72,13.96,4.37,10.99,29.99,21.53,34.65,6.01Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m384.26,733.08c-7.31-3.48-12.91,2.78-12.82,9.71-.23,10.84,5.66,22.5,16,26.65,7.78,2.71,12.14-4.63,11.89-11.2-.4-10.13-5.72-20.63-15.08-25.16Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m295.4,731.34c-.63,1.85-.95,3.82-1.4,5.73.86,9.29,5.07,19.12,13.6,23.59,4.05,1.84,7.77.15,9-4.14,2.05-8.57-1.43-17.13-6.84-23.69-3.06-4.42-11.78-8.36-14.36-1.49Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m466.5,755.22c-8.55,2.66-15.81,9.32-21.59,16.03-4.07,3.97-10.28,16.06,1.04,10.89,9-4.72,16.24-11.42,21.76-19.93,1.31-2.36,3.79-7.13-1.21-6.98Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m501.49,751.07c8.81-3.39,15.83-10.02,21.53-17.37,3-3.87,7.43-12.48-1.74-8.3-7.35,3.99-13.27,9.62-18.21,16.28-1.5,2.02-2.56,4.41-3.53,6.74-.58,1.4.34,2.34,1.95,2.65Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m431.36,811.07c-4.31.76-29.47,22.48-20.29,24.63,9.41-3.33,17.03-10.16,23.12-17.88,3.46-4.82,5.81-10.55-2.83-6.74Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m490.06,784.64c2.7-3.49,5.51-9.85-1.86-6.29-6.9,3.59-12.48,8.77-17.3,14.77-1.11,1.89-6.97,8.61-1.65,8.51,8.37-3.21,15.34-9.99,20.81-17Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m343.01,462.01c7.29-14.39,14.7-32.99-1.61-44.7-6.99,15.72-24.72,36.24,1.61,44.7Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m149.86,453.62c-2.99-2.36-6.19-5.34-10.77-1.21,5.38,7.63,10.84,14.79,18.93,19.8,2.96-6.25.41-11.83-8.16-18.59Z"/>
        </g>
      </g>
    </svg>
  )
}