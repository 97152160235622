export const BigFlame24 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_2" data-name="Layer 2">
        <polygon points="304.39 627.8 458.99 573.96 517.47 698.9 525.76 819.26 360.52 859.41 273.71 741.31 304.39 627.8"/>
      </g>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m522.82,766.4c42.88-44.02,61.76-112.71,18.15-162.11-1.25.69.11,2.58-1.95,2.76-10.88-11.16-25.22-17.53-38.15-25.93-27.33-14.42-6.88-63.87-40.99-68.69-32.57-1.32-48.73-6.15-72.57-28.22,5.01,15.03,10,30.01,15.46,46.42-41.73-15.48-81.96-30.14-123.7-44.04,22.57,46.69,18.52,91.19-3.28,135.53-58.51-10.42-53.15-103.7-80.28-139.94,16.23,65.85-57.22,141.99,2.64,197.62,44.45,41.54,13.33,79.3,46.04,120.32,24.49,31.19,63.24,42.58,101.76,42.93,4.31,5.83,8.63,11.65,12.93,17.48,54.1-9.51,109.88-27.36,164.5-39.61,3.14-.78,2.7-.46,2.67-3.59-1.07-16.94-2.17-33.93-3.24-50.93Zm-73.39-82.31c-57.37,17.21-114.68,35.58-172.06,52.31,9.21-35.69,18.68-71.33,27.91-107.03.31-1.18,1.02-1.53,2.01-1.87,48.48-16.65,96.93-33.36,145.38-50.06,1.8-.62,1.83-.61,1.77,1.2-.6,19.93-1.25,39.86-1.94,59.79-.49,14.48-.92,29-1.47,43.47.07,1.27-.38,1.83-1.6,2.2Zm68,134.46c-51.65,12.94-103.33,25.8-154.97,38.78-28.21-36.12-55.02-74.7-82.64-111.72-.65-.9-.64-1.19.5-1.53,19.5-5.86,38.99-11.75,58.48-17.66,37.62-11.45,75.28-22.79,112.91-34.18,1.15-.35,1.54.02,2.02.94,21.52,41.1,43.12,82.15,64.68,123.23.88,1.67.87,1.69-.97,2.15Zm-58.4-134.04c.55-34.7,1.67-69.39,2.01-104.09.18-.03.36-.06.53-.08,18.11,40.89,39.66,80.93,55.94,122.32,1.79,36.2,5.14,72.5,6.13,108.65-21.17-42.34-45.05-83.94-64.61-126.8Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m402.66,768.63c-23.23-.24-25.6,23.07-2.8,26.93,27.93,3.81,30.95-25.25,2.8-26.93Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m417.72,653.96c-11.09.64-22.87,7.13-26.86,17.88-2.1,5.98,3.98,10.04,9.4,9.53,10.85-.76,22.25-6.81,26.54-17.19,2.45-6.39-3.08-10.57-9.08-10.22Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m382.67,640.54c-7.83-1.46-15.38,2.27-21.48,6.84-7.39,4.9-13.4,18.27.34,18.27,5.2-.06,11.71-2.46,16.68-6.15,5.85-3.56,15.11-15.63,4.45-18.96Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m344.95,627.85c-4.68.11-8.91,1.75-12.89,4.03-5.62,2.76-18.09,14.08-9.12,19.08,13.84,5.52,42.74-21.37,22.02-23.12Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m476.9,678.63c-.95-2.31-5.79-11.34-7.47-5.22-.8,11,3.16,21.95,8.07,31.65,2.68,5.3,6.5,6.45,6.18-.82-.48-8.91-3.12-17.51-6.78-25.61Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m501.99,737.81c-.67,8.72,2.15,17.37,5.18,25.44,1.07,3.44,6.91,14.26,7.49,4.61-.36-9.22-2.89-17.98-6.62-26.36-.75-2.1-5.1-9.22-6.05-3.69Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m485.85,677.75c-.9,5.22.63,10.55,1.89,15.59.33,3.61,10.3,28.77,10.92,13.78,1.14-3.1-8.74-37.97-12.82-29.36Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m483.01,648.89c-.9-9.36-3.57-18.21-7.89-26.56-.84-1.77-4.38-7.32-5.37-2.83-.55,10.13,3.38,20.06,7.74,29.06,1.86,3.9,5.62,7.17,5.53.33Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m511.71,709.86c.75-2.94-7.84-35.23-11.73-27.92-2.94,3.38,10.45,44.76,11.73,27.92Z"/>
        </g>
      </g>
    </svg>
  )
}