import { BarDiceLogo } from "../../assets/BarDiceLogo";
import { moneyFormatter } from "../../../../utils/moneyFormatter";
import { useNavigate } from "react-router-dom";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { AccountIcon } from "../../../../assets/icons/AccountIcon";
import { useEffect, useState } from "react";
import { i18nEn, i18nPt } from "../assets/i18n"
import { Jackpot } from "../../assets/jackpot/Jackpot";
import { Ticket } from "../../assets/jackpot/Ticket";

import Mecca100 from "../assets/mecca100SignInLogo.png";

const Style = {
  nav: {
    display: "flex",
    justifyContent: "space-between",
  },
  navSectionLogo: {
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    flexShrink: "1",
    flexBasis: "33%",
  },
  navLogo: {
    minWidth: "95px",
    maxWidth: "100%",
    height: "auto"
  },
  navSectionJackpot: {
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "1",
    flexBasis: "33%",
  },
  navSectionWallet: {
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "1",
    flexBasis: "23%",
  },
}

export const BarDiceNav = (props) => {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const auth = useAuthUser();
  const user = props.user;
  const balance = props.balance;
  const i18n = props.jogo ? i18nPt: i18nEn;

  useEffect(()=>{
    if (props?.jackpot!==undefined) {
      props.setModalData(
        <div id="jackpotModal">
          <div className="jackpotCenterContainer">
            {/* <div style={{position: "relative"}}>
              <Jackpot
                w={"100%"}
                h={"30vh"}
                amount={
                  moneyFormatter(Math.floor((
                    props.jackpot===0 
                    ? localStorage.getItem("jackpot") 
                    : props.jackpot
                    ) / 100
                  ), i18n.currency)
                }
                fontSize={"10vw"}
                shieldPattern={"spin"}
                setModalOpen={()=>{props.setModalOpen(false)}}
              />
              <Ticket
                tickets={props.tickets} 
                setModalOpen={()=>{props.setModalOpen(false)}}
                entries={i18n.entries}
              />
            </div> */}
            <div className="jackpotRules">
              <div>
                <div className="bold">{i18n.jackpotRulesTitle}:</div>
                <ul>
                  <li>{i18n.jackpotRules1}</li>
                  <li>{i18n.jackpotRules2}</li>
                  <li>{i18n.jackpotRules3}</li>
                  <li>{i18n.jackpotRules4}</li>
                </ul>
              </div>
              <div>
                <div className="bold">{i18n.jackpotTerms}:</div>
                <ul>
                  <li>{i18n.jackpotKeepAnEye}</li>
                </ul>
              </div>
              <div className="jackpotModalCloseButton" onClick={()=>{
                props.setModalOpen(false)
                console.log("modal test")
              }}>
                {i18n.returnHome}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }, [props.jackpot])

  return (
    <nav style={Style.nav}>
      <div style={Style.navSectionLogo}>
        <img style={Style.navLogo} src={Mecca100} alt="Mecca 100" onClick={() => navigate("/")}></img>
      </div>
      <div style={Style.navSectionJackpot}>
        <Jackpot 
          w={90} 
          h={30} 
          amount={
            moneyFormatter(Math.floor((
              props.jackpot===0 
              ? localStorage.getItem("jackpot") 
              : props.jackpot
              ) / 100
            ), i18n.currency)
          }
          shieldPattern={"spin"}
          fontSize={"12px"}
          setModalOpen={props.setModalOpen}
        />
      </div>
      <div style={Style.navSectionWallet} className="barDiceNavWallet" onClick={() => navigate(
        "/cashier", 
        {
          state: { 
            venue: props.selectedLocation,
            suggestDeposit: (balance < 5) 
          }
        }
        )}>
          {props.balance < 1 && props?.bet === 0
            ? i18n.balanceDeposit
            : moneyFormatter(balance, i18n.currency, true)
          }
      </div>
      <div
        className="row username"
        onClick={() => {
          navigate("/account");
        }}
      >
        {/* {props?.jackpot==0 ? (
          <div className="colCenter">{props.user.username}</div>
        ) : (<></>)} */}
        <div className="colCenter right"><AccountIcon/></div>
      </div>
    </nav>
  );
};
