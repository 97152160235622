export const SmallFlame54 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="313.51 824.18 446.05 855.56 559.25 739.15 483.37 632.06 365.66 571.74 267.7 699.88 313.51 824.18"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m559.67,738.16c-6.79-9.68-13.57-19.37-20.35-29.06,9.49-48.53-16.68-98.05-60.13-121.45-18.68-10.08-38.48-18.09-57.76-27.06-1.63-.76-3.22-.51-4.88-.28-18.15,1.63-35.08,9.34-53.21,10.54-29.71,2.03-62.67,2.98-89.1,17.13-23.17,31.8-46.74,66.67-42.11,108.08-10.56-4.87-17.69-12.78-24.99-21.31-10.78,51.73,19.37,99.24,56.01,132.35,35.81,28.45,83.41,39.78,129.49,37.26,18.09,4.24,36.18,8.48,54.27,12.71,38.08-37.35,74.84-77.74,112.54-116.03.99-1.03,1.06-1.68.22-2.87Zm-288.43-39.67c17.15-22.29,34.27-44.6,51.4-66.91,13.96-18.03,27.9-36.03,41.82-54.01.29.09.53.69,1.14,1.74,20.38,35.21,40.72,70.44,61.12,105.63.61,1.04.51,1.71-.26,2.61-37.16,43.29-74.09,86.8-111.25,130.1-7.94-17.99-36.75-96.47-44.37-116.62-.38-1.01-.25-1.69.41-2.55Zm38.48,117.67c1.03,2.79,2.04,5.53,3.02,8.23.11.3.24.55.39.76-.09-.02-.18-.04-.27-.06-1.04-2.98-2.09-5.95-3.13-8.92Zm138.59,35.94c-.81.85-1.52,1.12-2.71.84-40.91-9.83-81.85-19.57-122.78-29.32-1.81-.43-1.82-.44-.6-1.86,11.44-13.3,22.89-26.6,34.33-39.89,25.58-29.74,51.21-59.44,76.77-89.19.62-.72,1.12-.67,1.89-.35,39.47,16.69,79.44,32.74,118.62,49.82-35.08,36.73-70.48,73.17-105.52,109.94Zm-8.24-166.5c-3.2-1.36-5.34-3.27-7.1-6.28-19.47-33.83-40.25-67.26-59.38-101.12,37.59,19.67,78.13,36.17,113.15,59.12,3.74,5,7.43,10.04,11.08,15.12,13.64,19.85,27.28,39.7,40.93,59.53.02-.11.04-.22.07-.33,5.29,7.69,10.59,15.35,15.98,22.94-38.33-16.32-76.39-32.73-114.72-48.99Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m339.05,758.03c4.01-10.68-1.94-28.75-14.84-17.49-5.75,5.73-8.19,12.83-8.63,19.97.43,23.39,19.93,13.54,23.47-2.49Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m365.11,625.21c.01-6.58-6.07-12.04-12.63-7.27-7.77,5.52-11.12,13.43-11.81,22.66,1.77,24.39,26.43,3,24.45-15.39Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m430.43,753.26c3.88-.11,7.15-.79,10.25-2.13,11.41-3.15,25-23.39,6.94-27.46-18.91-4.21-43.72,26.19-17.2,29.59Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m462.15,764.25c9.17.02,19.5-6.15,23.71-13.96,5.01-8.49-1.86-15.49-10.19-14.49-9.55.82-18.99,6.6-22.97,15.51-3.5,7.17,2.66,13.26,9.45,12.95Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m370.4,794.78c-10.21,7.17-10.85,20.61,4.09,20.75,6.18-.18,11.8-2.2,16.85-5.86,3.32-2.4,6.06-5.3,7.52-9.22,4.22-16.71-20.89-13.06-28.46-5.68Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m492.17,774.43c6.04-.17,11.29-2.49,15.97-6.15,6.34-4.08,13.05-16.57,3.06-20.22-11.36-3.17-26.14,5.77-28.84,17.18-1.3,5.84,4.55,9.67,9.82,9.19Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m420.76,798.1c-9.66.52-18.83,5.26-23.32,13.78-3.53,6.8,1.93,12.03,8.35,12.07,10.31.1,21.74-5.7,25.23-15.83,2.1-6.5-4.81-10.41-10.26-10.02Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m434.47,813.66c-8.39,6.07-10.44,18.22,2.74,18.23,7.18-.39,13.36-3.1,18.59-8.03,2.44-2.3,4.43-4.88,5.13-8.27.87-14.03-20.33-7.86-26.45-1.93Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m438.04,654.92c-4.65-.38-3.58,4.06-2,6.55,5.89,9.26,14.76,17.26,25.3,20.58,7.12,1.58,3.57-6.37,1.08-9.26-6.2-7.69-14.59-15.71-24.39-17.87Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m405.54,602.29c-6.14-1.28-2.64,5.63-.5,7.96,6.01,7.14,14.02,14.38,23.15,16.69,5.27.31,2.45-4.93.72-7.17-6.07-7.62-13.89-14.65-23.37-17.48Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m508.98,688.1c-2.39-1.93-14.19-9.91-13.16-2.32,3.73,9.31,15.55,19.93,25.12,22.6,10.34-.25-8.86-18.64-11.96-20.28Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m463.88,631.62c-3.72-.33-2.71,2.57-1.61,4.51,5.24,8.12,12.31,14.15,21.03,18.27,5.02,2.23,5.81-1.2,3.13-4.87-5.51-7.66-13.47-15.27-22.56-17.91Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m258.02,419.64c13.29,8.71,26.75,17.17,40.27,25.52-8.87-27.87-24.26-52.02-55.71-47.64,2.08,9.41,7.29,16.79,15.44,22.12Z"/>
        </g>
      </g>
    </svg>
  )
}