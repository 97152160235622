export const SmallFlame02 = (props) => {
  return (
    <svg width={props?.w ?? "50"} height={props?.h ?? "50"} viewBox={props?.v ?? "250 450 350 450"} strokeWidth={0}>
      <g id="Layer_3" data-name="Layer 3">
        <polygon points="285.44 625.45 389.32 620.56 530.98 630.62 520.09 799.09 433.28 837.5 282.98 803.7 285.44 625.45"/>
      </g>
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path fill={props?.color ?? "#e8c305"} d="m430.13,630.13c-3.96-1.79-33.38-2.91-36.23-1.37,12.1,1.3,23.93,1.91,36.23,1.37Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m386.12,703.38c6.93,5.52,15.58,4.64,21.14-2.27,3.07-3.82,4.3-8.31,4.27-13.2-.11-6.73-2.25-12.64-7.37-17.19-6.48-5.77-15.99-5.56-21.71,1.82-6.86,8.85-5.09,23.85,3.68,30.84Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m311.57,781.74c6.9,5.71,15.61,3.81,19.47-4.26,4.15-8.65,1.16-20.9-6.5-26.67-6.86-5.17-15.28-3.18-18.82,4.64-1.14,2.51-2.01,8.13-1.86,8.17-.49,6.53,2.67,13.95,7.71,18.12Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m472.81,671.12c-2.39-3.52-5.9-4.09-9.1-1.3-1.35,1.18-2.51,2.69-3.4,4.25-3,5.27-4.03,11.06-4.24,17.06.15,1.77.21,3.55.48,5.3.49,3.18,1.33,6.25,3.43,8.81,2.07,2.53,4.93,3.02,7.66,1.24,2.36-1.54,3.83-3.84,5-6.32,2.82-5.96,3.75-12.27,3.27-18.8-.27-3.63-1.01-7.16-3.11-10.25Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m467.91,761.96c-1.89-1.97-4.39-2.43-6.49-.75-1.84,1.47-3.61,3.23-4.83,5.22-3.69,6.01-4.88,12.75-4.71,19.71.09,3.57.67,7.1,2.78,10.15,1.79,2.59,3.98,3.33,6.77,1.95,1.36-.67,2.66-1.71,3.6-2.9,4.82-6.04,6.17-13.24,6.61-20.63-.13-1.6-.18-3.12-.4-4.61-.45-2.97-1.16-5.87-3.33-8.14Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m514.11,665.17c-1.84-2.82-5.02-3.01-7.2-.77-1.25,1.29-2.43,2.8-3.19,4.42-3.46,7.4-4.26,15.16-2.44,23.11.41,1.78,1.31,3.55,2.36,5.07,1.63,2.36,4.22,2.59,6.4.72,1.02-.87,1.96-1.92,2.66-3.06,3.16-5.21,4.19-10.99,4.16-16.99,0-1.26.1-2.52-.02-3.76-.31-3.09-.98-6.08-2.72-8.75Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m509.38,748.96c-1.49-1.82-3.71-2.28-5.54-.79-1.43,1.16-2.81,2.57-3.71,4.15-3.81,6.66-5.01,13.93-4.08,21.49.27,2.22,1.05,4.5,2.11,6.48,1.52,2.84,4.48,3.19,6.84.94,1.33-1.28,2.51-2.82,3.39-4.45,2.72-5.07,3.8-10.59,3.74-16.32,0-1.2.12-2.42-.02-3.6-.33-2.82-.85-5.6-2.72-7.89Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m489.53,569.93c-36.21-15.58-58.33-46.08-75.02-80.63-2.19-3.73-4.28-7.74-8.36-10.56-10.79,22.44-23.15,42.91-31.02,67.12-15.6,1.19-31.61,3.73-47.28,8.13,3.24,18.49-5.12,46.28-18.13,60.37-27.37-12.73-55.09-24.87-84.81-32.28,38.04,44.04-1.47,98.89,1.46,148.39,12.18,115.91,99.81,144.15,198.81,107.86,2.2.5,4.26.97,6.16,1.4,1.07.24,2.42.08,3.44-.36,28.43-12.49,56.82-25.06,85.24-37.56,1.35-.6,1.81-1.31,1.89-2.75.2-3.66.84-14.9,1.71-29.89,59.46-66.89,49.29-158.84-34.08-199.24Zm41.33,62.87c-.06-.03-.11-.07-.18-.09.06,0,.12.01.18.02,0,.02,0,.05,0,.07Zm-2.19-.31c-1.95-.13-3.9-.25-5.85-.37-1.18.25-73.02,4.78-80.96,5.06-3.8.14-7.63-.3-11.44-.58-6-.44-134.05-10.63-135.11-10.71,0-.09,0-.19,0-.28,1.27-.09,2.53-.17,3.8-.25,13.06-.37,56.42-1.57,78.39-2.18,50.42.33,100.67,3.83,151.18,9.3Zm-164.68-9.31c-16.43.44-32.85.87-49.28,1.31,16.46-.81,32.88-1.23,49.28-1.31Zm64.22,210.23c-47.89-10.7-95.59-21.37-143.48-32.07.88-56.74,1.77-113.43,2.65-170.38,49.65,4.19,99.12,8.36,148.87,12.55-2.68,63.29-5.35,126.46-8.04,189.9Zm91.86-38.7c.05,1.95-.73,2.81-2.46,3.55-21.04,8.99-80.57,34.64-80.85,34.76,0-2.86,7.42-177.48,7.82-186.79.11-2.52.1-2.47,2.63-2.65,12.9-.9,81.21-5.65,82.02-5.65-.27,5-9.22,154.12-9.16,156.78Z"/>
          <path fill={props?.color ?? "#e8c305"} d="m195.45,544.39c-4.04-13.77-14.4-23.36-24.02-33.52-1.97,41.11-3.9,47.68,37.12,62.73q-.07-3.08-1.24-4.72c-5.32-7.5-9.24-15.55-11.86-24.49Z"/>
        </g>
      </g>
    </svg>
  )
}