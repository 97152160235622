import "./App.scss";
import { useEffect, useRef, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useAuthUser, useSignOut, useAuthHeader } from "react-auth-kit";
import { game, server, socket } from "./utils/sockets";
import { fetcher } from "./utils/fetcher";
import { BarDice100 } from "./games/BarDice100/BarDice100";
import { Cashier } from "./hub/cashier/Cashier";
import { Admin } from "./hub/admin/Admin";
import { Account } from "./hub/account";
import { Support } from "./hub/account/Support";
import { RadSoftwareBackground } from "./hub/_assets/radsoftware-bg";
import { Toaster, loadToaster } from "./utils/toast";
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Helmet } from "react-helmet";
import { Modal } from "./utils/modal";
import { BackOfficePortal } from "./hub/admin/BOP/BackOfficePortal";
import { SuperAdminPanel } from "./hub/admin/super/SuperAdminPanel";
import { Error } from "./hub/error/Error";

// DATA
const data = {
  user: {
    username: null,
    at: null,
    history: [],
    role: "player",
    id: null,
    phoneNumber: null,
    gameHistory: null,
    balance: 0,
  },
  mobileDevice:
    navigator?.maxTouchPoints ||
    navigator?.msMaxTouchPoints ||
    (window.matchMedia && matchMedia("(pointer:coarse)")?.matches) ||
    window?.orientation ||
    /\b(BlackBerry|webOS|iPhone|IEMobile|Android|Windows Phone|iPad|iPod)\b/i.test(
      navigator.userAgent
    ),
  demo: (window.location.href.includes("demo.") || process.env.REACT_APP_DEMO=="true") && process.env.REACT_APP_NODE_ENV !== "production",
  jogo: window.location.href.includes("jogododado") || process.env.REACT_APP_JOGO=="true",
};

function App() {
  const auth = useAuthUser();
  const signOut = useSignOut();
  const authHeader = useAuthHeader();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log(authHeader(), auth())
  }, [authHeader()])

  const [user, setUser] = useState({});
  const [balance, setBalance] = useState(0);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(undefined);
  const [toasts, setToasts] = useState([])
  const [ip, setIp] = useState(undefined)
  const [online, setOnline] = useState(true)

  const backend = useRef(
    process.env.REACT_APP_NODE_ENV === "production"
      ? process.env.REACT_APP_PROD // TODO: dedicated DB server
      : process.env.REACT_APP_NODE_ENV === "staging"
      ? process.env.REACT_APP_STAGING
      : "http://localhost:4000"
  );

  // Force global socket reconnection on network change
  useEffect(()=>{
    window.addEventListener("online", ()=>{
      console.log("network found")
      setOnline(true)
    })
    window.addEventListener("offline", ()=>{
      console.log("network lost")
      setOnline(false)
    })
    return ()=>{
      window.removeEventListener("online", ()=>{})
      window.removeEventListener("offline", ()=>{})
    }
  }, [])

  useEffect(()=>{
    console.log("network status", online)
  }, [online])

  const globalSocket = useRef();
  useEffect(() => {
    if (auth()?.id) {
      globalSocket.current = socket("/global");
      globalSocket.current.on("connect", () => {
        console.log("global connected");
        data.user.username = `guest-${Math.round(Math.random() * 100000)}`;
      });
      globalSocket.current.on("disconnect", () => {
        console.log("disconnected from global");
        globalSocket.current?.disconnect(true);
        globalSocket.current?.off();
        //? error screen to overwrite blast?
      });
      globalSocket.current.on("ipAddress", (ipAddress) => {
        console.log("ipAddress", process.env.REACT_APP_TESTIP ?? ipAddress)
        setIp(process.env.REACT_APP_TESTIP ?? ipAddress)
      })
      globalSocket.current.on("balanceUpdate", (newBalance) => {
        console.log("balance update", newBalance);
        setBalance(newBalance / 100);
      });
      globalSocket.current.on("forceNavigate", (route) => {
        navigate(route);
      });
      globalSocket.current.on("forceSignOut", () => {
        signOut();
        window.location.reload();
      });
    }

    return () => {
      console.log("destroy sockets");
      globalSocket.current?.disconnect(true);
      globalSocket.current?.off();
    };
  }, [auth()?.id, online]);

  // socket re-registration on page navigation
  useEffect(()=>{
    if (auth()?.id && balance === 0) {
      globalSocket.current.emit("register", location.pathname.includes("/admin") ? "admin" + auth().id : auth().id);
      if (!location.pathname.includes("/admin")) {
        globalSocket.current.emit("balanceUpdate", auth()?.id);
      }
    }
  }, [window.location.href])

  const venueSocket = useRef();
  useEffect(() => {
    if (selectedLocation?.id) {
      venueSocket.current = socket(`/venue-${selectedLocation?.id}`);
      venueSocket.current.on("connect", () => {
        console.log("venue established", selectedLocation);
      });
      venueSocket.current.on("disconnect", () => {
        console.log("venue disconnected")
      });
      venueSocket.current.on("venueUpdate", (update) => {
        const oldVenue = locations.find(v => v.id === update.id)
        console.log("venue update", locations, update)
        if (oldVenue) {
          setLocations(locations.map(v => v.id === update.id ? update : v))
          if (selectedLocation.id === update.id) {
            setSelectedLocation(update)
            console.log("venue updated", oldVenue, update)
          }
        }
      })
    }

    return () => {
      venueSocket.current?.disconnect();
    };
  }, [selectedLocation]);

  // QR CAPTURE AND VENUE COOKIE HANDLER
  useEffect(() => {
    if (location.pathname.includes("/play")) {
      console.log("QR captured")
      const path = location.pathname.match(/\/play\/(\d+)\/(\w+)/);
      const venue = {
        id: Number(path[1]),
        path: path[2],
      };
      localStorage.setItem("venue", JSON.stringify({
        id: venue.id,
        path: venue.path,
        expires: Date.now() + 12 * 60 * 60 * 1000
      }))
      console.log("qr", venue, localStorage.getItem("venue"))
      navigate(auth()?.id ? "/" : "/login");
    } else {
      console.log("qr venue check", localStorage.getItem("venue"))
    }

    const cookieExpiration = JSON.parse(localStorage.getItem("venue"))?.expires-Date.now()
    let cookieTimer
    if (cookieExpiration>4) {
      cookieTimer = setTimeout(()=>{
        localStorage.setItem("venue", JSON.stringify({}))
        clearTimeout(cookieTimer)
        signOut()
      }, cookieExpiration)
    }

    return ()=>{
      clearTimeout(cookieTimer)
    }
  }, [selectedLocation]);

  // POST LOGIN USER SETUP
  useEffect(()=>{
    if (auth()?.id) {
      setUser({
        id: auth()?.id,
        username: localStorage.getItem("username")
          ?? auth().username 
          ?? auth().firstName 
          ?? `Guest ${auth().id}`,
        phoneNumber: auth().phoneNumber ?? "",
        email: auth().email,
        role: auth().role,
        locations: auth().locations,
        details: auth().details,
        settings: auth().settings,
      });
    }
  }, [auth()?.id])

  // LOCATION VALIDATION
  useEffect(() => {
    if (auth()?.id && ip) {
      (async () => {
        let venueCookie = JSON.parse(localStorage.getItem("venue"))
        let fullPath = /.com\/?.*$/i.exec(window.location.href)?.["0"]?.slice(5).toLowerCase()
        let path = /\b\w+$/i.exec(fullPath)?.["0"]
        let exclusive = process.env?.REACT_APP_VENUE_PATH && process.env?.REACT_APP_VENUE_PATH !== "null"
        let venueRequired, pathRequired
        
        console.log("venueCookie", venueCookie)
        console.log("fullPath", fullPath)
        console.log("path", path)
        console.log(window.location);

        switch (true) {
          case /\bsuper(\/?|\/.+)$/i.test(fullPath):
          case /\blogin\/?$/i.test(fullPath):
          case /\bplay\/\d+\/\w+$/i.test(fullPath):
            break;

          case /\baccount(\/?|\/\w+)$/i.test(fullPath):
          case /\bcashier\/?$/i.test(fullPath):
          case /\berror(\/?|\/\w+)$/i.test(fullPath):
          case !path:
            venueRequired = true
            break;

          default:
            pathRequired = true
        }

        console.log("venue required", venueRequired)
        console.log("path required", pathRequired)
        console.log("env var", process.env.REACT_APP_VENUE_PATH, typeof process.env.REACT_APP_VENUE_PATH, exclusive)
        console.log("api call", backend.current + `/locations?${
          pathRequired ? `path=${path}`
          : exclusive ? `path=${process.env.REACT_APP_VENUE_PATH}`
          : venueCookie?.path ? `path=${venueCookie?.path}`
          : "active=true"
        }&attributes=id_name_path_cashEnabled_ips_wifiName&order=id-ASC`)
        console.log("make api call?", (venueRequired && !data.jogo) || pathRequired || data.jogo&&!!venueCookie?.id)

        if ((venueRequired&&!data.jogo) || pathRequired || (data.jogo&&!!venueCookie?.id)) {
          await fetcher("GET", authHeader(),
            backend.current + `/locations?${
              pathRequired ? `path=${path}`
              : exclusive ? `path=${process.env.REACT_APP_VENUE_PATH}`
              : venueCookie?.path ? `path=${venueCookie?.path}` 
              : "active=true"
            }&attributes=id_name_path_cashEnabled_ips&order=id-ASC`
          ).then((results) => {
            setLocations(results);
            console.log("locations results", results)

            let locationActive, ipMatch
            let foundVenue = results.find(l => {
              if (l.path===path || l.id===venueCookie?.id 
                || l.path===process.env?.REACT_APP_VENUE_PATH
              ) { 
                locationActive = true
                ipMatch = l?.ips?.includes(ip)
              }
              return !!locationActive
            })

            console.log("foundVenue", foundVenue)
            console.log("locationActive", locationActive)
            console.log("ipMatch", ipMatch)
            console.log("check wifi?", !ipMatch, !auth()?.locations.includes(0), !ipMatch && !auth()?.locations.includes(0))

            if (!data.demo && !data.jogo) {
              if (!locationActive) {
                navigate("/error/scan", {state: {redirect: location.pathname}})
              } 
              else if (!ipMatch && !auth()?.locations.includes(0)) {
                // navigate("/error/wifi", {state: {redirect: location.pathname}})
              }
            }
            
            setSelectedLocation(foundVenue)
            localStorage.setItem("venue", JSON.stringify({
              id: foundVenue?.id,
              path: foundVenue?.path,
              expires: foundVenue?.id ? Date.now() + 12 * 60 * 60 * 1000 : 0
            }))
            console.log("venue localStorage check", localStorage.getItem("venue"))
          }).catch((error) => {
            console.log(error);
          })
        }
      })();
    }
    // eslint-disable-next-line
  }, [auth()?.id, ip]);

  useEffect(() => {
    document
      .querySelector("#root")
      .style.setProperty(
        "height",
        document.documentElement.clientHeight + "px"
      );
    // console.log("useEffect innerHeight", window.innerHeight)
  }, [document.documentElement.clientHeight]);  

  const [jackpotValue, setJackpotValue] = useState(0)
  const [jackpotTicketCount, setJackpotTicketCount] = useState(0)
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(<></>);
  
  return (
    <>
      <Helmet>
        <title>Mecca 100</title>
        <link rel="icon" type="image/png" href={"/meccaIcon.png"} />
        <link rel="apple-touch-icon" sizes="180x180" href={"/meccaIcon.png"} />
      </Helmet>
      <GoogleOAuthProvider clientId="302612497388-ds5todt0n0ooqlb87bhrs510pc9s8e85.apps.googleusercontent.com">
        <Routes>
          <Route
            path="/admin/*"
            element={
              <BackOfficePortal
                user={user}
                locations={locations}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                backend={backend}
                venueSocket={venueSocket}
                globalSocket={globalSocket}
                demo={data.demo}
                jogo={data.jogo}
                jackpotValue={jackpotValue}
              />
            }
          />
          <Route
            path="/super/*"
            element={
              <SuperAdminPanel
                user={user}
                locations={locations}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                backend={backend}
                venueSocket={venueSocket}
                globalSocket={globalSocket}
                demo={data.demo}
                jogo={data.jogo}
                jackpotValue={jackpotValue}
              />
            }
          />
          <Route
            path="/cashier/*"
            element={
              <Cashier
                user={user}
                balance={balance}
                locations={locations}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                backend={backend}
                venueSocket={venueSocket}
                globalSocket={globalSocket}
                demo={data.demo}
                jogo={data.jogo}
                jackpotValue={jackpotValue}
                modalOpen={modalOpen}
                modalData={modalData}
                setModalOpen={setModalOpen}
                setModalData={setModalData}
              />
            }
          />
          <Route
            path="/account/*"
            element={
              <Account
                username={auth()?.phoneNumber}
                balance={balance}
                data={data}
                user={user}
                setUser={setUser}
                backend={backend}
                demo={data.demo}
                jogo={data.jogo}
                jackpotValue={jackpotValue}
                selectedLocation={selectedLocation}
                modalOpen={modalOpen}
                modalData={modalData}
                setModalOpen={setModalOpen}
                setModalData={setModalData}
                toasts={toasts}
                setToasts={setToasts}
              />
            }
          />
          <Route
            path="/account/support"
            element={
              <Support
                username={auth()?.phoneNumber}
                balance={balance}
                data={data}
                user={user}
                demo={data.demo}
                jogo={data.jogo}
                jackpotValue={jackpotValue}
                selectedLocation={selectedLocation}
                modalOpen={modalOpen}
                modalData={modalData}
                setModalOpen={setModalOpen}
                setModalData={setModalData}
                toasts={toasts}
                setToasts={setToasts}
                backend={backend}
              />
            }
          />
          <Route 
            path="/error/*" 
            element={(
              <Error
                jogo={data.jogo}
                locations={locations}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                ip={ip}
              />
            )}
          />
          <Route
            path="/*"
            element={
              <BarDice100
                user={user}
                setUser={setUser}
                balance={balance}
                setBalance={setBalance}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                demo={data.demo}
                jogo={data.jogo}
                toasts={toasts}
                setToasts={setToasts}
                currency={process.env.REACT_APP_CURRENCY}
                setJackpotValue={setJackpotValue}
                modalOpen={modalOpen}
                modalData={modalData}
                setModalOpen={setModalOpen}
                setModalData={setModalData}
                ip={ip}
              />
            }
          />
        </Routes>
        <Modal modalOpen={modalOpen} setModalOpen={setModalOpen} element={modalData}/>
        <Toaster toasts={toasts} setToasts={setToasts}/>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;